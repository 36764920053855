.footerLandingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  border-top: 2px solid var(--box-bg);
  &.reveal {
    top: 0;
    transition: all 2s ease;
  }
  &.reveal-active {
    top: 0;
  }
}
.footer-landing {
  width: 100%;
  max-width: calc(220px + 600px + 300px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_left {
    padding-left: 31px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &_text {
      font-weight: 500;
    }
  }
  &_right {
    font-size: var(--font-size-s);
    display: flex;
    align-items: center;
    margin: 0px -16px;
    margin-right: 0;
    &_item {
      margin: 0px 16px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .footer-landing {
    padding: 0px 16px;
    &_left {
      padding-right: 8px;
      padding-left: 0;
    }
    &_right {
      font-size: 12px;
      padding-right: 0;
      margin: 0px -8px;
      &_item {
        margin: 0px 8px;
        &_sub {
          display: none;
        }
      }
    }
  }
}
