//start betDetail
.betDetail {
  padding: 16px;
  display: flex;
  &_left {
    width: 85%;
    padding: 25px;
    background: var(--box-bg);
    border-radius: 4px;
    box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
    &_head {
      display: flex;
      margin-bottom: 40px;
      justify-content: space-around;
      &_total {
        margin-right: auto;
      }
      &_amount {
        margin-right: auto;
      }
      &_potential {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
      }
      .title {
        position: relative;
        p {
          color: var(--normal-text-black-color);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 5px;
        }
        .infoIcon {
          margin-left: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid #ccc;
          font-size: 9px;
          width: 12px;
          height: 12px;
          color: var(--normal-text-black-color);

          position: absolute;
          top: 1px;
          right: -17px;
          cursor: default;
        }
        //custom
        .bet-detail-tooltip {
          width: 100px;
          padding: 5px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .tooltipDetailBet {
          // font-size: 8px;
          // line-height: 12px;
          // color: white;
        }
      }
      .value {
        color: var(--normal-text-black-color);
        font-size: var(--font-size-xl);
        font-style: normal;
        font-weight: 500;
      }
    }
    &_below {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &_right {
    width: 40%;
    display: flex;
    .inputBets {
      width: 100%;
      display: flex;
      flex-direction: column;
      //custom
      .input-bet {
        background-color: var(--box-bg);
        margin-left: auto;
      }
    }
    .yourBet {
      margin-left: auto;
      .title {
        color: var(--normal-text-black-color);
        font-size: var(--font-size-xs);
        font-style: normal;
        font-weight: 400;
        margin-bottom: 5px;
      }
      .value {
        color: var(--green);
        font-size: var(--font-size-xl);
        font-style: normal;
        font-weight: 500;
        &.red {
          color: var(--red);
        }
      }
    }
  }
}
.chart {
  margin: 0 16px 60px 16px;
  padding: 25px;
  border-radius: 6px;
  background: #171B26;// var(--box-bg);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  &_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 24px 0;
    &_left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &_symbol {
        font-size: 28px;
        font-weight: 600;
        color: var(--normal-text-black-color);
      }
      &_number {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        &_price {
          font-size: 14px;
          font-weight: 600;
          color: var(--normal-text-black-color);
        }
        &_difference {
          font-size: 12px;
          font-weight: 600;
          color: var(--normal-text-black-color);
        }
        .red-text{
          color: var(--red);
        }
        .green-text{
          color: var(--green);
        }
      }
    }
    &_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &_numbers {
        font-size: 12px;
        font-weight: 400;
        color: var(--normal-text-black-color);
        margin-bottom: 3px;
      }
      &_avatars {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100px;
        &_item {
          a{
            img{
              position: absolute;
              border: 2px solid  #171B26 ;//var(--box-bg);
              border-radius: 25px;
            }
          }

        }
      }
    }
  }
}
// end betDetail

.betInfoRate {
  display: flex;
  width: 100%;
  .mg-bottom-0 {
    margin-bottom: 0 !important;
  }
  &_column {
    width: calc(100% / 3);
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    .title {
      p {
        color: var(--normal-text-black-color);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 8px;
      }

      &-per {
        margin-left: auto;
        margin-right: auto;
      }
      &-mul {
        margin-left: auto;
      }
    }
    .value {
    }
    &:nth-child(3) {
      // background: red;
    }
  }
}
@media only screen and (min-width: 1100px) {
  .betDetail {
    &_left {
      &_head {
        &_amount {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .betDetail {
    flex-direction: column;
    &_left {
      width: 100%;
      &_head {
        justify-content: space-between;
        &_total {
          .displayNoneMobile {
            display: none;
          }
        }
        &_potential {
          margin-left: 0;
        }
        .displayNoneMobile {
          display: none;
        }

        &_yourBet {
          // display: none;
          .title {
            color: var(--normal-text-black-color);
            font-size: var(--font-size-xs);
            font-style: normal;
            font-weight: 400;
            margin-bottom: 5px;
          }
          .value {
            color: var(--green);
            font-size: var(--font-size-xl);
            font-style: normal;
            font-weight: 500;
            &.red {
              color: var(--red);
            }
          }
        }
      }
    }
    &_right {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      .yourBet {
        display: none;
      }
    }

    .betInfoRate {
      display: flex;
      width: 100%;
      &_column {
        width: 18%;
      }
    }

    .inputBets {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .input-bet {
        input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
        width: 48%;
        margin-left: 0;
        &.red {
          margin-top: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .betDetail {
    padding: 16px;
    .betInfoRate {
      display: flex;
      width: 100%;
      &_column {
        width: calc(100% / 3);
      }
    }
  }
}
@media only screen and (max-width: 492px) {
  .betDetail {
    .inputBets {
      .inputBet {
        width: 126px;
      }
    }
  }
}
.detail-info {
  width: auto !important;
}

// [data-theme="light"] {
//   .chart_title_right_avatars_item a img {
//     border: 2px solid #FFFFFF;
//   }
// }
