.history {
  &_title {
    margin-bottom: 20px;
    position: relative;
    p {
      font-size: 23px;
      font-weight: 600;
    }
  }
  .following_table_detail_header {
    height: auto;
  }
  .following_table_detail {
    padding-top: 0;
    tr.following_table_detail_header_tr_history {
      th {
        padding-bottom: 19px;
        font-size: 13px;
      }

      th.date {
        width: 17%;
      }
      th.transaction {
        width: 32%;
      }
      th.amount {
        width: 18%;
      }
      th.funds-th {
        width: 18%;
      }
      th.account {
        width: 15%;
      }
      th.events {
        width: 15%;
      }
    }
    //
    .btnShare {
      background: var(--bg);
      padding: 6px 10px;
      p {
        margin: auto;
        // color: var(--normal-text-black-color);
        color: var(--gray-blur-text);
        border-right: none;
        text-align: center;
        font-size: 13px;
      }
    }
  }
}

.history,
.adminWithdraw {
  &_lists {
    margin-bottom: 76px;
  }
  //custom  infinite-scroll-component
  .infinite-scroll-component {
    overflow: hidden !important;
  }
}

@media only screen and (max-width: 1100px) {
  .history {
    &_lists {
      margin-bottom: 100px;
    }
  }
}
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .history {
    &_lists {
      margin-bottom: 200px;
    }

    &_title {
      p {
        font-size: 16px;
      }
    }

    .following_table_detail {
      tr.following_table_detail_header_tr_history {
        th {
          font-size: 12px;
          padding-bottom: 15px;
        }
        th.date {
          width: 27%;
        }
        th.transaction {
          width: 50%;
        }
        th.amount {
          width: 23%;
        }
      }
      .following_table_detail_tbody tr {
        height: auto;
      }
      .following_table_detail_tbody td {
        padding-top: 20px;
        height: fit-content;
        padding-bottom: 4px;
      }
    }
  }
}

//

.shareImageWrapper {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  align-items: center;
  display: flex;
  z-index: 2000;
  filter: blur(0px) !important ;

  &_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    // background: red;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2000;

    &-url {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .shareImage {
    margin: auto;
    position: relative;
    z-index: 2000;

    &_img {
      position: relative;
      width: 540px;
      height: fit-content;
      background: var(--purple);
      .btnCloseImage {
        @media only screen and (min-width: 1100px) {
          display: none !important;
        }
      }
      img {
        display: block;
        width: 540px;
        // margin-bottom: 34px;
      }
      &_content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 44px 33px;
        p.title {
          font-size: 39px;
          font-weight: 500;
          color: var(--normal-text-black-color);
          margin-bottom: 35px;
          font-family: Roboto;
        }
        p.text {
          font-size: 25px;
          color: var(--normal-text-black-color);
          font-weight: 400;
          margin-bottom: 20px;
          font-family: Roboto;
        }
        p.price {
          font-size: 58px;
          color: var(--normal-text-black-color);
          font-weight: 600;
          font-family: Roboto;
        }
      }
    }

    &_imgMeta {
      position: absolute;
      top: 0;
      left: 0;
      width: 800px;
      width: 540px;
      display: none;
      z-index: -1;
      img {
        width: 100%;
      }
      &_content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 14% 0 0 51%;
        p.title {
          font-size: 23px;
          font-weight: 600;
          color: var(--normal-text-black-color);
          margin-bottom: 14%;
          font-family: Roboto;
        }
        p.text {
          font-size: 15px;
          color: var(--normal-text-black-color);
          font-weight: 400;
          margin-bottom: 9%;
          font-family: Roboto;
        }
        p.price {
          font-size: 45px;
          color: var(--normal-text-black-color);
          font-weight: 700;
          font-family: Roboto;
        }
      }
    }

    &_buttons {
      margin-top: 34px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btnShare,
      .btnSaveImage {
        width: 248px;
        background: var(--bg);
        padding: 11px 0;
        border-radius: 20px;
        p {
          font-size: 18px;
          img {
            width: 20px;
            margin: 0;
            transform: translateY(2px);
            margin-left: 9px;
          }
        }
        &.disable {
          opacity: 0.5;
          cursor: wait;
        }
      }
      .btnShare {
        border: 1px solid #03a9f4;
        p {
          color: #03a9f4;
        }
      }
      .btnSaveImage {
        border: 1px solid var(--purple);
        p {
          color: var(--purple);
        }
      }
    }
    &-url {
      img {
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    height: fit-content;
    &_bg {
      height: 100vh;
    }
    .shareImage {
      width: 100%;
      max-width: 600px;
      // &_imgMeta,
      &_img {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
        height: fit-content;
        &_content {
          width: 100%;
          padding: 4vh 0 0 3.5vh;
          p.title {
            font-size: 3vh;
            margin-bottom: 2vh;
          }
          p.text {
            font-size: 1.7vh;
            margin-bottom: 2vh;
          }
          p.price {
            font-size: 5vh;
          }
        }
        img {
          width: 100%;
        }
        .btnCloseImage {
          position: absolute;
          opacity: 0.8;
          top: 0px;
          right: 0px;
          left: auto;
          border-radius: 0;
          // border-top-right-radius: 3px;
          background: rgba(0, 0, 0, 0.7);

          cursor: pointer;
          z-index: 2000;
          display: none;
        }
      }
      &_imgMeta {
        width: 80%;
        // display: block;
        margin: 2.5vh;
        &_content {
          width: 100%;
          // padding: 4vh 0 0 3.5vh;\
          padding-top: 4vh;
          p.title {
            font-size: 2vh;
            margin-bottom: 1vh;
          }
          p.text {
            font-size: 1vh;
            margin-bottom: 1h;
          }
          p.price {
            font-size: 3vh;
          }
        }
      }
      &_buttons {
        flex-wrap: wrap;
        .btnShare,
        .btnSaveImage {
          padding: 6px 0;
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          p {
            font-size: 15px;
            img {
              width: 15px;
            }
          }
        }
        .btnShare {
          margin-bottom: 10px;
        }
      }
    }
  }
}
