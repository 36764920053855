.investorItem {
  width: 100%;
  background: var(--box-bland-bg); //#fbfafb;
  border-radius: 8px;
  padding: 12px 10px 10px 10px;
  // margin: 8px 0;

  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // cursor: pointer;
  &:hover {
    background: var(--green-hover);
  }
  &_avatar {
    margin-bottom: 9px;
    position: relative;
    img {
      width: 43px;
      border-radius: 50%;
    }
  }
  &_name {
    margin-bottom: 15px;
    overflow: hidden;
    p {
      color: var(--normal-text-black-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      // height: 15px;
    }
  }
  &_money {
    background: transparent;
    width: 100%;
    padding: 4px 2px;
    border-radius: 5px;
    border: 1px solid var(--green);
    p {
      text-align: center;
      color: var(--green);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      transform: translateY(1px); // Top Investors → Number spacing
    }
    &-loss {
      border: 1.5px solid var(--red);
      p {
        color: var(--red);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .investorItem {
    &:hover {
      background: var(--box-bland-bg);
    }
    &_avatar {
      img {
        width: 27px;
      }
    }
    &_name {
      p {
        font-size: 12px;
      }
    }
    &_money {
      p {
        font-size: 10px;
      }
    }
  }
}
