.skeleton {
  background: #777;
  // margin: 5px 0;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &_shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    animation: loading 1s infinite;
    &-light {
      background: rgba(255, 255, 255, 0.2);
    }
    &-dark {
      background: rgba(255, 255, 255, 0.05);
    }
  }
  //theme
  &-light {
    background: #ddd;
  }
  &-dark {
    background: #777;
  }
  //type
  &.textTableStock {
    margin: 0;
    max-width: 40px;
    height: 22px;
    border-radius: 5px;
  }
  &.textTablePrice {
    margin: 0;
    max-width: 60px;
    height: 16px;
    border-radius: 4px;
  }
  &.textTablePriceVolatility {
    margin: 0;
    max-width: 40px;
    height: 12px;
    border-radius: 3px;
  }

  &.textTableGreen {
    margin: 0;
    margin-left: auto;
    max-width: 30px;
    height: 20px;
    border-radius: 3px;
  }
  &.textTableRed {
    margin: 0;
    max-width: 30px;
    height: 20px;
    border-radius: 3px;
  }
  &.textTableTotal {
    margin: 0;
    max-width: 50px;
    height: 20px;
    border-radius: 3px;
  }
  &.textTableBet {
    margin: 0;
    width: 33px;
    height: 18px;
    border-radius: 3px;
  }
  //history
  &.textHistoryTitle {
    margin: 0;
    width: 70px;
    height: 16px;
    border-radius: 3px;
  }
  &.textHistoryDate {
    margin: 0;
    width: 33px;
    height: 12px;
    border-radius: 3px;
  }
  &.textHistoryPrice {
    margin: 0;
    width: 80px;
    height: 18px;
    border-radius: 3px;
  }
  //end history

  //custom
  &.userImageSideBar {
    margin: 0;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background: #2a2e39;
    &-dark {
      background: #252a39;
    }
    .skeleton_shimmer {
      background: #2b2f39;
      background: rgba(255, 255, 255, 0.05);
    }
  }
  &.userNameSideBar {
    margin: 0;
    width: 89px;
    height: 19px;
    border-radius: 4px;
    background: #2a2e39;
    &-dark {
      background: #252a39;
    }
    .skeleton_shimmer {
      background: #2b2f39;
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(200%);
  }
}

.skeleton-avatarSpotlight {
  width: 44px;
  border-radius: 50%;
  margin-right: 16px;
  position: relative;
  z-index: 10;
}
.skeleton-spotlightText {
  display: inline-block;
  width: 100px;
  height: 12px;
  margin-right: 10px;
  transform: translateY(1px);
}
.skeleton-spotlightText-2 {
  display: inline-block;
  width: 40px;
  height: 12px;
  transform: translateY(1px);
}
.skeleton-spotlightText-3 {
  display: inline-block;
  width: 450px;
  height: 17px;
  // transform: translateY(1px);
}

// topmoves
.skeleton-topMoves-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  // position: relative;
  // z-index: 10;
}
.skeleton-topMoves-name {
  display: inline-block;
  width: 100px;
  height: 14px;
  transition: 1s;
  // transform: translateY(1px);
}
.skeleton-topMoves-value {
  margin-left: auto;
  display: inline-block;
  width: 60px;
  height: 14px;
  transition: 1s;
  // transform: translateY(1px);
}
// end top move

// trades

.skeleton-trades-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.skeleton-trades-text {
  display: inline-block;
  width: 186px;
  height: 14px;
  transition: 1s;
}

// end trades

// bigMover
.skeleton-avatarBigMover {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 0px;
  position: relative;
  z-index: 10;
  //
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-titleBigMover {
  display: inline-block;
  width: 35px;
  height: 11px;
  transform: translateY(1px);
}
.skeleton-volumeBigMover {
  display: inline-block;
  width: 70px;
  height: 11px;
  transform: translateY(1px);
}
.skeleton-priceBigMover {
  display: inline-block;
  width: 60px;
  height: 12px;
  transform: translateY(1px);
}
.skeleton-volatilityBigMover {
  display: inline-block;
  width: 40px;
  height: 12px;
  transform: translateY(1px);
}
// end big mover

// metricsChart

.skeleton-avatarMetricsChart {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 0px;
  position: relative;
  z-index: 10;
  transform: translateY(7px);
  //
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-titleMetricsChart {
  display: inline-block;
  width: 280px;
  height: 11px;
  // transform: translateY(6px);
}
.skeleton-titleMetricsChart-2 {
  display: inline-block;
  width: 50px;
  height: 11px;
  // transform: translateY(6px);
}
// end metricsChart

// Top investor
.skeleton-avatarInvestor {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  //
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-nameInvestor {
  display: inline-block;
  width: 85px;
  height: 11px;
  // transform: translateY(6px);
}

.skeleton-moneyInvestor {
  display: inline-block;
  width: 89.19px;
  height: 17px;
  border-radius: 5px;
}
// End Top investor

@media screen and (max-width: 1100px) {
  // @media screen and (max-width: 992px) {
  .skeleton-spotlightText {
    width: 43px;
    margin-right: 8px;
  }
  .skeleton-spotlightText-2 {
    width: 60px;
  }
  .skeleton-spotlightText-3 {
    width: 300px;
    height: 14px;
  }

  // bigMover
  .skeleton-avatarBigMover {
    width: 28px;
    height: 28px;
  }

  .skeleton-titleBigMover {
    display: inline-block;
    width: 35px;
    height: 10px;
    transform: translateY(-1px);
  }
  .skeleton-volumeBigMover {
    display: inline-block;
    width: 70px;
    height: 10px;
    transform: translateY(-1px);
  }
  .skeleton-priceBigMover {
    display: inline-block;
    width: 60px;
    height: 10px;
    transform: translateY(1px);
  }
  .skeleton-volatilityBigMover {
    display: inline-block;
    width: 40px;
    height: 10px;
    transform: translateY(-2px);
  }
  // end big mover

  // metricsChart

  .skeleton-avatarMetricsChart {
    width: 28px;
    height: 28px;
  }

  .skeleton-titleMetricsChart {
    display: inline-block;
    width: 49vw;
    height: 10px;
    // transform: translateY(6px);
  }
  .skeleton-titleMetricsChart-2 {
    display: inline-block;
    width: 15vw;
    height: 10px;
    // transform: translateY(6px);
  }
  // end metricsChart
}
