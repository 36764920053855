.adminWithdrawItem-border {
  width: 90%;
  border-top: 1px solid var(--border-comment);
}
.adminWithdrawItem-detail {
  width: 100%;
  padding: 29px 34px 15px 68px;
  display: flex;
  justify-content: space-between;
  .label {
    font-size: 12px;
    color: #b3afbf;
  }
  .value {
    color: var(--normal-text-black-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &_right {
    display: flex;
    flex-direction: column;

    &_item {
      margin-bottom: 15px;
      display: flex;
      &:nth-child(3) {
        margin-bottom: 0;
      }
      &_currency {
        width: 100px;
      }
      .typeCrypto {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 7px;
      }
    }
  }
  &_left {
    display: flex;
    flex-direction: column;
    &_item {
      margin-bottom: 20px;
      &:nth-child(2) {
        margin-top: auto;
        margin-bottom: 0px;
      }
      .selectWrapper {
        margin-top: 2px;
        .selectOption {
          width: 100%;
          padding: 8px;
          border: 1px solid var(--normal-text-black-color);
          background-color: #252a39;
          outline: none;
          color: var(--normal-text-black-color);
          font-size: var(--font-size-xs);
          font-style: normal;
          font-weight: 400;
          border-radius: 2px;
        }
        .selectAdminCustom {
          &.disable {
            opacity: 0.6;
            pointer-events: none;
          }
          .css-yk16xz-control {
            background: transparent;
          }
          .css-1pahdxg-control {
            background: transparent;
          }
          .css-1uccc91-singleValue {
            color: var(--normal-text-black-color);
            font-size: var(--font-size-xs);
            font-style: normal;
            font-weight: 400;
          }
          .css-1okebmr-indicatorSeparator {
            display: none;
          }
          .css-1gtu0rj-indicatorContainer {
            color: var(--normal-text-black-color);
          }
          .css-tlfecz-indicatorContainer {
          }
          .css-tlfecz-indicatorContainer:hover {
            color: var(--normal-text-black-color);
          }
          .css-qraw6e-control {
            border-color: var(--normal-text-black-color);
          }
        }
      }
      .btnContinue {
        width: 120px;
        border: 1px solid var(--green);
        padding: 5px 10px;
        border-radius: 5px;
        p {
          font-size: var(--font-size-s);
          font-style: normal;
          color: var(--green);
        }
        &:hover {
          background: var(--green);
          p {
            color: var(--mirage);
          }
        }
        &.disable {
          opacity: 0.5;
          pointer-events: none;
        }
        &.hide {
          opacity: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .adminWithdrawItem-detail {
    padding: 16px;
  }
}
@media only screen and (max-width: 492px) {
  .adminWithdrawItem-detail {
    padding: 16px;
    display: flex;
    flex-direction: column;
    &_right {
      margin-bottom: 20px;
    }
    .textarea {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (min-width: 493px) {
  .adminWithdrawItem-detail {
    .textarea {
      .textAreaInput {
        min-width: 250px;
      }
    }
  }
}
