.container-landing {
  width: 100%;
  font-family: var(--font-family-overpass);
  color: var(--normal-text-black-color); //#fff;
  background-color: var(--body-landing-bg);
}

.hedge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: calc(60px + 60px);
  margin-bottom: 80px;
  padding-top: 0px;
  height: 100vh;
  min-height: 600px;
  &_video {
    width: 50%;
    padding-left: 16px;
    position: relative;
    height: fit-content;
    height: 600px;
    overflow: hidden;
    video {
      // height: 100%;
      // transform: translateX(100%);
      position: relative;
      z-index: 1;
      z-index: -99;
      width: calc(100% + 10px);
      width: 1500px;
      //
    }
    canvas {
      position: absolute;
      top: 0;
      left: calc(-10px);
      width: calc(100% + 10px);
      height: auto;
      // pause fix bg
      // display: none;
    }
  }
  &_texts {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 16px;
    &_head {
      font-size: 65px;
      font-weight: 500;
      margin-bottom: 35px;
      line-height: 1.15;
    }
    &_sub {
      font-size: 20px;
      margin-bottom: 50px;
      line-height: 1.2;
      .greenText {
        color: var(--green);
      }
      .redText {
        color: var(--red);
      }
    }
    &_form {
      display: flex;
      justify-content: space-between;
      .emailInput {
        background-color: var(--body-landing-bg);
        outline: none;
        border-radius: 4px;
        border: 1px solid #c4c9d4; // var(--border);
        padding: 0px 8px;
        width: 80%;
        margin: 0px;
        margin-right: 16px;
        height: 45px;
        color: var(--normal-text-black-color);
        color: #c4c9d4;
        font-size: var(--font-size-m);
      }
      .btnSignUp {
        width: 20%;
        background-color: var(--green);
        outline: none;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        width: 120px;
        padding: 8px 0px;
        padding-top: 10px;
        border: 1px solid var(--green);
        border-radius: 4px;
        p {
          font-family: var(--font-family-overpass);
          font-size: var(--font-size-m);
          font-weight: 500;
          color: #2b2b2d;
        }
      }
    }
  }
}

.dividerLandingWrapper {
  width: 100%;
  padding: 32px 16px;
  .dividerLanding {
    display: flex;
    justify-content: space-between;
    background-color: var(--box-bland-bg);
    padding: 12px 24px;
    border-radius: 8px;
    &_left {
      display: flex;
      align-items: center;
      &_text {
        margin-left: 16px;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      color: var(--green);
      &_text {
        margin-left: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .hedge {
    flex-direction: column-reverse;
    padding: 40px 16px 50px 16px;
    height: 100%;
    &_video {
      padding-left: 0;
      width: 100%;
      height: 100vw;
      // padding: 32px 24px 0px;
      display: flex;
      justify-content: center;
      margin-bottom: 0px;
      overflow: hidden;
      min-height: 100vw;
      // fix bg
      video {
        &.ios {
          width: 100%;
          z-index: 1;
          position: static;
        }
      }
      canvas {
        min-height: 100vw;
        &.ios {
          display: none;
        }
      }
    }

    &_texts {
      width: 100%;
      padding-left: 0;
      margin-bottom: 60px;
      &_head {
        font-size: 42px;
        margin-bottom: 22px;
      }
      &_sub {
        font-size: var(--font-size-m);
        margin-bottom: 34px;
        line-height: 1.2;
      }
      &_form {
        width: 80%;
        .emailInput,
        .btnSignUp {
          font-size: var(--font-size-s);
          height: 32px;
        }
        .emailInput {
          width: 75%;
          margin-right: 8px;
        }
        .btnSignUp {
          width: 25%;
          p {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .dividerLandingWrapper {
    .dividerLanding {
      &_left {
        line-height: 1.2;
        &_text {
          margin-left: 12px;
        }
        p {
          font-size: 12px;
        }
      }
      &_right {
        display: none;
      }
    }
  }
}
