.historyItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
  box-shadow: 0 2px 1px rgba(57, 63, 72, 0.3), 1px 0 5px rgba(57, 63, 72, 0.3);
  border-radius: 10px;
  background: var(--box-bg);
  overflow: hidden;
  .historyItem {
    position: relative;
    width: 100%;
    display: flex;
    padding: 16px 27px 16px 16px;
    cursor: pointer;
    &:hover {
      background:var(--green-hover);
    }
    &_left {
      width: 50%;
      display: flex;
      flex-direction: column;
      .title {
        p {
          color: var(--normal-text-black-color);
          font-size: var(--font-size-m);
          font-style: normal;
          font-weight: 500;
          margin-bottom: 0.5em;
        }
      }

      .date {
        p {
          color: var(--normal-text-black-color);
          font-size: var(--font-size-xs);
          font-style: normal;
        }
      }
    }
    &_right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .amountGroup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        text-align: right;
        .balance {
          color: var(--normal-text-black-color);
          font-size: var(--font-size-xs);
          font-style: normal;
          font-weight: 400;
        }
        .price-red {
          color: var(--red);
          span {
            color: var(--red);
          }
        }
        .price-green {
          color: var(--green);
          span:not(:last-child) {
            color: var(--green);
          }
        }
      }
      .title {
        .infoIcon {
          // margin-left: 4px;
          display: inline-block;
          text-align: center;
          border-radius: 50%;
          border: 1px solid #ccc;
          font-size: 9px;
          width: 12px;
          height: 12px;
          color: var(--cloud);

          position: absolute;
          top: 31%;
          right: 9px;
          cursor: default;
        }
      }
      .price {
        margin-left: auto;
        position: relative;
        .amount_infoIcon{
          @media only screen and (max-width: 1100px) {
          right: -21px;
            
          }
         display: flex;
         justify-content: center;
         align-items: center;
          border-radius: 50%;
          border: 1px solid #ccc;
          font-size: 9px;
          width: 12px;
          height: 12px;
          color: var(--normal-text-black-color);

          position: absolute;
          top: 17%;
          right: -15px;
          cursor: pointer;
        }
        p,
        span:not(:last-child) {
          color: var(--normal-text-black-color);
          font-size: var(--font-size-l);
          font-style: normal;
          font-weight: 500;
          margin-bottom: 8px;
          white-space: nowrap;
        }
        &-red {
          p {
            color: var(--red);
          }
        }
        &-green {
          p {
            color: var(--green);
          }
        }
      }
    }
    // &:hover {
    //   background: var(--ebony-clay);
    //   &::after {
    //     content: "";
    //     position: absolute;
    //     top: -1px;
    //     left: 0;
    //     width: calc(100%);
    //     height: 1px;
    //     background: #2b2f39;
    //   }
    // }
    &-detail {
      // background: var(--ebony-clay);
      &:hover {
        background: var(--box-bg);
      }
      &::after {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: calc(100%);
        height: 1px;
        // background: #2b2f39;
      }
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 16px;
    //   width: calc(100% - 32px);
    //   height: 1px;
    //   background: #2b2f39;
    // }
  }

  // custom
  .my-bet-item {
    &__header {
      display: none;
    }
    width: 100%;
    background: var(--ebony-clay);
  }
}

@media only screen and (max-width: 1100px) {
  .historyItemWrapper {
    .historyItem {
      &:hover {
        background: var(--box-bg);
      }
      &_right {
        .amountGroup {
          padding-right: 2%;
          width: auto;
        }
      }
    }
  }
}
.__react_component_tooltip.place-top:after {
  right: 3% !important;
  left: unset !important;
}
.t90eb6550-7ee9-4884-bd8b-c6cb738faedf.place-top::after{
  border-top-color: var(--gray-bg) !important;
}
// .t5d67193b-0202-46cb-9893-0e71ba995a7c.place-top::after
@media only screen and (min-width: 1100px) {
  .historyItemWrapper {
    .historyItem {
      // &:hover {
      //   background: var(--ebony-clay);
      //   &::after {
      //     content: "";
      //     position: absolute;
      //     top: -1px;
      //     left: 0;
      //     width: calc(100%);
      //     height: 1px;
      //     background: #2b2f39;
      //   }
      // }
    }
  }
}
.detail-info {
  width: auto !important;
}
