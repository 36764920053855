.socialTradingDemo {
  padding-left: 16px;
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 600px;
  img.phone {
    width: 250px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 110;
  }
  img.bg {
    width: 480px;
    position: absolute;
    top: 7%;
    // left: -39px;
    right: calc(50% - 250px / 2 - 50px);
    z-index: 100;
  }
  .bgCoverPhone {
    position: absolute;
    // equal img
    width: 265px;
    left: 50%;
    transform: translateX(-50%);
    // end equal img
    top: calc(20% + 262px);
    z-index: 111; // phone is 1100
    height: calc(520px - 262px);
    background: var(--bg);
  }
  &_investors {
    //custom
    .investorItem {
      width: 120px;
      position: absolute;
      z-index: 111; // phone is 1100
      top: 0;
      right: 0;
      &:nth-child(1) {
        top: 9%;
        right: calc(50% - 250px / 2 + 6px);
        transform: scale(0.8) translateX(50%);
      }
      &:nth-child(2) {
        top: 19%;
        right: calc(50% + 250px / 2 + 14px);
        transform: scale(0.8) translateX(50%);
        z-index: 100; // phone is 1100
      }
      &:nth-child(3) {
        top: calc(9% + 246px);
        right: calc(50% - 250px / 2 - 10px);
        transform: scale(0.8) translateX(50%);
      }
      &:nth-child(4) {
        top: calc(19% + 240px);
        right: calc(50% + 250px / 2 - 15px);
        transform: scale(0.8) translateX(50%);
        z-index: 111; // phone is 1100
      }
    }
    // img of users
    .user1 {
      position: absolute;
      top: calc(10%);
      right: calc(50% - 250px / 2 + 12px);
      transform: scale(0.98) translateX(50%);

      width: 105px;
      height: 123px;
      // background: rgba(255, 0, 0, 0.32);
      overflow: hidden;
      z-index: 111;
      &:hover {
        .userImg {
          display: none;
        }
      }
      img {
        width: calc(96px + 45px + 45px);
        transform: translateX(-41px) translateY(-41px);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .socialTradingDemo {
    min-height: 112vw;
    margin-bottom: 20px;
    img.phone {
      width: 54vw;
      position: absolute;
      top: 20vw;
      left: auto;
      right: 7vw;
      transform: translateX(0%);
      z-index: 110;
    }
    img.bg {
      width: 100vw;
      top: 5vw;
      left: auto;
      right: calc(0vw);
      transform: translateX(0%);
    }
    .bgCoverPhone {
      // // equal img
      width: 56vw;
      left: auto;
      right: 6vw;
      transform: translateX(0%);
      // // end equal img
      top: 77vw;
      height: calc(60vw);
    }
    &_investors {
      //custom
      .investorItem {
        padding: 2vw 2vw;
        width: 20vw;
        left: auto;
        top: 0;
        right: 0;
        transform: translateX(0%);
        height: fit-content;
        &:nth-child(1) {
          top: 11vw;
          left: auto;
          right: calc(1% + 2px);
          transform: translateX(0%);
        }
        &:nth-child(2) {
          top: 22vw;
          left: auto;
          right: calc(57vw);
          transform: translateX(0%);
        }
        &:nth-child(3) {
          top: 63vw;
          left: auto;
          right: 0%;
          transform: translateX(0%);
        }
        &:nth-child(4) {
          top: 73vw;
          left: auto;
          right: calc(50%);
          transform: translateX(0%);
        }
        &_avatar {
          margin-bottom: 1vw;
          img {
            width: 7vw;
          }
        }
        &_name {
          margin-bottom: 2vw;
          p {
            font-size: 2.4vw;
          }
        }
        &_money {
          padding: 0.7vw 0.2vw;
          padding-top: 1vw;
          p {
            font-size: 2vw;
          }
        }
      }
    }
  }
}
