.auth {
  max-width: 559px;
  margin: 0 auto;
  background: var(--box-bland-bg);
  // padding: 60px 60px;
  padding: 37px 41px;
  padding-bottom: 30px;
  position: relative;
  border: 3px solid var(--purple);
  border-radius: 8px;
  &_comingSoon {
    height: calc(100vh - 61px - 61px);
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 250px;
    p {
      display: block;
      color: #adacae;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }
  }

  &_options {
    // margin-top: 7px;
    width: 100%;
    padding: 16px 16px 0 16px;
    padding-top: calc(16px + 7px);
    position: sticky;
    top: calc(61px);
    background: var(-bg);
    z-index: 500;
    .optionsWrapperAuth {
      width: 100%;
      display: flex;
      background: var(--gray-bg);
      border-radius: 25px;
      position: relative;
      .btnOptAuth {
        padding: 5px 10px;
        width: calc(100% / 2);
        position: relative;
        z-index: 1000;
        height: 26px;
        p {
          color: var(--normal-text-black-color);

          font-size: var(--font-size-s);
          font-style: normal;
          transition: 0.1s;
          font-weight: 400;
        }
        &.active {
          p {
            color: var(--normal-text-color);
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        left: 0%;
        top: 0;
        z-index: 100;
        width: calc(100% / 2);
        height: 100%;
        background: var(--green);
        border-radius: 25px;
        transition: 0.1s;
      }
      &-sign-up {
        &::before {
          left: 0%;
        }
      }
      &-sign-in {
        &::before {
          left: calc(100% / 2);
        }
      }
    }
  }
  &_title {
    padding: 16px;
    display: flex;
    p {
      display: block;
      color: var(--normal-text-black-color);

      font-size: var(--font-size-l);
      font-style: normal;
      font-weight: 500;
    }
    p.viewAll {
      margin-left: auto;
      font-size: 13px;
      font-weight: 400;
      color: #8e8e8f;
      cursor: pointer;
    }
  }
  &_MajorIndices {
    padding: 16px;
    .majorIndicesWrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .stockItem {
        width: 32%;
      }
    }
  }

  &_TopInvestors {
    padding: 16px;
    padding-left: 14px;
    position: relative;
    overflow: hidden;
    .TopInvestorsWrapper {
      width: calc(100% + 70px);
      .investorItemWrapper {
        padding: 8px;
      }
      .investorItemWrapper:nth-child(1) {
        padding-left: 2px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 500;
      width: 16px;
      height: 100%;
      background: var(--bg);
    }
  }
  .info {
    &_title-email {
      padding: 15px 0;
      padding-bottom: 11px;
      p {
        text-align: left;
        font-size: var(--font-size-s);
        font-style: normal;
        // color: #b3afbf;
        color: var(--gray-blur-text);
        font-style: normal;
        font-weight: 400;
      }
    }
    &_title {
      font-size: var(--font-size-l);
      font-family: var(--font-family-overpass);
      text-align: left;
      padding-bottom: 21px;
      p {
        // font-weight: 500;
      }
    }
    &_title-password {
      padding: 11px 0;
      padding-bottom: 11px;
      p {
        text-align: left;
        font-size: var(--font-size-s);
        font-style: normal;
        color: var(--gray-blur-text);
        font-style: normal;
        // font-weight: 500;
      }
    }
    &_close {
      position: absolute;
      top: 36px; // equal padding box
      right: 44px; // equal padding box
      &_button {
        width: 18px;
      }
    }
    &_input {
      padding: 10px 0;
      padding-top: 0px;
      .inputWrapper {
        display: flex;
        height: 39px;
        .dolaText {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--border);
          border-right: none;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          p {
            font-size: 16px;
            font-style: normal;
            color: var(--white);
          }
        }
        input {
          height: 39px;
          width: calc(100%);
          padding: 8px;
          border: 1px solid var(--border);
          background-color: transparent;
          outline: none;
          color: var(--normal-text-black-color);

          font-size: var(--font-size-s);
          font-style: normal;
          font-weight: 400;
          border-radius: 5px;
          .text {
            color: #939394;
          }
        }
        input.dolaInput {
          width: calc(100% - 30px);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        input:focus {
          outline: none;
        }
      }
    }
    &_buttons {
      // padding: 10px 0;
      // padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      &:nth-child(1) {
        padding-top: 29px;
        padding-bottom: 15px;
      }
      .google-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .btn-sign-up {
        background-color: var(--purple);
        border: 1px solid var(--purple);
        padding: 8px 10px;
        border-radius: 5px;
        box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
        width: 100%;
        height: 39px;
        p {
          font-size: 15px;
          font-weight: 500;
          font-style: normal;

          color: var(--white);
        }
        &:hover {
          background: #322a48;
        }
      }
      .auth__button {
        height: 39px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        // box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
        border: 1px solid var(--border-box);
        border-radius: 5px;
        background: var(--white);
        width: 50%;
        span {
          font-size: 15px;
          font-weight: 600;
          color: #757575;
          padding: 12px 0;
          transform: translateY(2px);
        }
      }
      .auth__button_meta {
        height: 39px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        // box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
        border: 1px solid var(--border-box);
        border-radius: 5px;
        background: var(--white);
        width: 50%;
        margin-left: 15px;
        span {
          font-size: 15px;
          font-weight: 600;
          color: #e88f35;
          padding: 12px 0;
          transform: translateY(2px);
        }
      }
      .icon_meta {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    &-checkBox {
      padding: 12px 0;
      padding-bottom: 22px;
      input {
        margin-right: 10px;
      }
      label {
        text-align: left;
        font-size: 12px;
        font-style: normal;
        color: var(--gray-blur-text);
        font-style: normal;
        font-weight: 400;
      }
      [type="checkbox"]:not(:checked),
      [type="checkbox"]:checked {
        position: absolute;
        left: 0;
        opacity: 0.01;
      }
      [type="checkbox"]:not(:checked) + label,
      [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 2.3em;
        // font-size: 1.05em;
        line-height: 1.7;
        cursor: pointer;
      }
      /* checkbox aspect */
      [type="checkbox"]:not(:checked) + label:before,
      [type="checkbox"]:checked + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: -2px;
        width: 1.3em;
        height: 1.3em;
        border: 1px solid #aaa;
        // background: #fff;
        background: transparent;
        // border-radius: 0.2em;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
          0 0 0 rgba(203, 34, 237, 0.2);
        -webkit-transition: all 0.275s;
        transition: all 0.275s;
      }

      [type="checkbox"]:checked + label:before {
        background: var(--purple);
      }

      /* checked mark aspect */
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: -1px;
        // font-size: 1.375em;
        line-height: 0;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;

        // left: 9px;
        // top: 5px;
        width: 5px;
        height: 10px;
        border: solid var(--box-bland-bg);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      /* checked mark aspect changes */
      [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        // -webkit-transform: scale(0) rotate(45deg);
        // transform: scale(0) rotate(45deg);
      }

      [type="checkbox"]:checked + label:after {
        opacity: 1;
        // -webkit-transform: scale(1) rotate(0);
        // transform: scale(1) rotate(0);
      }
    }
  }
}

@media only screen and (max-width: 922px) {
  .auth {
    .info_input {
      .inputWrapper {
        input {
          font-size: var(--font-size-m);
        }
      }
    }
    .info {
      &_buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn-sign-up {
          width: 100%;
        }
        .auth__button {
          padding: 7px 10px;
          width: 100%;
          &_meta {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .auth {
    width: calc(100% - 22px);
    top: 108px;
    padding: 30px 25px;
    .info_title {
      padding-bottom: 22px;
    }
    .info_input .inputWrapper {
      height: 38px;
    }
    .info_buttons {
      .btn-sign-up,.auth__button {
        height: 35px;
      }
    } 
    .info_buttons:nth-child(1) {
      padding-top: 23px;
      padding-bottom: 13px;
    }
    .info_buttons .auth__button_meta {
      display: none;
      margin-top: 13px;
      height: 35px;
    }
  }
  .page-auth {
    align-items: stretch;
  }
  .auth .info_buttons .auth__button span {
  }
}
