.adminTransaction {
  position: relative;
  .top-header {
    padding: 26px 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--bg);
  }
  &_options {
    width: 100%;
    position: sticky;
    top: 92px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0;
    background: var(--bg);
    .selectAdmin {
      width: 220px;
    }
    .selectAdminCustom {
      margin-right: 8px;
      margin-left: 8px;
      .css-cuqz5b-singleValue {
        color: var(--normal-text-black-color) !important;
      }
      .css-yk16xz-control {
        background: transparent;
      }
      .css-1pahdxg-control {
        background: transparent;
      }
      .css-1uccc91-singleValue {
        color: var(--normal-text-black-color);

        font-size: var(--font-size-xs);
        font-style: normal;
        font-weight: 400;
      }
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .css-1gtu0rj-indicatorContainer {
        color: var(--normal-text-black-color);
      }
      // normal
      .css-tlfecz-indicatorContainer {
        padding: 0 8px 0 8px !important;
      }
      .css-tlfecz-indicatorContainer:hover {
        color: var(--normal-text-black-color);
      }
      // case2
      .css-1gtu0rj-indicatorContainer {
        padding: 0 8px 0 8px !important;
      }
      .css-pxjf2u-control {
        border-color: var(--normal-text-black-color);
      }
      .css-1in1kiz-control {
        border-color: var(--normal-text-black-color);
      }
      .css-b8ldur-Input {
        margin: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        input {
          height: 20px !important;
          color: var(--normal-text-black-color) !important;
          font-family: var(--font-family-roboto) !important;
          font-size: 11px !important;
          font-style: normal !important;
          font-weight: 400 !important;
        }
      }
    }
    &_transaction {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .transaction-select {
        display: flex;
        align-items: center;
        label {
          font-size: 18px;
          font-weight: 700;
          color: var(--normal-text-black-color);
        }
      }
      .btnDownload {
        img {
          width: 14px;
          // margin-left: 7px;
          transform: translateX(-7px);
        }
      }
    }
    &_entries {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .show {
        display: flex;
        align-items: center;
        label {
          font-size: 14px;
          color: var(--normal-text-black-color);
        }
        span {
          font-size: 12px;
          color: #b3afbf;
        }
      }
      .pagination {
        // background: red;
        display: flex;
        li {
          height: 24px;
          display: block;
          text-decoration: none;
          padding: 6px 10px;
          border: thin solid var(--normal-text-black-color);
          transition: 0.2s;
          margin: 0 2px;
          cursor: pointer;
          color: #b3afbf;

          font-size: var(--font-size-xs);
          font-style: normal;
          font-weight: 700;
          &.selected {
            background: #ac521e;
            cursor: default;
            pointer-events: none;
          }
          &.disable {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }

    .btnDownload {
      p {
        font-size: 16px;
        color: var(--gray);
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .adminTransaction {
    &_options {
      &_entries {
        .show {
          margin-bottom: 10px;
        }
      }
      .btnDownload {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .adminTransaction {
    &_options {
      .wrapperSelects {
        .wrapperSelect {
          margin: 0px 0 10px 0;
        }
        display: flex;
        width: 100%;
        flex-direction: column;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .adminTransaction {
    &_options {
      &_transaction {
        .transaction-select {
          .selectAdmin {
            width: 140px;
          }
        }
      }
      &_entries {
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .adminTransaction {
    .css-pxjf2u-control,
    .css-f8dy4b-control {
      width: 140px !important;
    }
  }
}

@media only screen and (max-width: 1077px) and (min-width: 977px) {
  .adminTransaction {
    .css-pxjf2u-control,
    .css-f8dy4b-control {
      background-color: var(--body-bg);
      width: 140px !important;
    }
  }
}
