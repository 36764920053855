.topHeaderWrapper {
  // background-color: #342c4b;
  background: var(--bg);
  z-index: 1000;
  width: 100%;
  height: 30px;
  padding-top: 26px;
  padding-bottom: 26px;
  height: calc(26px + 26px + 40px);
  margin: 0 auto;
  height: auto;

  max-width: 1320px;
  // max-width: 960px;
  // width: 960px;

  // position: sticky;
  // top: 0;
  // z-index: 500;
  &-hideDesktop,
  &-transactionPage {
    display: none;
  }
}

.top-header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &_left {
    // width: 70%;
    width: calc(100% - 282px);
    padding-right: 50px;
    display: flex;
  }
  &_right {
    // width: 30%;
    width: calc(282px);
    display: flex;
    position: relative;
    .headerLogo {
      display: none;
    }
    .valuesUser {
      width: calc(100% - 38px - 10px);
      overflow: hidden;
      height: 40px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--border-box);
      &_item {
        width: 100%;
        height: 100%;
        padding: 7px 10px;
        padding-top: 8px;
        padding-bottom: 6px;
        display: flex;
        background: var(--box-bland-bg);
        justify-content: space-between;
        align-items: center;
        // width: 186px;
        border-radius: 4px;
        margin-bottom: 3px;
        p {
          font-size: 16px;
        }
        .right {
          display: flex;
          .icon {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-bottom: auto;
            margin-top: auto;
            img {
              // filter: invert(0.5);
            }
          }
        }
      }
    }
    .bell {
      margin-left: auto;
      width: 38px;
      height: 38px;
      background: var(--box-bland-bg);
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      img {
        display: block;
        margin: auto;
      }
      @media only screen and (max-width: 1110px) {
        display: none;
      }
    }
    .notificationsWrapper {
      position: absolute;
      width: 100%;
      top: calc(38px + 8px);
      background: var(--box-bland-bg);
      border-radius: 8px;
      // padding: 16px;
      padding-top: 16px;
      padding-bottom: 0;
      z-index: 1000;
      p.titleBig {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
        padding: 0 16px;
      }
      .notifications {
        overflow: auto;
        max-height: 400px;
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }
  }
  &_search_button {
    width: 100%;
  }
  &_options {
    display: flex;
    justify-content: space-between;
    width: 40%;
    position: sticky;
    top: 0;
    z-index: 500;
    // padding-left: 15px;
    &-hide {
      display: none; // page portfolio
    }
    .optionsWrapperHome {
      width: 100%;
      display: flex;
      background: var(--box-bland-bg);
      border-radius: 4px;
      position: relative;
      border: 1px solid var(--border-box);
      .btnOptHome,
      .btnOptProfile {
        padding: 5px 10px;
        width: calc(100% / 3);
        position: relative;
        z-index: 1000;
        // height: 26px;
        p {
          color: #707070;
          //
          font-size: 16px;
          font-style: normal;
          transition: 0.1s;
          font-weight: 400;
        }
        &.active {
          p {
            color: var(--white);
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        left: 0%;
        top: 0;
        z-index: 100;
        width: calc(100% / 3);
        height: 100%;
        background: var(--purple);
        border-radius: 4px;
        transition: 0.1s;
      }
      &-DAILY,
      &-HOME,
      &-PORTFOLIO {
        &::before {
          left: 0%;
        }
      }
      &-WEEKLY,
      &-HISTORY {
        &::before {
          left: calc(100% / 3);
        }
      }
      &-MONTHLY,
      &-COMMENTS {
        &::before {
          left: calc(200% / 3);
        }
      }
      &-METRICS {
        pointer-events: none;
        &::before {
          display: none;
        }
      }
    }
  }
  &_logo {
    cursor: pointer;
    padding-left: 16px;
    width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    &_text {
      color: var(--normal-text-black-color);
      font-size: 19px;
      font-weight: 700;
      padding-left: 2px;
    }
    img {
      width: 22px;
      // height: 27px;
      margin-right: 1px;
    }
  }
  &_search {
    position: relative;
    display: flex;
    // justify-content: center;

    // flex-direction: row-reverse;
    align-items: center;
    // width: 75%;
    width: 60%;

    &_icon {
      position: absolute;
      left: 8px;
      top: 9px;
      &_input {
        margin-left: 15px;
      }
    }

    input {
      border: transparent;
      border-radius: 4px;
      padding: 8px;
      padding-left: 35px;
      width: 180px;
      background-color: transparent;
      outline: none;
      color: #898b91;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      background: var(--box-bland-bg);
      width: 90%;
      height: 40px;
      border: 1px solid var(--border-box);
      &::placeholder {
        color: #898b91;
      }
    }
    &-full {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  &_user {
    width: 300px;
    display: flex;
    justify-content: flex-end;
    &_info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      &_avatar {
        margin-right: 3px;
      }
      &_name {
        font-size: 14px;
        font-weight: 500;
        color: var(--normal-text-black-color);
      }
    }
  }
  &_auth {
    display: flex;
    flex-direction: row;
    // padding: 0 0 0 30px;
    // padding-right: 16px;
    justify-content: space-between;
    &_sign-up,
    &_sign-in {
      width: calc(50% - 8px);
      height: 40px;
      border: none;
      background: var(--box-bland-bg);
      border-radius: none;
      color: white;

      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--border-box);

      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #2b2b2d;
      }
    }
    &_sign-in {
      p {
        color: var(--normal-text-black-color);
      }
    }
    &_sign-up {
      border: 1px solid var(--purple);
      background: var(--purple);
      margin-left: 8px;
      p {
        color: var(--white); //#fff;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .top-header_auth_sign-up,
  .top-header_auth_sign-in {
    height: 30px;
    p {
      font-size: 12px;
    }
  }
  .top-header_options .optionsWrapperHome .btnOptHome p,
  .top-header_options .optionsWrapperHome .btnOptProfile p {
    font-size: 12px;
  }
  .topHeaderWrapper {
    padding-bottom: 0px;
    padding-top: 15px;
    // position: sticky;
    // top: 0;
    // z-index: 500;
    &-hideDesktop {
      display: block;
    }
  }
  .top-header {
    display: flex;
    flex-wrap: wrap-reverse;
    &_left {
      width: 100%;
      padding-right: 0px;
      // background: red;
      .top-header_search {
        display: none;
      }
      .top-header_options {
        width: 100%;
        margin-bottom: 17px;
      }
    }
    &_right {
      width: 100%;
      // background: green;
      // height: 30px;
      display: flex;
      margin-bottom: 15px;
      .headerLogo {
        width: 40%;
        display: flex;
        cursor: pointer;
        img {
          width: 20px;
          margin-right: 8px;
        }
        p {
          margin-top: auto;
          margin-bottom: auto;
          font-size: 20px;
          font-weight: 700;
          transform: translateY(1px);
        }
      }
      .valuesUser {
        width: 60%;
        &_item {
          p {
            font-size: 14px;
          }
        }
      }
      .top-header_search_button {
        width: 55%;
        margin-left: auto;
        .top-header_auth_sign-in {
          width: calc(50% - 4px);
        }

        .top-header_auth_sign-up {
          width: calc(50% - 4px);
          margin-left: 0;
        }
      }
      .top-header_auth {
        padding-right: 0px;
      }
    }
    &_logo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      transform: translateY(2px);
      &_text {
        font-size: 18px;
      }
    }
    &_search {
      display: none;
      &_value {
        display: none;
      }
    }
    &_user {
      // display: none;
      width: 0;
      &_info {
        display: none;
      }
    }
    &_auth {
      // display: none;
    }
  }
}
@media only screen and (max-width: 1110px) {
  .top-header {
    box-shadow: none;
    // margin-bottom: 3px;
    &_auth {
      padding-right: 15px;
    }
  }
}
