.social-custom {
  .page-content {
    max-height: 100vh;
  }
}

.social {
  padding-top: 44px;
  width: 100%;
  // height: 100%;
  // background: var(--bg);
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  &_options {
    .optionsWrapper {
      &-RECENT {
        &::before {
          left: calc(0);
        }
      }
      &-POPULAR {
        &::before {
          left: calc(100% / 2);
        }
      }
    }
  }
  .typeChatWrapper {
    padding: 0 16px;
    position: sticky;
    z-index: 1000;
    // top: calc(150px - 16px);
    bottom: 16px;
    // background: var(--bg);
    padding-top: 5px;
    padding-bottom: 16px;
    .uploadInput {
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      // display: none;
    }
  }
  .typeChat {
    box-shadow: 0 2px 1px rgba(57, 63, 72, 0.3), 1px 0 5px rgba(57, 63, 72, 0.3);
    margin: 0 16x;
    padding: 13px 15px;
    background: var(--box-bg);
    border-radius: 10px;
    display: flex;
    // align-items: center;
    flex-direction: column;
    // margin-top: 0;
    // &::before {
    //   content: "";
    //   position:absolute;
    //   z-index: 500;
    //   top: -10px;
    //   left: -1px;
    //   width: calc(100% + 2px);
    //   height: 20px;
    //   background: var(--bg);
    // }
    &_head {
      display: flex;
      align-items: center;
      textarea {
        resize: none;
        border: none;
        background: transparent;
        color: var(--normal-text-black-color);
        
        font-size: var(--font-size-s);
        font-style: normal;
        width: calc(100%);
        flex-wrap: wrap;
        &::-webkit-scrollbar {
          width: 0em;
        }
      }
      &_btnUploadImg,
      &_btnChat {
        width: 32px;
        height: 22.3px;
        // background: red;
        p {
          color: var(--normal-text-black-color);
        }
      }
      &_btnUploadImg {
        img {
          width: 20px;
          transform: translateY(-1px);
          filter: invert(60%);
        }
      }
      &_btnChat {
        img {
          width: 20px;
          filter: invert(70%);
        }
      }
    }
    .imageUploadWrapper {
      // margin-top: 10px;
      padding-top: 10px;
      // height: 40px;
      height: fit-content;
      max-height: calc(100vh - 200px);
      overflow: hidden;
      position: relative;
      img {
        // height: 40px;
        width: 200px;
        border: 1px solid var(--border);
        border-radius: 4px;
      }
      .btnCloseImage {
        background: rgba(0, 0, 0, 0.9);
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        left: calc(200px - 12px);
        transition: 0.3s;
        &:hover {
          background: #000;
        }
        p {
          color: var(--white);
          margin: 0;
          text-align: center;
          transform: translateY(-1px);
        }
      }
    }
  }
  .listsChat {
    padding: 16px;
    padding-top: 10px; //9
  }
  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 350px;
    &_icon {
      svg {
        width: 48px;
        height: 48px;
        g {
          fill: #8e8e8f;
        }
      }
    }
    &_text {
      font-weight: 500;
      font-size: 18px;
      color: #8e8e8f;
    }
    &_images {
      margin-top: 10px;
      // height: 40px;
      height: fit-content;
      max-height: calc(100vh - 200px);
      overflow: hidden;
      img {
        // height: 40px;
        width: 200px;
        border: 1px solid var(--border);
        border-radius: 4px;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .social {
    padding-top: calc(61px + 68px);
    flex-direction: column-reverse;
    display: flex;
    &_options {
      position: fixed;
      width: 100%;
      top: 57px;
      left: 0;
      z-index: 100;
    }
    .typeChatWrapper {
      width: 100%;
      position: fixed;
      bottom: 60px;
      top: auto;
      padding-top: 8px;
      padding-bottom: 0;
      border-bottom: 1px solid #49526c; // var(--border);
      border-bottom: 1px solid var(--border);
      // height: 64px;
      .typeChat {
        box-shadow: none;
        background: transparent;
        //Fix Mobile Comment Entry Zoom
        textarea {
          font-size: var(--font-size-m);
        }
        &_head {
          &_btnUploadImg,
          &_btnChat {
            // width: 32px;
            // height: 22.3px;
          }
        }
      }
    }
    .listsChat {
      width: 100%;
      padding-bottom: calc(50px + 16px);
    }
  }
}
