.landingPage {
  width: 100%;
  display: flex;
  justify-content: center;
  * {
    font-family: var(--font-family-overpass);
  }
  section {
    max-width: calc(220px + 600px + 300px);
    margin-bottom: 40px;
    p {
      font-family: var(--font-family-overpass);
      color: var(--normal-text-black-color);
    }
  }

  .dividerLandingWrapper {
    max-width: calc(220px + 600px + 300px);
    margin-bottom: 40px;
    p {
      font-family: var(--font-family-overpass);
      color: var(--normal-text-black-color);
    }
    .dividerLanding {
      // box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
      // box-shadow: 0 3px 3px red, 1px 1px 3px red;
      box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);
    }
  }
  @import "./startedPage.scss";
  @import "./investorsPage.scss";
  @import "./socialTradingPage.scss";
  @import "./shareAndDiscussPage.scss";
  @import "./stockListPage.scss";
  @import "./monetizePage.scss";

  // custom
  &-socialTrading {
    padding-bottom: 0;
    margin-bottom: 0;
    .landingSection {
      margin-bottom: 0px;
    }
    .landingSection_left_title {
      position: relative;
      z-index: 200;
    }
    .landingSection_left_details {
      position: relative;
      z-index: 200;
    }
  }
  &-pickYesNo {
    margin-bottom: 0px;
  }
  &-monetize {
    .landingSection {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &-shareDiscuss {
    .landingSection {
      padding-top: 0;
    }
  }
}

@media screen and (min-width: 1100px) {
  .landingPage {
    &-pickYesNo {
      .landingSection_left {
        height: 100%;
        padding-top: 65px;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .landingPage {
    &-pickYesNo {
      margin-bottom: 0px;
      .landingSection_right {
        margin-bottom: 0px;
      }
    }
  }
}
