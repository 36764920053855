.monetizeDemo {
  padding-left: 16px;
  margin-top: 85px;
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 600px;
  img.boxSubBg {
    width: 500px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 150;
  }
  img.boxSub {
    width: 370px;
    position: absolute;
    top: 103px;
    left: 53%;
    transform: translateX(-50%);
    z-index: 150;
  }
  .usersWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    left: 50%;
    transform: translateX(-50%);
    .user1 {
      position: absolute;
      top: 77px;
      width: 330px;
      height: 64px;
      border-radius: 6px;
      z-index: 9999;
      left: calc(50% + 105px);
      transform: translateX(-50%);
      overflow: hidden;
      &:hover {
        img:nth-child(1) {
          display: none;
        }
        #Rectangle_179-2 {
          fill: #33555b;
        }
      }
      img,
      svg {
        position: absolute;
        top: 0;
        left: 0px;
        &:nth-child(1) {
          top: -45px;
          left: -45px;
          z-index: 220;
        }
        &:nth-child(2) {
          top: -45px;
          left: -45px;
          z-index: 210;
        }
      }
    }

    .user2 {
      position: absolute;
      top: 325px;
      width: 346px;
      height: 64px;
      border-radius: 6px;
      left: calc(50% - 59px);
      transform: translateX(-50%);
      z-index: 205;
      overflow: hidden;
      &:hover {
        img:nth-child(1) {
          display: none;
        }
        #Rectangle_179-2 {
          fill: #33555b;
        }
      }
      img,
      svg {
        position: absolute;
        top: 0;
        left: 0px;
        &:nth-child(1) {
          top: -45px;
          left: -45px;
          z-index: 220;
        }
        &:nth-child(2) {
          top: -45px;
          left: -45px;
          z-index: 210;
        }
      }
    }
    .userSub {
      width: 100%;
      height: 64px;
      background: var(--box-bg);
      position: relative;
      border-radius: 6px;
      &:hover {
        background: var(--green-hover);
      }
      &_name {
        position: absolute;
        top: 16px;
        left: 16px;
        font-size: 16px;
        font-weight: 700;
      }
      &_date {
        position: absolute;
        bottom: 10px;
        left: 16px;
        font-size: 12px;
      }
      &_bonus {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 16px;
        color: var(--green);
        font-weight: 500;
      }
      &_money {
        position: absolute;
        bottom: 10px;
        right: 16px;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .monetizeDemo {
    min-height: 85vw;
    margin-top: 50px;
    // width: calc(100vw - 32px)
    img.boxSubBg {
      width: calc(100vw - 32px - 16px);
      top: 0;
      left: auto;
      right: 16px;
      transform: translateX(0%);
    }
    .usersWrapper {
      .user1 {
        top: 7vw;
        left: auto;
        right: 0;
        width: calc(55vw);
        height: 10vw;
        transform: translateX(0%);
        // background: rgba(255, 0, 0, 0.491);
        &:hover {
          img:nth-child(1) {
            // display: none;
          }
        }
        img {
          width: 70vw;

          &:nth-child(1) {
            top: -81%;
            left: -13%;
          }
          &:nth-child(2) {
            top: -81%;
            left: -13%;
          }
        }
      }

      .user2 {
        // background: rgba(255, 0, 0, 0.491);
        top: 49vw;
        width: 57vw;
        height: 10vw;
        &:hover {
          img:nth-child(1) {
            // display: none;
          }
        }
        img {
          width: 72vw;
          &:nth-child(1) {
            top: -81%;
            left: -13%;
          }
          &:nth-child(2) {
            top: -81%;
            left: -13%;
          }
        }
      }

      .userSub {
        width: 100%;
        height: 9vw;
        background: var(--box-bg);
        position: relative;
        border-radius: 1vw;
        &_name {
          position: absolute;
          top: 2vw;
          left: 2vw;
          font-size: 2.3vw;
          font-weight: 700;
        }
        &_date {
          position: absolute;
          bottom: 1.4vw;
          left: 2vw;
          font-size: 1.8vw;
        }
        &_bonus {
          position: absolute;
          top: 2vw;
          right: 2vw;
          font-size: 2.3vw;
          color: var(--green);
          font-weight: 500;
        }
        &_money {
          position: absolute;
          bottom: 1.4vw;
          right: 2vw;
          font-size: 1.7vw;
        }
      }
    }
  }
}
