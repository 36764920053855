.withdraw-custom {
  position: fixed;
}

// look like deposit. title, text, button

.withdraw,
.settings,
.wallet {
  &_input {
    padding: 10px 0;
    padding-top: 0px;
    .inputWrapper {
      display: flex;
      .dolaText {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--border);
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        p {
          font-size: var(--font-size-m);
          font-style: normal;
          
          color: var(--normal-text-black-color);
          font-weight: 400;
        }
      }
      input {
        width: calc(100%);
        height: 35px;
        padding: 8px;
        border: 1px solid var(--border);
        background-color: transparent;
        outline: none;
        color: var(--normal-text-black-color);
        
        font-size: var(--font-size-s);
        font-style: normal;
        font-weight: 400;
        border-radius: 5px;
      }
      input.dolaInput {
        width: calc(100% - 30px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      input:focus {
        outline: none;
      }
    }
  }
  &_continue {
    padding-top: 27px;
  }
}

.withdraw,
.wallet {
  &_options {
    &-bank {
      &::before {
        bottom: 0;
        left: calc(50%);
      }
    }
  }
  &_cryptoTypes {
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    .btnCrypto {
      width: 31%;

      border: 1px solid var(--border);
      padding: 8px 10px;
      border-radius: 5px;

      p {
        font-size: var(--font-size-s);
        font-style: normal;
        
        color: var(--normal-text-black-color);
      }
      &:hover {
        background: var(--green-hover);
      }
      &.active {
        background: var(--green);
        border: 1px solid var(--green);
        p {
          color: var(--mirage);
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .withdraw {
    &_input {
      .inputWrapper {
        input {
          font-size: var(--font-size-m);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .withdraw {
    &_text {
      p {
        line-height: 1.4;
      }
    }
    &_input {
      padding-bottom: 0;
    }
    &_continue {
      padding-top: 16px;
    }
  }
}

@media only screen and (max-width: 390px) {
  .withdraw_title p {
    font-size: var(--font-size-s);
  }
  .withdraw_text {
    padding: 13px 16px;
    p {
      font-size: var(--font-size-s);
    }
  }
  .withdraw_title,
  .settings_title {
    padding: 26px 16px 0 16px;
  }

  .withdraw_options,
  .deposit_options {
    .btnCoinbase p {
      font-size: 12px;
    }
  }

  .deposit_options-coin::before,
  .withdraw_options-coin::before,
  .settings_options-coin::before {
    bottom: 0;
    left: -webkit-calc(0% + 50px);
    left: -moz-calc(0% + 50px);
    left: calc(0%);
  }
}
