//
.usersWinLossWrapper {
  &_title {
    background: var(--bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #232325;
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    position: sticky;
    top: 90px;
    width: 100%;
    display: none; // new ui => remove
    &::after {
      content: "";
      position: absolute;
      width: 120%;
      height: 1px;
      bottom: 0;
      right: -5%;
      // background-color: #2b2f39;
      justify-content: center;
      display: flex;
    }
    .left {
      display: flex;
      flex-direction: row;
    }
    padding: 7px 0 15px 0;
    .rank {
    }
    .investor {
      margin: 0 0 0 19px;
    }

    .gain {
      margin: 0 48px 0 0;
    }
  }
  .userWinLossItem {
    width: 100%;
    padding: 5px 0;
    display: flex;
    align-items: center;
    // box-shadow: 0px 2px 1px rgba(57, 63, 72, 0.3),
    //   1px 0px 5px rgba(57, 63, 72, 0.3);
    border-radius: 4px;
    background: var(--box-bland-bg);
    border: 1px solid var(--border-box);
    -webkit-tap-highlight-color: transparent;
    &-win {
      &:hover {
        // background: var(--green-hover);
        border: 1px solid var(--green-hover-border);
      }
    }
    &-loss {
      &:hover {
        // background: var(--red-hover);
        border: 1px solid var(--red-hover-border);
      }
    }
    img {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .id {
      margin: 0 28px 0 7px;
    }
    p {
      display: block;
      color: var(--normal-text-black-color);
      font-size: var(--font-size-s);
      font-style: normal;
      font-weight: 400;
    }
    p.name {
      font-size: 16px;
      font-weight: 600;
      padding-right: 5px;
    }
    p.money {
      margin-left: auto;
      &-win {
        color: var(--green);
      }
      &-loss {
        color: var(--red);
      }
    }
  }
  &-win {
    p.money {
      color: var(--green);
    }
  }
  &-loss {
    p.money {
      color: var(--red);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .userWinLossItem {
    &:hover {
      background: var(--body-bg) !important;
    }
  }
}
