// look like withdraw. title, text, button
// look like withdraw. input

.adminUser {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  height: auto;
  margin-bottom: 90px;
  * {
    font-family: var(--font-family-overpass);
  }
  .adminManageUser {
    width: 70%;
    padding-right: 50px;
    &_options {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      height: 40px;

      top: 80px; //head
      z-index: 100;
      display: flex;
      flex-direction: row;
      align-items: start;
      flex-wrap: wrap;
      .wrapperSelects {
        display: flex;
        width: 100%;
        flex-direction: row;
      }
      .wrapperSelect {
        display: flex;
        align-items: center;
        // margin-right: 8px;
        label {
          font-size: 18px;
          color: var(--normal-text-black-color);
          font-weight: bold;
        }
        span {
          color: var(--normal-text-black-color);
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
        }
        //custom
        .selectAdminCustom {
          margin-right: 8px;
          margin-left: 8px;
          .css-cuqz5b-singleValue {
            color: var(--normal-text-black-color) !important;
          }
          .css-yk16xz-control {
            background: transparent;
          }
          .css-1pahdxg-control {
            background: transparent;
          }
          .css-1uccc91-singleValue {
            color: var(--normal-text-black-color);
            font-size: var(--font-size-xs);
            font-style: normal;
            font-weight: 400;
          }
          .css-1okebmr-indicatorSeparator {
            display: none;
          }
          .css-1gtu0rj-indicatorContainer {
            color: var(--normal-text-black-color);
          }
          // normal
          .css-tlfecz-indicatorContainer {
            padding: 0 8px 0 8px !important;
          }
          .css-tlfecz-indicatorContainer:hover {
            color: var(--normal-text-black-color);
          }
          // case2
          .css-1gtu0rj-indicatorContainer {
            padding: 0 8px 0 8px !important;
          }
          .css-pxjf2u-control {
            border-color: var(--normal-text-black-color);
          }
          .css-1in1kiz-control {
            border-color: var(--normal-text-black-color);
          }
          .css-b8ldur-Input {
            margin: 0px !important;
            padding-bottom: 0px !important;
            padding-top: 0px !important;
            input {
              height: 20px !important;
              color: var(--normal-text-black-color) !important;
              font-size: 11px !important;
              font-style: normal !important;
              font-weight: 400 !important;
            }
          }
          input {
            height: 20px !important;
            color: var(--normal-text-black-color) !important;
            font-size: 11px !important;
            font-style: normal !important;
            font-weight: 400 !important;
          }
        }
      }
    }
    .btnUpdate {
      width: 100px;
      height: 25px;
      border: 1px solid var(--green);
      padding: 5px 10px;
      border-radius: 5px;
      p {
        font-size: 9px;
        font-style: normal;
        color: var(--green);
      }
      &:hover {
        background: var(--green);
        p {
          color: var(--mirage);
        }
      }
    }
    &_update {
      margin-top: 30px;
      background-color: var(--box-bland-bg);
      padding: 30px;
      border-radius: 8px;
      &_text {
        font-size: 12px;
        color: #b3afbf;
        padding-bottom: 5px;
      }
      &_button {
        display: flex;
        .input_left {
          display: flex;
          justify-content: start;
          width: 80%;
          .inputWrapper {
            display: flex;
            .dolaText {
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid var(--border);
              border-right: none;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              p {
                font-size: var(--font-size-s);
                font-style: normal;
                color: var(--normal-text-black-color);
              }
            }
            input {
              width: calc(100%);
              padding: 8px;
              border: 1px solid var(--border);
              background-color: transparent;
              outline: none;
              color: var(--normal-text-black-color);
              font-size: var(--font-size-s);
              font-style: normal;
              font-weight: 400;
              border-radius: 5px;
              border-left: none;
            }
            input.dolaInput {
              width: calc(100% - 30px);
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            input:focus {
              outline: none;
            }
          }
          &_toggleBtn {
            .button {
              border: 1px solid var(--border) !important;
              color: var(--normal-text-black-color);
              font-size: 14px;
              text-transform: capitalize;
              border-radius: 4px !important;
              height: 39px;
              margin-right: 10px;
            }
            .button.Mui-selected {
              background-color: var(--purple);
              color: var(--white);
            }
          }
        }

        .btnContinue {
          background-color: var(--purple);
          width: 103px;
          border-radius: 4px;
          p {
            font-size: 15px;
            color: #fff;
          }
        }
      }
    }

    &_detail {
      background-color: var(--box-bland-bg);
      border-radius: 8px;
      &_info {
        margin: 0 30px 30px;
        padding: 30px 0;
        display: flex;
        // align-content: center;
        align-items: center;
        border-bottom: 2px solid #b9b9b985;
        .image {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 4px;
        }
        .email {
          font-size: 14px;
          font-size: 500;
          // padding-bottom: 20px;
        }
      }
      &_value {
        display: flex;
        justify-content: space-between;
        padding: 0 60px 30px;
        .portfolio-admin {
          .title {
            font-size: 12px;
            color: #b3afbf;
            padding-bottom: 4px;
          }
          .value {
            font-size: 16px;
          }
        }
      }
    }

    &_whiteLists {
      // border: 1px solid #fff;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .whiteList {
        width: 49%;
        &_title {
          font-size: 18px;
          color: var(--normal-text-black-color);
          font-weight: bold;
          margin-bottom: 12px;
        }
        &_box {
          background: var(--box-bland-bg);
          border-radius: 8px;
          &_lists {
            padding-top: 10px;
            padding-bottom: 8px;
            .userWhiteListItem {
              padding: 8px 16px;
              display: flex;
              p.email {
                color: var(--gray);
                font-size: 16px;
              }
              img.remove {
                margin-left: auto;
                width: 20px;
                cursor: pointer;
                opacity: 0.7;
                &:hover {
                  opacity: 1;
                }
              }
            }
          }
          &_select {
            border-bottom: 1px solid var(--border);
            position: relative;
            &_check {
              position: absolute;
              top: 0;
              right: 0;
              width: 50px;
              height: 100%;
              background: var(--box-bland-bg);
              z-index: 1000;
              display: flex;
              img.ok {
                width: 18px;
                margin: auto;
                opacity: 0.7;
                cursor: pointer;
                &:hover {
                  opacity: 1;
                }
              }
            }
            //custom
            .selectAdminCustom {
              margin-right: 8px;
              margin-left: 8px;
              .css-cuqz5b-singleValue {
                color: var(--normal-text-black-color) !important;
              }
              .css-yk16xz-control {
                background: transparent;
              }
              .css-1pahdxg-control {
                background: transparent;
              }
              .css-1uccc91-singleValue {
                color: var(--normal-text-black-color);
                font-size: var(--font-size-xs);
                font-style: normal;
                font-weight: 400;
              }
              .css-1okebmr-indicatorSeparator {
                display: none;
              }
              .css-1gtu0rj-indicatorContainer {
                color: var(--normal-text-black-color);
              }
              // normal
              .css-tlfecz-indicatorContainer {
                padding: 0 8px 0 8px !important;
              }
              .css-tlfecz-indicatorContainer:hover {
                color: var(--normal-text-black-color);
              }
              // case2
              .css-1gtu0rj-indicatorContainer {
                padding: 0 8px 0 8px !important;
              }
              .css-pxjf2u-control {
                border-color: var(--normal-text-black-color);
              }
              .css-1in1kiz-control {
                border-color: var(--normal-text-black-color);
              }
              .css-b8ldur-Input {
                margin: 0px !important;
                padding-bottom: 0px !important;
                padding-top: 0px !important;
                input {
                  width: 100% !important;
                  height: 50px !important;
                  color: var(--normal-text-black-color) !important;
                  font-size: 14px !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                }
              }
              input {
                width: 100% !important;
                height: 50px !important;
                color: var(--normal-text-black-color) !important;
                font-size: 14px !important;
                font-style: normal !important;
                font-weight: 400 !important;
              }
            }
          }
        }
      }
    }
  }
  .userSignUp {
    height: calc(90vh - 88px);
    width: 30%;
    &_top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .signUpTxt {
        font-size: 18px;
        font-weight: 700;
      }
      .downloadBtn {
        p {
          font-size: 16px;
          color: var(--gray);
        }
      }
    }
    &_users {
      height: 100%;
      scroll-behavior: auto;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      &_item {
        display: flex;
        padding: 12px 0 12px 12px;
        background-color: var(--box-bland-bg);
        border-radius: 4px;
        margin-bottom: 10px;

        img {
          width: 30px;
          height: 30px;
          margin-right: 8px;
          border-radius: 50%;
        }
        .text {
          overflow: hidden;
          .name {
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
            width: 175px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .email {
            font-size: 14px;
            padding-bottom: 14px;
            font-weight: 500;
            width: 175px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .time {
            font-size: 12px;
            color: #b3afbf;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1240px) and (max-width: 1300px) {
  .adminUser {
    .adminManageUser {
      &_update {
        &_button {
          flex-direction: column;
          .input_left {
            width: 100%;
          }
          .btnContinue {
            height: 39px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1240px) {
  .adminUser {
    flex-direction: column;
    .adminManageUser {
      width: 100%;
      padding-right: 0;
      &_options {
        .wrapperSelect {
          &:nth-child(2) {
            margin-left: 32px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .userSignUp {
      width: 100%;
      max-width: 100%;
      height: 400px;
      &_top {
        margin-top: 20px;
      }
      &_users {
        &_item {
          .text {
            .name {
              width: calc(100vw - 655px);
            }
            .email {
              width: calc(100vw - 655px);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .adminUser {
    .userSignUp {
      &_users {
        &_item {
          .text {
            .name {
              width: calc(100vw - 100px);
            }
            .email {
              width: calc(100vw - 100px);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 461px) {
  .adminUser {
    .adminManageUser {
      &_options {
        .wrapperSelect {
          &:nth-child(2) {
            margin-left: 32px;
            margin-bottom: 8px;
          }
        }
      }
      &_update {
        &_button {
          flex-direction: column;
          .input_left {
            width: 100%;
          }
          .btnContinue {
            height: 39px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
