.wallet {
  background-color: var(--bg);
  padding-top: 61px;
  &_options {
    position: sticky;
    top: 61px;
    .optionsWrapper {
      &-deposit {
        &::before {
          left: calc(0);
        }
      }
      &-withdraw {
        &::before {
          left: calc(100% / 2);
        //   background: var(--red);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
    .wallet {
        &_optionsMoney {
            .btnMoney {
                width: calc(50% - 8px);
            }
        }
    }
}
