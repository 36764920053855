.comment {
  background: transparent;
  // box-shadow: 0 2px 1px rgba(57, 63, 72, 0.3), 1px 0 5px rgba(57, 63, 72, 0.3);
  border-radius: 4px;
  padding: 10px 16px;
  display: flex;
  margin-bottom: 16px;
  &_avatar {
    width: 24px;
    margin-right: 14px;
    img {
      width: 100%;
      cursor: pointer;
      border-radius: 50%;
    }
  }
  &_content {
    width: calc(100% - 35px - 10px);
    padding-top: 2px;
    &_head {
      display: flex;
      p {
        display: block;
        color: var(--normal-text-black-color);
        font-style: normal;
        margin-bottom: 4px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
      }
      .date {
        color: #b3b2b3;
        font-size: 13px;
        margin-left: auto;
      }
    }
    &_text {
      p {
        color: var(--normal-text-black-color);
        font-style: normal;
        font-size: 14px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        line-height: 1.2;

        span.dola {
          color: var(--green);
        }
      }
    }
    &_images {
      margin-top: 10px;
      img {
        width: 200px;
        border: 1px solid var(--border);
        border-radius: 4px;
        // cursor: zoom-in;
      }
    }
  }
}
