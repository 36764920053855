.footerWrapper {
  background-color: var(--box-bland-bg);
  z-index: 1000;
  width: 100%;
  padding: 40px 60px 30px 50px;
  margin: 0 auto;
  height: auto;

  max-width: 1320px;

  display: flex;
  flex-direction: row;

  border: 1px solid var(--border-box);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .footerLeft {
    flex-basis: 50%;
    .logoWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .logoLeft {
        width: 29px;
        img {
          width: 100%;
        }
      }
      .logoRight {
        padding-top: 3px;
        margin-left: 5px;
        display: flex;
        width: 82px;
        flex-direction: column;
        .title {
          font-size: 18px;
          font-weight: 700;
          color: var(--normal-text-black-color);
        }
        .version {
          font-size: 10px;
          font-weight: 500;
          color: var(--normal-text-black-color);
          opacity: 0.45;
          margin-top: 1px;
          margin-left: auto;
          order: 2;
        }
      }
    }
    .listPage {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      .line {
        margin-bottom: 18px;
        display: flex;
        a {
          display: flex;
          font-size: 14px;
          color: var(--normal-text-black-color);
          margin-right: 29px;
        }
      }
    }
    .poweredBy {
      display: flex;
      background-color: var(--purple);
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      border-radius: 4px;
      width: 200px;
      height: 27px;
      padding: 5px 13px;
      p {
        font-size: 12px;
        font-weight: 500;
        color: #ffff;
        line-height: 27px;
        margin-bottom: unset;
      }
    }
    .credit {
      font-size: 12px;
      font-weight: 400;
      color: var(--normal-text-black-color);
      opacity: 0.6;
      margin-top: 13px;
    }
  }
  .footerRight {
    flex-basis: 50%;
    // padding-left: 100px;
    // padding-right: 30px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: var(--normal-text-black-color);
      padding-top: 9px;
    }
    .content {
      margin-top: 30px;
      font-size: 12px;
      font-weight: 400;
      color: var(--gray);
      line-height: 1.4;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .footerWrapper {
    .footerRight {
      padding-left: 45px;
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .footerWrapper {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 40px 15px;
    margin-bottom: 50px;
    .footerLeft {
      .logoWrapper {
        .logoRight {
          width: auto;
        }
      }
    }
    .footerRight {
      padding-left: 0;
      padding-right: 0;
      margin-top: 33px;
      .title {
        padding-top: 0px;
      }
      .content {
        margin-top: 21px;
        line-height: 1.4;
      }
    }
  }
}
