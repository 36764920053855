.my-bet-item {
  padding: 0 16px;
  border-bottom: 1px solid #2b2f39;
  &__header {
    display: flex;
    align-items: center;
    padding: 18px 24px;
    justify-content: space-between;
    border-bottom: 1px solid #2b2f39;
    .right {
      .your-bet {
        display: none;
      }
    }
    // custom
    .robotomono-bold-white-26px {
      position: relative !important;
      .price {
        position: absolute;
        top: 3px;
        left: calc(100% + 8px);
        font-size: var(--font-size-xs);
        font-weight: 400;
      }
      .volatility {
        position: absolute;
        top: 17px;
        left: calc(100% + 8px);
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .my-bet-item {
    &__header {
      .right {
        .your-bet {
          display: block;
          text-align: right;
          margin-top: 4px;
          font-weight: 700;
          color: var(--green);
          &.red {
            color: var(--red);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1100px) {
  .my-bet-item {
    &:hover {
      background: var(--ebony-clay);
    }
  }
}
