.funds {
  .desktop {
    display: flex;
    width: 100%;
    .home_top_withdraw {
      // transform: translateY(-45px);
    }
  }
  .mobile {
    display: none;
  }
  display: flex;
  padding-bottom: 30px;
  &_money {
    background: var(--box-bland-bg);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--border-box);
    &_padding {
      padding: 32px 50px;
      padding-top: 34px;
      padding-bottom: 28px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &_detail {
      width: 25%;
      &.investment {
        // padding: 0 100px;
      }
    }
  }

  &_padding {
    padding: 30px 36px;
    background: var(--box-bland-bg);
    border-radius: 8px;
    border: 1px solid var(--border-box);
  }
  &_list-button {
    padding-top: 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 520px;
  }
  &_button {
    width: 156px;
    align-items: center;
    text-align: center;
    display: flex;
    border: 1px solid var(--border);
    justify-content: center;
    background: var(--box-bland-bg);
    margin-bottom: 15px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: var(--purple);
    }
    &.active {
      background: var(--purple);
    }
  }
  &_button-click {
    background: var(--purple);
    width: 156px;
    border-radius: 4px;
    margin-top: 25px;
    button {
      width: 100%;
      padding: 15px;
      p {
        color: white;
      }
    }
  }
  &_amount {
    padding-top: 28px;
    &_flex {
      display: flex;
      position: relative;
      .dolaText {
        position: absolute;
        top: 12px;
        left: 10px;
      }
    }
    &_button {
      width: 90px;
      align-items: center;
      text-align: center;
      display: flex;
      border: 1px solid var(--border);
      justify-content: center;
      background: var(--box-bland-bg);
      margin-right: 20px;
      margin-bottom: 15px;
      border-radius: 4px;
      height: 40px;
      cursor: pointer;
      &:hover {
        background: var(--purple);
      }
      &.active {
        background: var(--purple);
      }
      &.usds {
        margin-right: 0;
      }
    }
  }
  &_input {
    border-radius: 4px;
    padding: 8px;
    width: 180px;
    background-color: transparent;
    outline: none;
    color: white;

    font-style: normal;
    font-weight: 400;
    background: var(--box-bland-bg);
    height: 40px;
    border: 1px solid var(--border);
    margin-right: 20px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    input {
      color: var(--white);
      background: transparent;
      width: 100%;
      border: none;
      font-size: 15px;
    }
    p {
      font-size: 14px;
      padding-top: 4px;
    }
  }
  &_input-address {
    padding-left: 0;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    background-color: transparent;
    outline: none;
    color: white;

    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    background: var(--box-bland-bg);
    height: 40px;
    border: 1px solid var(--border);
    margin-right: 20px;
    max-width: 510px;
  }
  &_deposit {
    padding-top: 20px;
  }
  &_sub-title {
    font-size: 14px;
    color: var(--gray);
    padding-bottom: 9px;
  }
  &_fs-20 {
    font-size: 20px;
  }
  &_fs-15 {
    font-size: 15px;
    padding: 15px 0;
  }
  &_fs-23 {
    font-size: 23px;
    padding-bottom: 15px;
    font-weight: 600;
  }
  &_center {
    padding: 120px 0;
    text-align: center;
  }
  &_title {
    font-size: var(--font-size-l);
    padding-bottom: 23px;
    p {
      font-weight: bold;
      font-size: 18px;
    }
  }
  &_subscriptions {
    // width: 70%;
    // padding-right: 50px;
    // padding-bottom: 50px;
  }
  &_follow {
    max-width: 282px;
    height: calc(100vh - 180px);
    position: sticky;
    top: 0;
    // width: 30%;
    overflow: hidden;
  }
  &_history {
    height: calc(100vh - 369px);
    height: 0px;
  }

  &_description {
    height: calc(100vh - 180px);
    height: 859px;
    overflow: auto;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    z-index: 2;
    &_padding {
      width: 100%;
    }
    &_history {
      display: flex;
      justify-content: space-between;
      background: var(--box-bland-bg);
      padding: 20px 15px;
      margin-bottom: 10px;
      border: 1px solid var(--border-box);
    }
    &_date {
      padding-right: 50px;
    }
    &_price-increase {
      color: var(--green);
    }
    &_price-decrease {
      color: var(--red);
    }
  }
  &_description::-webkit-scrollbar {
    display: none;
  }
  &_options {
    position: sticky;
    top: 61px;
    .optionsWrapper {
      &-SUBSCRIPTIONS {
        &::before {
          left: calc(0);
        }
      }
      &-COMMENTS {
        &::before {
          left: calc(100% / 2);
        }
      }
    }
  }
  &_history_nothing {
    height: 100%;
    background: var(--box-bland-bg);
    display: flex;
    // justify-content: center;
    // align-items: center;
    min-height: 200px;
    border: 1px solid var(--border-box);
    border-radius: 8px;
    padding: 16px 16px;
    p {
      color: var(--gray-blur-text-2);
      // font-weight: 500;
    }
  }
  &_user {
    display: flex;
    padding: 16px;
    // padding-top: calc(30px - 3px);
    padding-bottom: 0;
    background: var(--bg);
    &_left {
      width: 80%;
      .infoUser {
        display: flex;
        &_avatar {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        &_detail {
          padding: 0px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;
          p {
            display: block;
            margin-right: auto;
          }
          .name {
            color: var(--normal-text-black-color);

            font-size: var(--font-size-l);
            font-style: normal;
            font-weight: 500;
            margin-bottom: 5px;
          }
          .followers {
            color: var(--normal-text-black-color);

            font-size: 12px;
          }
        }
      }
    }
    &_right {
      .btnFollowing,
      .btnFollow {
        min-width: 91px;
        padding: 6px 12px;
        border: 1px solid var(--green);
        border-radius: 5px;
        p {
          font-size: var(--font-size-xs);
          font-style: normal;

          color: var(--green);
        }
        // &:hover {
        //   background: var(--green);
        //   p {
        //     color: #000;
        //   }
        // }
      }
      .btnFollow {
        background: var(--green);
        p {
          color: var(--white);
        }
      }
      .btnFollowing {
        cursor: auto;
      }
    }
  }
  &_notSubs {
    // height: calc(100vh - 61px - 61px);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-wrap: wrap;
    padding-top: 170px;
    p {
      text-align: center;
      width: 270px;
      display: block;
      color: #adacae;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;
    }
    p.text {
      font-size: 14px;
      line-height: 1.2;
    }
  }
  //custom
  .listsChat {
    padding: 16px;
    padding-top: 9px;
  }
  .userFollowItem {
    // min-height: 59px;
    padding-top: 30px;
    padding-bottom: 30px;
    // border-top: 1px solid var(--border);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background: var(--border);
    }
    &:nth-child(1),
    &-disableBorder {
      padding-top: 14px;
      // background: red;
      &::before {
        background: transparent;
      }
    }
    .my-bet-list {
      .my-bet-item {
        margin-bottom: 0;
      }
    }
  }
}
@media only screen and (max-width: 1240px) {
  .funds_money_detail.investment {
    // padding: 0 100px;
  }
}
// @media only screen and (max-width: 1200px) {
//   .funds_list-button {
//     width: 100%;
//     justify-content: left;
//   }
//   .funds_button {
//     margin-right: 20px;
//     width: 120px;
//   }
// }
@media only screen and (max-width: 1100px) {
  .funds {
    padding-bottom: 20px;
    .funds_list-button {
      width: 580px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      width: 100%;
    }
    .funds_subscriptions {
      width: 100%;
      padding-right: 0;
    }

    .funds_amount_button {
      margin-right: 8px;
      height: auto;
      .funds_fs-15 {
        padding: 12px 0;
      }
      &.usds {
        margin-right: 0px;
      }
    }
    .funds_input-address {
      height: auto;
    }
    .funds_history {
      padding-top: 20px;
      height: 100%;
    }
    .funds_description {
      height: 100%;
      overflow: unset;
    }
    .funds_button {
      margin-right: 20px;
      width: 30%;
      padding: 11px 0;
      margin-right: 0;
      .funds_fs-15 {
        padding: 0;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600

  //@media only screen and (max-width: 620px) {
  .funds {
    .funds_list-button {
      width: 100%;
      justify-content: left;
      max-width: 354px;
      justify-content: space-between;
    }
    .funds_button {
      margin-right: 20px;
      margin-right: 0;
      // width: 98px;
    }
    &_button-click {
      button {
        padding: 9px;
      }
    }
  }
  .funds_button-click {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 455px) {
  .funds {
    &_title {
      font-size: 16px;
      padding-bottom: 12px;
      p {
        font-size: 16px;
      }
    }
    &_money {
      &_padding {
        padding: 17px 30px;
      }
    }
    &_sub-title {
      font-size: 10px;
    }
    &_fs-20 {
      font-size: 14px;
    }
    &_fs-23 {
      font-size: 15px;
      padding-bottom: 13px;
    }
    &_fs-15 {
      font-size: 10px;
    }
    &_padding {
      padding: 20px;
    }
    &_intro {
      .funds_sub-title {
        font-size: 12px;
      }
    }
    &_list-button {
      padding-top: 14px;
    }
    &_button {
      width: 100px;
      margin-right: 15px;
    }
    &_button-click {
      // margin-top: 10px;
      width: 82px;
      button {
        p {
          font-size: 10px;
        }
      }
    }
    &_amount {
      padding-top: 14px;
      .funds_sub-title {
        font-size: 12px;
      }
      &_button {
        margin-right: 10px;
      }
    }
    &_wallet {
      .funds_sub-title {
        font-size: 12px;
      }
    }
    &_input {
      width: 127px;
      margin-right: 10px;
    }
    .funds_money_detail.investment {
      padding: unset;
    }
    .funds_money_padding {
      width: 100%;
    }
    .funds_description_history {
      font-size: 12px;
      p,
      span {
        font-size: 12px;
      }
    }
  }
  .funds_history_nothing {
    p {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .funds_description_history {
    padding: 15px;
    padding-bottom: 13px;
  }
  .funds_description_padding {
    .funds_description_history:last-child {
      margin-bottom: 0px;
    }
  }
}
