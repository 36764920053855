:root {
  --caribbean-green: #16c196;
  --black: #232325;
  --yellow: #cdc832;
  --cloud: #c7c7c7;
  --ebony-clay: #252a39;
  --mirage: #131722;
  --rose-pearl: #f32e60;
  --white: #ffffff;
  //new color
  --green: #24cfa4;
  --green-hover: #33555b;
  --green-hover-border: #24cea4;
  --red: #ff8282;
  --red-hover: #664747;
  --red-hover-border: #ff8282;
  --body-bg: #23272a; // #322a48;
  --body-landing-bg: #262a3a;
  --header-bg: #333d5b;
  --bubble-highlight: #333d5b;
  --border: #6c6e71;
  --normal-text-color: #252a39;
  --purple: #7f72da;
  --chat-text-color: #e4e4e4; // not include username
  --border-box: transparent;
  --gray: rgba(255, 255, 255, 0.6);
  --gray-blur: #6b6d70; // => #7A7C80;
  --gray-blur-text: #abacad; // => #7A7B7F
  --gray-blur-text-2: #abacad; // => #7A7C80
  --gray-text-3: #abacad; //
  //
  --normal-text-black-color: #ffffff; // => dark: :  #FFFFFF
  --gray-bg: #333d5b; // => dark: : #333D5B
  --box-bg: #23272a; //#433960; //  => dark: : #333D5B
  --box-bland-bg: #2c2f33; // #433960  => dark: : #333D5B
  --bg: #23272a; // #342c4b; //  => dark: : #252A39
  --gray-chart: #6c6e71; // #6C6E71 => light : #B5B5B9
  --sidebar-bg: #2c2f33;
  --sidebar-item-bg: #23272a;
  --right-bar-bg: transparent; //#2c2f33;
  --right-bar-title-bg: #23272a;
  --btn-buy-sell-bg: #23272a;
  --border-comment: #f7f7f81c;
  --border-type-chat: #393d40;
  //end new color

  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 26px;
  --font-size-xs: 12px;
  --font-family-gemeli_mono: "Gemeli Mono", Helvetica;
  --font-family-gemelimono-bold: "GemeliMono-Bold", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-roboto_mono: "Roboto Mono", Helvetica;
  --font-family-overpass: "Overpass", Helvetica;
}

.footerLogoLight {
  display: none;
}

[data-theme="light"] {
  //new color
  --green: #23cfa4; // => dark : #23CFA4
  --green-hover: #e0f9f2; // => dark : #33555B
  --red: #ff8282; // => dark: : #FF8282
  --red-hover: #ffe5ec; // => dark: : #664747
  --body-bg: #f9f9f9; // => dark: : #252A39
  --header-bg: #ffffff; // => dark: : #333D5B
  --bubble-highlight: #ffffff; // => dark: : #333D5B
  --border: #c3c3c7; // => dark: : #49526C
  --border-box: #dcdbdf;
  --gray: #2c2f33;
  --gray-blur: #7a7c80;
  --gray-blur-text: #7a7b7f;
  --gray-blur-text-2: #7a7c80;
  --normal-text-color: #ffffff; // => dark: :  #252A39
  --chat-text-color: #515561; // not include username  => dark: :  #E4E4E4
  --gray-text-3: #7a7c80;

  //
  --normal-text-black-color: #2c2f33; // #232325; // => dark: :  #FFFFFF
  --gray-bg: #edebed; // => dark: : #333D5B
  --box-bg: #f9f9f9; //  => dark: : #333D5B
  --box-bland-bg: #f0eff3; // #fbfafb; //  => dark: : #333D5B
  --bg: #ebeaef; //#ffffff; //  => dark: : #252A39
  --gray-chart: #b5b5b9;
  --sidebar-bg: #ebeaef; // => dark: #2c2f33
  --sidebar-item-bg: #f0eff3; // => dark: #23272a
  --right-bar-bg: #ebeaef; // => dark: #2c2f33
  --right-bar-title-bg: #f0eff3; // => dark: #2c2f33
  --btn-buy-sell-bg: #ebeaef;
  --border-comment: #dcdbe0;
  --border-type-chat: #dcdbe0;
  //end new color

  //custom
  .home_top_left .chartStock_options::before,
  .userPage_top_left .chartStock_options::before {
    background: var(--purple);
  }
  .voteStockItem_numVote svg path {
    opacity: 1;
    fill: var(--gray-blur);
  }

  .voteStockItemCreate_icon svg path {
    fill: var(--gray-blur);
  }

  .bigMoversItem,
  .metricsChartItem .chartStock_chartButton .btn-green,
  .bigMoversItem,
  .metricsChartItem .chartStock_chartButton .btn-red {
    color: var(--white) !important;
  }
  .chartStock_chartButton .btn-green {
    background: var(--green) !important;
  }
  .chartStock_chartButton .btn-red {
    background: var(--red) !important;
  }
  .sidebar {
    border-right: 1px solid #d8d8dd;
  }
  .sidebar_menu .sidebar_menu_item_icon svg,
  .footer-mobile_item svg {
    filter: invert(60%);
  }
  .sidebar_menu .active .sidebar_menu_item_icon svg {
    filter: invert(100%);
  }
  .footer-mobile_item.active svg {
    filter: invert(0%);
  }

  .stockItem_footer_investor img,
  .stockItem_footer_money img {
    filter: invert(0);
  }

  // .btnAddLiquidity {
  //   &:hover {
  //     background: #e9e7ef !important;
  //   }
  // }

  .social .typeChat_head_btnUploadImg img {
    filter: invert(40%);
  }
  .social .typeChat_head_btnChat img {
    filter: invert(30%);
  }
  .userPage_subscription .btnSubscribe .starText img {
    filter: invert(1);
  }
  .top-header {
    // border-bottom: none; // var(--body-bg);
    // margin-bottom: 5px;
    border-bottom: 2px solid #e8e8e8;
  }
  .top-header_user .changeThemeWrapper {
    background: var(--gray-bg);
  }
  .top-header_right .valuesUser_item .right .icon {
    // filter: invert(0.5);
  }
  .home_top_right .homeTopInvestors_lists .userWinLossItem .name {
    opacity: 0.8;
  }
  .chart {
    background: var(--box-bg);
  }
  .chart_title_right_avatars_item a img {
    border: 2px solid var(--box-bg);
  }

  .btnPrev,
  .btnNext {
    background: #d8d7da !important;
    img {
      filter: invert(1);
    }
  }
  .rulesSummary_item {
    .leftIcon {
      img {
        filter: invert(100%);
      }
    }
  }
  .btnAddLiquidity {
    img {
      filter: invert(1);
    }
  }
  .stockNew {
    &:hover {
      background: #eeedf1 !important;
    }
  }
  .footerLogoDark {
    display: none;
  }
  .footerLogoLight {
    display: block;
  }
}

[data-theme="dark-stock-chart"] {
  --box-bland-bg: #131722;
  --sidebar-bg: #131722;
}

.roboto-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-cloud-12px {
  color: var(--cloud);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-caribbean-green-16px {
  color: var(--caribbean-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-rose-pearl-16px {
  color: var(--red);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-caribbean-green-14px {
  color: var(--caribbean-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.robotomono-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-caribbean-green-12px {
  color: var(--caribbean-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.robotomono-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.robotomono-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-caribbean-green-26px {
  color: var(--caribbean-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
}

.roboto-normal-white-26px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.roboto-normal-caribbean-green-26px-2 {
  color: var(--caribbean-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.robotomono-light-cloud-14px {
  color: var(--cloud);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 300;
}

.gemelimono-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-gemeli_mono);
  font-size: var(--font-size-s);
}

.gemelimono-bold-white-14px-2 {
  color: var(--white);
  font-family: var(--font-family-gemelimono-bold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.robotomono-bold-white-26px {
  color: var(--white);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.robotomono-bold-caribbean-green-16px {
  color: var(--green);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.robotomono-bold-white-18px-2 {
  color: var(--white);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-l);
}

.robotomono-bold-caribbean-green-18px {
  color: var(--green);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.robotomono-bold-rose-pearl-18px {
  color: var(--red);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.roboto-medium-mirage-12px {
  color: var(--mirage);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.robotomono-bold-rose-pearl-16px {
  color: var(--red);
  font-family: var(--font-family-roboto_mono);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-caribbean-green-12px {
  color: var(--caribbean-green);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.border-1px-cloud {
  border: 1px solid var(--cloud);
}

.border-1px-caribbean-green {
  border: 1px solid var(--caribbean-green);
}

.border-1px-white {
  border: 1px solid var(--white);
}

.green-text {
  color: var(--green);
  p,
  span {
    color: var(--green);
  }
}

.red-text {
  color: var(--red);
  p,
  span {
    color: var(--red);
  }
}

.yellow-text {
  color: var(--yellow);
  p,
  span {
    color: var(--yellow);
  }
}

.text-not-records {
  padding: 20px 16px;
  max-height: 60px;
  position: fixed;
  width: 100%;
  top: 80px;
  background: var(--mirage);
  z-index: 100;
  p {
    color: var(--white);
    font-family: var(--font-family-overpass);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
  }
}

.enableBlur {
  filter: blur(3px);
  // custom
  &.shareImageWrapper {
    filter: blur(0);
  }
}

.isDesktop {
  @media only screen and (max-width: 1100px) {
    // update ui responsive , 992 => mobile 600
    // @media only screen and (max-width: 600px) {
    display: none !important;
  }
}
.isMobile {
  @media only screen and (min-width: 1101px) {
    // @media only screen and (min-width: 600px) {
    display: none !important;
  }
}
