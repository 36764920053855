.bigMoversItem,
.metricsChartItem {
  width: 100%;
  margin-bottom: 16px;
  background: var(--box-bland-bg); //#fbfafb;
  border-radius: 8px;
  padding: 15px 17px 18px 17px;
  cursor: pointer;
  // box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // height: 152px;
  border: 1px solid var(--border-box);
  -webkit-tap-highlight-color: transparent;

  &:hover {
    // background: var(--green-hover);
    border: 1px solid var(--green-hover-border);
  }
  &-green {
    &:hover {
      // background: var(--green-hover);
      border: 1px solid var(--green-hover-border);
    }
  }
  &-red {
    &:hover {
      // background: var(--red-hover);
      border: 1px solid var(--red-hover-border);
    }
  }

  &_header {
    display: flex;
    // margin-bottom: 23px;
    margin-bottom: 3px; // char + 20
    align-items: center;
    height: 33px;

    &_name {
      width: 70%;
      display: flex;
      &_logo {
        margin-right: 10px;
        position: relative;
        img {
          width: 33px;
          border-radius: 50%;
          position: relative;
          z-index: 100;
        }
      }
      &_info {
        padding-top: 4px;
        p {
          color: var(--normal-text-black-color);
          font-style: normal;
          font-weight: 700;
          &:nth-child(2) {
            // fix .text not working
            font-size: 12px;
            color: var(--gray-blur-text);
            font-weight: 400;
          }
        }
        .title {
          font-size: 15px;
          margin-bottom: 2px;
        }
        .text {
          font-size: 12px;
          color: var(--gray-blur-text);
          font-weight: 400;
        }
      }
    }
    &_price {
      width: 30%;
      padding-top: 4px;
      display: flex;
      flex-direction: column;
      p {
        font-size: 15px;
        font-style: normal;
        text-align: right;
        font-weight: 400;
        // margin-bottom: 2px;
        height: 14px;
        margin-bottom: 4px;
        display: flex;
        margin-left: auto;
      }
      .volatility {
        font-size: 12px;
        height: 12px;
      }
    }
  }

  //
  .chartStock {
    &_chartOne {
      position: relative;
      &_lineHoz {
        position: absolute;
        top: 0px;
        left: 16px;
        z-index: 50;
        // transform: translateX(-1000px);
        pointer-events: none;
        &::before {
          content: "";
          position: absolute;
          top: 0px;
          left: 50%;
          z-index: 100;
          // left: -10000px;
          width: 1px;
          height: 447px;
          background: #898b91;
          pointer-events: none;
          // background: red;
        }
      }
      &_activeDot {
        position: absolute;
        top: 0px;
        left: -10000px;
        z-index: 50;
        transform: translate(-50%, -50%);
        pointer-events: none;
        width: 7px;
        height: 7px;
        background: var(--purple);
        border-radius: 20px;
        pointer-events: none;
      }
      &_times {
        display: flex;
        // margin-top: 3px;
        height: 20px;
        border-top: thin solid var(--border-comment); // #898b91;
        border-bottom: thin solid var(--border-comment); // #898b91;
        padding: 6px 6px;

        // set chart
        pointer-events: none;
        position: absolute;
        bottom: calc(100px + 84px);
        left: 0;
        width: 100%;
        // end set chart
        p {
          font-size: 7px;
          color: var(--normal-text-black-color);
          transform: translateX(-50%);
          position: absolute;
          top: 5px;
          left: 0;
          white-space: nowrap;
        }
        p.first {
          margin-right: auto;
        }
        p.last {
          margin-left: auto;
        }
      }

      .chartStockTooltip {
        // position: relative;
        // z-index: 200;
        pointer-events: none;
        .time {
          margin-bottom: 6px;
          p {
            font-size: 11px;
            color: #898b91;
          }
        }
        .price {
          width: 50px;
          border-radius: 3px;
          background: var(--box-bg);
          padding: 5px 0;
          p {
            text-align: center;
            font-size: 11px;
          }
          &_green {
            color: var(--green);
            margin-bottom: 3px;
          }
          &_red {
            color: var(--red);
          }
        }
      }

      //custom
      .recharts-responsive-container {
        circle {
        }
      }
      .recharts-tooltip-wrapper {
        z-index: 300;
      }
      // end custom
    }

    &_chartButton {
      display: flex;
      button {
        height: 25px;
        border-radius: 4px;
        font-size: 14px;
        cursor: default;
      }
      .btn-green {
        background-color: transparent;
        border: 1px solid var(--green);
        margin: 0 10px 0px 0px;
        transition: 0.5s;
        min-width: 70px;
        color: var(--green);
        font-size: 12px;
        font-weight: 600;
      }
      .btn-red {
        margin: 0 0px 0px 0px;
        transition: 0.5s;
        min-width: 70px;
        border: 1px solid var(--red);
        background: transparent;
        font-size: 12px;
        font-weight: 600;
        color: var(--red);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .bigMoversItem,
  .metricsChartItem {
    padding: 14px;
  }
}

@media only screen and (max-width: 1100px) {
  .bigMoversItem,
  .metricsChartItem {
    &_header {
      height: 28px;
      &_name {
        &_logo {
          img {
            width: 28px;
          }
        }
        &_info {
          .title {
            font-size: 12px;
          }
          .text {
            font-size: 10px;
          }
        }
      }

      &_price {
        padding-top: 7px;
        p {
          font-size: 12px;
        }
        .volatility {
          font-size: 10px;
          height: 10px;
          transform: translateY(-1px);
        }
        //custom
        .ticker-view {
          span {
            font-size: 12px;
          }
          .number-placeholder {
            font-size: 12px;
          }
        }
      }
    }

    //
    .chartStock {
      &_chartButton {
        button {
          height: 20px;
        }
        .btn-green {
          font-size: 10px;
        }
        .btn-red {
          font-size: 10px;
        }
      }
    }
    // custom
    .recharts-responsive-container {
      height: 100px !important;
    }
  }
}

// metricsChartItem

.metricsChartItem {
  &_header {
    &_name {
      width: calc(100% - 15px);
      &_logo {
        img {
          transform: translateY(7px);
        }
      }
      &_info {
        .title {
          line-height: 1.3;
          transform: translateY(6px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .metricsChartItem {
    &_header {
      &_name {
        width: calc(100%);
      }
    }
  }
}
