.chat {
  width: 100%;
  position: relative;
  z-index: 1000;
  background: var(--bg);

  .accountInfo {
    padding-top: 18px;
    .accountValue {
      .text {
        color: var(--normal-text-black-color);
      }
    }
  }
  .titleChat {
    padding-left: 25px;
    border-radius: 4px;
    height: 40px;
    align-items: center;
    display: flex;
    background-color: var(--right-bar-title-bg);
    color: var(--normal-text-black-color);
    border: 1px solid var(--border-box);
    border-left: none;
  }
  &_social {
    // margin-top: 20px;
    // padding: 16px;
    // max-height: 585px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    // background-color: none;
    // background: var(--right-bar-bg);

    .social {
      // padding: 16px;
      display: flex;
      height: auto;
      flex-direction: column-reverse;
      height: 100%;
      // justify-content: end;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 15px;
      background: var(--right-bar-bg);
      border-radius: 5px;
      .typeChatWrapper {
        padding: 0;
        margin-right: 10%;
        width: 94%;
        border: none;
        // &::before {
        //   position: absolute;
        //   content: "";
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 1px;
        //   background: var(--border);
        // }
      }
      .typeChat {
        margin-top: auto;
        box-shadow: none;
        border-radius: 0;
        flex-direction: column-reverse;
        border-radius: 8px;
        background: var(--box-bland-bg);
        border: none;

        textarea {
          font-size: 12px;
          width: calc(90%);
        }
        .imageUploadWrapper {
          margin-top: 0px;
          margin-bottom: 5px;
          height: fit-content;
          max-height: 400px;
          overflow: hidden;
          img {
            // height: 80px;
            width: 100%;
            // width: auto;
            border: 1px solid var(--border);
            border-radius: 4px;
          }
          .btnCloseImage {
            left: calc(206px);
            top: 11px;
            right: 1px;
            left: auto;
            border-radius: 0;
            border-top-right-radius: 3px;
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .listsChat {
        margin: 0;
        padding: 0;
        margin-bottom: 105px;
        width: 100%;
        // height: calc(100vh - 300px);
        // max-height: calc(100vh  - 290px);
        // background: red;
        scroll-behavior: auto;
        overflow: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0em;
        }
        #bar-checkScroll-chat {
          width: 100%;
          height: 1px; // fix scrollId elm not see
          background: transparent;
        }
        .comment {
          margin: 0;
          padding: 0;
          margin-bottom: 20px;
          margin-left: 20px;
          margin-right: 16px;
          box-shadow: none;
          border-radius: 0;
          border-bottom: 2px solid #f7f7f81c;
          padding: 10px 0;
          background: none;
          &_content {
            &_head {
              .name {
                font-size: 12px;
              }
              .date {
                font-size: 12px;
              }
            }
            &_text {
              p {
                font-size: 12px;
                margin-bottom: 1px;
              }
            }
            &_images {
              margin-top: 5px;
              // margin-bottom: 5px;
              height: fit-content;
              max-height: 400px;
              overflow: hidden;
              img {
                // height: 80px;
                width: 100%;
                // width: auto;
                border: 1px solid var(--border);
                border-radius: 4px;
              }
            }
          }
        }
      }
      &_content {
        // height: 100%;
        // padding: 50% 0;
      }
    }
  }
  &_input {
    position: relative;
    input {
      border-radius: 8px;
      padding: 8px;
      padding-left: 32px;
      width: 100%;
      background-color: transparent;
      outline: none;
      color: var(--white);

      font-size: var(--font-size-s);
      font-style: normal;
      font-weight: 400;
    }
    input::placeholder {
      color: var(--cloud);
      font-weight: 300;
    }
    &_searchIcon {
      position: absolute;
      top: 7px;
      left: 8px;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .chat {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .chat {
    .titleChat {
      font-size: 16px;
      font-weight: 600;
      padding-left: 0;
      border-left: 1px solid var(--border-box);
    }
    .chat_social {
      border-radius: 0;
    }
    .chat_social .social {
      padding-top: 10px;
      margin-bottom: 20px;
      .typeChatWrapper {
        background: var(--bg);
      }
      .typeChat {
        border-radius: 0;
        background: var(--bg);
        position: relative;
        // &::before {
        //   position: absolute;
        //   content: "";
        //   bottom: 0px;
        //   left: -50px;
        //   width: 200%;
        //   height: 1px;
        //   background: var(--border-type-chat);
        // }
        &::after {
          position: absolute;
          content: "";
          top: 0px;
          left: -50px;
          width: 200%;
          height: 1px;
          background: var(--border-type-chat);
        }
      }
    }
    .chat_social .social .listsChat .comment {
      margin-left: 18px;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--border-comment);
      &_content_head {
        .name {
          opacity: 0.8;
        }
      }
      &_content_text {
        p span {
          opacity: 0.6;
        }
        p span.dola {
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .chat .chat_social .social .listsChat .comment {
    margin-left: 4px;
    margin-right: 0;
    padding: 0;
    border-bottom: 1px solid transparent;
    margin-bottom: 28px;
  }
  .chat .chat_social .social .listsChat .comment_content_head .name {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .chat_social .social .listsChat .comment_content_text p {
    font-size: 14px;
  }
  .chat {
    .titleChat {
    }
    &_social {
      .social {
        background: transparent;
        .listsChat {
          .comment {
            // margin-left: 4px;
            // padding: 0;
            // border-bottom: 1px solid transparent;
          }
        }
        .typeChatWrapper {
        }
        .typeChat {
          &_head {
            textarea {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
