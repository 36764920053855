.stockList {
  &_options {
    margin-bottom: 40px;
    .optionsWrapper {
      display: flex;
      .btnOpt {
        outline: 0;
        outline-color: transparent;
        padding: 4px 16px 2px;
        width: max-content;
        margin-right: 8px;
        border: 1px solid transparent;
        p {
          color: var(--normal-text-black-color);
          font-family: var(--font-family-overpass);
          font-size: var(--font-size-m);
          font-style: normal;
          transition: 0.1s;
          font-weight: 400;
        }
        &.active {
          border: 1px solid var(--green);
          border-radius: 16px;
          p {
            color: var(--green);
          }
          transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
      }
    }
  }
  &_stockItems {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 18px;
    row-gap: 8px;
  }
  // custom
  .stockItem {
    &_header {
      &_price {
        .green-text {
          color: var(--green);
          p,
          span {
            color: var(--green);
          }
        }

        .red-text {
          color: var(--red);
          p,
          span {
            color: var(--red);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .stockList {
    &_options {
      .optionsWrapper {
        .btnOpt {
          p {
            font-size: var(--font-size-s);
          }
        }
      }
    }
    &_stockItems {
      margin-bottom: 60px;
    }
  }
}
