html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-overpass);
  // font-family: Arial, Helvetica, sans-serif;
  background: var(--bg);
  p {
    font-family: var(--font-family-overpass);
    // font-family: Arial, Helvetica, sans-serif;
  }
  button,
  textarea {
    font-family: var(--font-family-overpass);
  }
  img,
  svg {
    -webkit-transform: translate3d(0, 0, 0);
  }
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  color: var(--normal-text-black-color);
}

* {
  box-sizing: border-box;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@100;400;500;600;700;800;900&display=swap");
.screen {
  background-color: var(--bg); // update new ui => remove it
  height: 100vh;
  // overflow: hidden;  // test fix scrollbar
  overflow-x: hidden; // test fix scrollbar
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-shrink: 1 !important;
  width: 100vw;
  position: fixed;
}

.screen textarea:focus,
.screen input:focus {
  outline: none;
}

.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.screen div {
  -webkit-text-size-adjust: none;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
// .page {
//   position: relative;
//   width: 100%;
.page-auth {
  position: fixed;
  top: 0;
  width: 100%;
  /* background: #855b9e2e; */
  z-index: 1000;
  height: 100vh;
  align-items: center;
  display: flex;
  .auth-container {
    width: 100%;
  }
}
// }
.page-content {
  // width: 90%;
  // max-width: 1140px;
  // max-width: 960px;
  // width: 960px;
  width: calc(1000px + 30px);
  max-width: calc(1000px + 30px);
  @media only screen and (min-width: 600px) {
    padding: 0 15px;
    max-height: 100vh;
  }

  // width: 60%;

  overflow: auto;
  // padding: 0 3%;

  margin: 0 auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &_disable {
    opacity: 0.3;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 50px;
  }
}

.page-content > div {
  // margin-bottom: 32px;
}

.rawHeader {
  width: 100%;
  height: 80px;
  // border-bottom: 1px solid #2b2f39;
  // background: var(--mirage);
}
.rawQuestion {
  width: 100%;
  height: 60px;
  // border-bottom: 1px solid #2b2f39;
  // background: var(--mirage);
  position: sticky;
  top: 0;
}

//custom
#ReactSimpleImageViewer {
  z-index: 2000;
  background-color: #000000ba;
  .react-simple-image-viewer__close {
    display: none;
  }
  img {
    transform: scale(0.8);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .page-content {
    padding: 0 11px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

@media only screen and (max-width: 768px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

@media only screen and (max-width: 1100px) {
  .screen {
    position: relative;
    height: auto;
    min-height: 100vh;
    overflow-x: initial;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
  }
  .sidebar {
    padding: 0 16px 0 26px;
    width: 230px;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--box-bland-bg);
    height: 100vh;
    z-index: 1000;
    display: none;
    &_links {
      margin-top: 10px;
    }
    //custom
    .userInfoWrapper {
      .userInfo {
        background: var(--mirage);
      }
    }
    .accountInfo {
      display: block;
      background-color: var(--mirage);
      margin-top: 10px;
      .accountValue,
      .currentBet,
      .availFund {
        padding: 0;
        flex-direction: column;
        margin-bottom: 10px;
        .text {
          font-size: var(--font-size-xs);
          margin-bottom: 5px;
        }
      }
      .availFund {
        margin-bottom: 0;
      }
    }
  }
  .rightBar {
    display: none;
  }
  .page-content {
    width: 100%;
    padding-bottom: 61px;
    overflow: initial;
    &_disable {
      opacity: 0.3;
    }
  }
}
/* Large devices (laptops/desktops, 1100px and up) */
@media only screen and (min-width: 1100px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

// custom
.slick-list,
.slick-slider,
.slick-initialized {
  -webkit-tap-highlight-color: transparent !important;
  * {
    -webkit-tap-highlight-color: transparent !important;
    &:focus {
      outline: none !important;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .page-content {
    width: 400px;
    max-width: 400px;
  }
}
