[class~="[timeHome]-custom"] {
  .top-header_right .notificationsWrapper {
    .notifications {
      max-height: 417px;
    }
  }
}

[class~="u-custom"] {
  .top-header_right .notificationsWrapper {
    .notifications {
      max-height: 367px;
    }
  }
}

[class~="funds-custom"] {
  .top-header_right .notificationsWrapper {
    .notifications {
      max-height: 872px;
    }
  }
}

.home, // stock page like home
.userPage {
  background: var(--white);
  background: var(--bg);
  padding-bottom: calc(30px - 18px + 6px);
  // padding-top: 61px;
  &-symbolPage {
    margin-bottom: 30px;
    padding-bottom: 0;

    .home_top_right {
      height: 712px;
    }
    // custom header
    .top-header_right .notificationsWrapper {
      top: calc(64px + 8px);

      .notifications {
        max-height: 520px;
      }
    }
    // end custom header
    @media only screen and (max-width: 1100px) {
      // update ui responsive , 992 => mobile 600
      // @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
      .home_top_right {
        height: fit-content;
      }
    }
  }

  &_btns {
    // admin page
    display: flex;
    margin-bottom: 16px;
    .btnOptStockAdmin {
      padding: 3px 16px;
      border-radius: 20px;
      margin-right: 6px;
      background: var(--box-bland-bg);
      p {
        color: var(--gray-blur-text);
        font-size: 13px;
      }
      &.active {
        background: var(--purple);
        p {
          color: var(--normal-text-black-color);
        }
      }
    }
  }

  &_comingSoon {
    height: calc(100vh - 61px - 61px);
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: 250px;
    width: 100%;
    height: 100%;
    background: var(--box-bland-bg);
    border: 1px solid var(--border-box);
    border-radius: 8px;
    p {
      display: block;
      color: #adacae;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
    }
  }
  .home_titleWrapper {
    display: flex;
  }
  &_title {
    padding: 20px 0 18px;
    display: flex;

    position: relative;

    &-50-1 {
      width: calc(50% + 10px);
    }
    &-50-2 {
      width: calc(50% - 10px);
    }

    @media only screen and (max-width: 1100px) {
      // update ui responsive , 992 => mobile 600
      // @media only screen and (max-width: 600px) {
      &-hideMobile {
        display: none;
      }
    }
    @media only screen and (min-width: 993px) {
      // @media only screen and (min-width: 601px) {
      &-hideDesktop {
        display: none !important;
      }
    }

    &_buttons {
      position: absolute;
      top: 16px;
      right: 10px;
      display: flex;
      .btnPrev {
        margin-right: 8px;
        img {
          transform: rotateZ(180deg);
        }
      }
      .btnNext,
      .btnPrev {
        border-radius: 50%;
        background: #424448;
        width: 22px;
        height: 22px;
        img {
          width: 22px;
          opacity: 0.5;
        }
        &.disable {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }

    &-topInvestors {
      padding-bottom: calc(18px - 5px);
      padding-top: 15px;
      display: none;
    }
    &-trendStocks, //
    // &-topInvestors,
    &-bigTech,
    &-techStocks,
    &-memeStocks,
    &-otherStocks {
      // padding-top: 12px; // 30 = 18 + 12
      padding-top: calc(27px); // 45 = 18 + 27
    }

    &-trendStocks {
      padding-top: 10px;
      //For only the top "Trending Stock" row, please decrease spacing
    }
    &-stockAdmin {
      justify-content: space-between;
      .btnDownload {
        p {
          font-size: 16px;
          color: var(--gray);
          font-weight: 400;
        }
      }
    }
    p {
      display: block;
      color: var(--normal-text-black-color);

      font-size: var(--font-size-l);
      font-style: normal;
      font-weight: 700;
    }
    p.viewAll {
      margin-left: auto;
      font-size: 13px;
      font-weight: 400;
      color: #8e8e8f;
      cursor: pointer;
    }
  }
  &_commonStocks,
  &_TrendStocks,
  &_MajorIndices,
  &_BigTech,
  &_TechStocks,
  &_MemeStocks,
  &_OtherStocks {
    margin-bottom: 0;
    // .commonStocksWrapper,
    .majorIndicesWrapper,
    .trendStocksWrapper,
    .bigTechWrapper,
    .memeStocksWrapper,
    .techStocksWrapper,
    .otherStocksWrapper {
      display: flex;
      gap: 1.3%;
      flex-wrap: wrap;
      @media only screen and (max-width: 1100px) {
        // update ui responsive , 992 => mobile 600
        // @media only screen and (max-width: 600px) {
        gap: 2%;
      }
      .stockItem {
        width: 24%;
        margin-bottom: 0;
        margin-bottom: 18px;
        .stockItem_percent_hoz {
          .hoz-red,
          .hoz-green {
            height: 16px;
          }
        }
      }
    }

    .commonStocksWrapper {
      width: calc(100% + 20px);
      @media only screen and (max-width: 1100px) {
        // update ui responsive , 992 => mobile 600
        // @media only screen and (max-width: 600px) {
        width: calc(100% + 8px);
      }
      .stockItemWrapper {
        padding-right: 20px;
        .stockItem {
          margin-bottom: 0;
          margin-bottom: 20px;
          .stockItem_percent_hoz {
            .hoz-red,
            .hoz-green {
              height: 14px;
            }
          }
        }
      }
      &-vote {
        width: calc(100% + 8px);
        margin-bottom: 20px;
        @media only screen and (max-width: 1100px) {
          // update ui responsive , 992 => mobile 600
          // @media only screen and (max-width: 600px) {
          width: calc(100% + 40px);
        }
      }
      .voteStockItemWrapper {
        padding-right: 13px;
      }

      &-recentTradesList {
        width: calc(100% + 110px);
        // margin-bottom: 20px;
        // @media only screen and (max-width: 600px) {
        //   width: calc(100% + 40px);
        // }
      }
      .recentTradesItemWrapper {
        padding-right: 14px;
        .rightRecentTradesItem {
          width: auto;
          min-width: auto;
          margin-bottom: 20px;
          border: 1px solid #424448;
          img.avatar {
            opacity: 1;
          }
          p.text {
            opacity: 1;
          }
          &:hover {
            border: 1px solid var(--purple);
          }
        }
      }
    }
  }

  &_commonStocks {
    @media only screen and (max-width: 1100px) {
      // update ui responsive , 992 => mobile 600
      // @media only screen and (max-width: 600px) {
      overflow: hidden;
      &-hideMobile {
        display: none;
      }
    }
    @media only screen and (min-width: 601px) {
      &-hideDesktop {
        display: none;
      }
    }
    &-stocksList {
      overflow: hidden;
    }
    &-bigMovers {
      overflow: hidden;
      .commonStocksWrapper {
        width: calc(100% + 150px);
        .bigMoversItemWrapper {
          padding-right: 20px;
        }
      }
    }
    &-recentTrades {
      overflow: hidden;
    }
  }
  &_TrendStocks {
    margin-bottom: calc(30px - 18px);
  }

  &_TopInvestors {
    // padding-left: 14px;
    display: none;
    margin-bottom: calc(20px);
    position: relative;
    overflow: hidden;
    .TopInvestorsWrapper {
      width: calc(100% + 70px);
      .investorItemWrapper {
        padding: 5px;
      }
      .investorItemWrapper:nth-child(1) {
        padding-left: 2px;
      }
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   z-index: 500;
    //   width: 16px;
    //   height: 100%;
    //   background: var(--bg);
    // }
  }
  &_spotlight {
    width: 70%;
    padding-right: 50px;
    &_title {
      padding-bottom: 23px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &_chart {
      height: 370px;
      background: var(--box-bland-bg);
    }
    &_Inventors {
      min-width: 195px;
    }
  }

  &_claimMoney {
    // padding: 20px 0;
    margin: 20px 0;
    border-radius: 8px;
    background-image: url("/mesh-gradient.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    background-color: var(--purple);
    cursor: pointer;
    height: 51px;
    display: flex;
    p {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      transform: translateY(-1px);
      display: inline-block;
      margin: auto;
      span {
        img {
          margin: 0 8px;
          width: 20px;
          // filter: invert(1);
          transform: translateY(3px);
        }
      }
    }
    @media only screen and (max-width: 1100px) {
      height: 40px;
      margin-top: 0px;
      margin-bottom: 24px;
      p {
        font-size: 14px;
        transform: translateY(1px);
      }
    }
  }

  // new-ui. like spotlight

  &_top {
    // padding: 20px 0 18px;
    display: flex;
    flex-wrap: wrap;
    &_earlyAccess {
      overflow: hidden;
      margin-top: 9px; // 26 + 9 = 35
      width: 100%;
      height: 393px;
      // background: var(--purple);
      background-image: url("/mesh-gradient.png");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      background-color: var(--purple);
      border-radius: 8px;
      margin-bottom: 25px; // 25 + 10 = 35
      padding: 44px 36px 40px;
      padding-right: 45%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;

      display: none;

      p.title {
        font-size: 64px;
        font-weight: 700;
        max-width: 500px;
        margin-bottom: 20px;
      }
      span.lineHoz {
        display: block;
        width: 69px;
        height: 5px;
        border-radius: 4px;
        background: var(--white);
        margin-left: 4px;
        margin-bottom: 28px;
      }
      p.text {
        max-width: 430px;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 28px;
        line-height: 1.3;
        padding-left: 3px;
      }

      .inputWrapper {
        display: flex;
        width: 359px;
        height: 34px;
        background: var(--white);
        border-radius: 4px;
        padding: 3px;
        padding-left: 10px;
        input {
          border: transparent;
          border-radius: 4px;
          width: 180px;
          background-color: transparent;
          outline: none;
          color: #23272a;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          width: calc(100% - 130px);
          position: relative;
          z-index: 1;
          &::placeholder {
            font-size: 14px;
            color: #7b7d7f;
          }
        }
        .btnGetEarlyAccess {
          margin-left: auto;
          background: var(--purple);
          border-radius: 4px;
          padding-left: 15px;
          padding-right: 8px;
          position: relative;
          z-index: 1;
          p {
            font-size: 12px;
            font-weight: 700;
            color: var(--white);
          }
        }
      }
      .bgChild {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 273px;
        height: 265px;
        pointer-events: none;
      }
      .imgLeft {
        position: absolute;
        top: 0px;
        // right: 50px;
        // width: 34, 6%;
        // height: auto;
        // // width: 40%;
        // height: 93%;
        top: -16px;
        right: -110px;
        height: 111%;
        pointer-events: none;
      }
    }
    &_left {
      width: calc(100% - 282px);
      padding-right: 50px;

      height: fit-content;

      &-desktop {
        @media only screen and (min-width: 923px) {
          // display: none;
        }
      }
      &-mobile {
        @media only screen and (max-width: 923px) {
          // transform: translateY(-18px);
        }
      }

      // HeadLeft custom
      .top-header_left {
        width: 100%;
        padding-right: 0;
        padding: 26px 0;
      }

      .homeChart {
        padding: 16px 0;
        padding-top: 10px;
        padding-bottom: 20px;
        .title {
          margin-bottom: 23px;
          p {
            color: var(--normal-text-black-color);

            font-size: var(--font-size-l);
            font-style: normal;
            font-weight: 700;
          }
        }
      }

      .stockHeader {
        @media only screen and (min-width: 1100px) {
          display: none;
        }
        display: flex;
        margin-bottom: 12px;
        align-items: center;
        height: 33px;

        &_name {
          width: 70%;
          display: flex;
          &_logo {
            margin-right: 10px;
            img {
              width: 33px;
              border-radius: 50%;
            }
          }
          &_info {
            padding-top: 4px;
            p {
              color: var(--normal-text-black-color);
              font-style: normal;
              font-weight: 700;
            }
            .title {
              font-size: 14px;
              margin-bottom: 2px;
            }
            .text {
              font-size: 12px;
              color: var(--gray-blur-text);
              font-weight: 400;
            }
          }
        }
        &_price {
          width: 30%;
          padding-top: 4px;
          p {
            font-size: 14px;
            font-style: normal;
            text-align: right;
            font-weight: 400;
            // margin-bottom: 2px;
            height: 14px;
            margin-bottom: 4px;
          }
          p.volatility {
            font-size: 12px;
            height: 12px;
          }
        }
      }

      .chartStock {
        // min-height: 370px;
        border-radius: 8px;
        // background: var(--box-bland-bg);
        position: relative;

        // border-bottom: 1px solid var(--border-box);
        &_header {
          padding: 32px 32px 0px;
          // height: calc(44px + 20px);
          display: flex;
          background: var(--box-bland-bg);
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          border-top: 1px solid var(--border-box);

          &_logo {
            position: relative;
            height: 44px;
            img {
              width: 44px;
              border-radius: 50%;
              margin-right: 16px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 100;
            }
          }
          &_info {
            .price {
              font-size: 14px;
              margin-right: 10px;
            }
            .priceMobile {
              display: none;
            }
            .vol {
              font-size: 14px;
              color: var(--gray-text-3);
            }
            .question {
              margin-top: 6px;
              font-size: 24px;
              color: var(--normal-text-black-color);
              font-weight: bold;
            }
          }
        }
        &_question {
          padding: 25px 20px;
          border-bottom: thin solid var(--border-comment);
          position: relative;
          display: flex;
          align-items: center;
          img {
            margin-bottom: auto;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-left: 1px;
            margin-right: 11px;
          }
          p {
            padding-top: 3px;
            font-size: 24px;
            color: var(--normal-text-black-color);
            font-weight: bold;
          }
          &-inHome {
            padding: 0px 20px;
            border: none;
          }
          &_price {
            // width: 30%;
            position: absolute;
            top: 0;
            right: 0;
            p {
              font-size: 15px;
              font-style: normal;
              text-align: right;
              font-weight: 400;
              // margin-bottom: 2px;
              height: 14px;
              margin-bottom: 3px;
            }
            p.volatility {
              font-size: 12px;
              height: 12px;
              margin-bottom: 0px;
              &.red-text {
                color: var(--red);
              }
              &.green-text {
                color: var(--green);
              }
            }
          }
        }
        &_text {
          padding: 16px 20px;
          background: var(--box-bland-bg);
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          p {
            font-size: 14px;
            color: var(--gray-text-3);
          }
          &-inHome {
            padding-top: 30px;
            padding-bottom: 14px;
          }
        }
        &_options {
          // background: var(--box-bland-bg);
          // border-top-left-radius: 8px;
          // border-top-right-radius: 8px;
          padding: 0px 29px;
          display: flex;
          position: relative;
          border-bottom: 1px solid var(--border-comment);
          // border-top: 1px solid var(--border-box);
          // border-left: 1px solid var(--border-box);
          // border-right: 1px solid var(--border-box);
          .btnOptChart {
            // background: red;
            width: fit-content;
            padding: 20px 34px 20px 0px;
            p {
              font-size: 15px;
              font-weight: 500;
              color: var(--gray);
            }
            &.active {
              p {
                color: var(--normal-text-black-color);
              }
            }
          }
          &::before {
            content: "";
            position: absolute;
            width: 118.3px;
            height: 2px;
            background: var(--white);
            left: 29px;
            bottom: 0px;
            transition: 0.3s;
          }

          .changeThemeWrapper {
            position: absolute;
            top: 6px;
            right: 0;
            .btnTheme {
              transform: translateY(-2px);
              transition: 0.5s;
            }
            p {
              width: fit-content;
              margin-right: 10px;
              font-size: 15px;
              color: var(--gray-blur-text);
              // color: #808284; // var(--gray-blur-text);
              opacity: 1;
            }
            &-light {
              .btnTheme {
                background: #424448;
                &::before {
                  background: #7f72da; //var(--purple);
                }
              }
              p {
                opacity: 0.6;
              }
            }

            @media screen and (max-width: 1100px) {
              top: 0;
              p {
                display: none;
              }
            }
          }

          //case 1
          &-stockChart {
            &::before {
              width: 80.95px;
              left: calc(29px + 118.3px + 34px);
            }
          }
          &-orderBook-1 {
            &::before {
              width: 78.17px;
              left: calc(29px + 118.3px + 34px + 80.95px + 34px);
            }
          }
          // case 2 - metrics page
          &-historicalData {
            &::before {
              width: 100.23px;
              left: calc(29px + 118.3px + 34px);
            }
          }
          &-orderBook-2 {
            &::before {
              width: 78.17px;
              left: calc(29px + 118.3px + 34px + 100.23px + 34px);
            }
          }
        }
        .tradingviewWidgetContainer {
          padding: 19px 29px;
          height: 360px;
          height: 505px;
          border-left: 1px solid var(--border-box);
          border-right: 1px solid var(--border-box);
          @media only screen and (max-width: 1100px) {
            padding: 15px;
            // height: 320px;
            // height: 780px;
          }
          // background: var(--box-bland-bg);
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          .group-wWM3zP_M- {
            background: green !important;
          }
        }

        .historicalDataChart {
          padding: 0px 0px;
          height: 360px;
          height: 505px;
          border-left: 1px solid var(--border-box);
          border-right: 1px solid var(--border-box);
          background: var(--box-bland-bg);
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          // custom

          .recharts-layer {
            .valueBar,
            .labelTextBar {
              font-size: 14px;
              font-family: var(--font-family-overpass);
              color: var(--white);
              fill: var(--normal-text-black-color);
            }
          }

          .recharts-cartesian-axis-ticks {
            .recharts-cartesian-axis-line {
              stroke: var(--border-comment);
            }
            .recharts-cartesian-axis-tick-line {
              // display: none;
            }
            .recharts-text.recharts-cartesian-axis-tick-value {
              font-size: 10px;
              font-family: var(--font-family-overpass);
              color: var(--white);
              fill: #a5a6a8;
            }
          }

          @media only screen and (max-width: 1100px) {
            padding: 0px 0;
            height: 320px;
            // custom
            .recharts-layer {
              .valueBar,
              .labelTextBar {
                font-size: 9px;
              }
            }
            .recharts-cartesian-axis-ticks {
              .recharts-cartesian-axis-line {
              }
              .recharts-cartesian-axis-tick-line {
              }
              .recharts-text.recharts-cartesian-axis-tick-value {
                font-size: 9px;
              }
            }
          }
        }

        .limitOrderData {
          background: var(--box-bland-bg);
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          height: 505px;
          overflow: hidden;

          &_header {
            // background-color: red;
            border-bottom: 1px solid var(--border-comment);

            .limitOrderItem {
              display: flex;
              width: 100%;
              padding: 16px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 50%;
                width: 1px;
                height: 100%;
                background: var(--border-comment);
                z-index: 100;
              }

              &_green,
              &_red {
                width: 50%;
                display: flex;
                .price {
                  width: 50%;
                  font-size: 14px;
                  color: var(--green);
                  text-align: right;
                  margin-right: 6px;
                  @media only screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
                .type {
                  width: 50%;
                  font-size: 14px;
                  color: var(--gray-blur);
                  text-align: left;
                  margin-left: 6px;
                  @media only screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
              }
              &_red {
                .price {
                  color: var(--red);
                }
              }
            }
          }
          &_content {
            height: calc(505px - 46px);
            overflow: hidden;
            overflow-y: scroll;
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none; /* Safari and Chrome */
            }
            .btnCancelOrder {
              top: 0;
              // left: 0;
              position: absolute;
              width: 60px;
              height: 100%;
              // background: var(--green);
              // opacity: 0.2;
              z-index: 1000;

              p {
                color: #808c8c;
              }
            }

            .limitOrderItem {
              display: flex;
              width: 100%;
              padding: 16px;
              position: relative;
              border-bottom: 1px solid var(--border-comment); // #424448
              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 50%;
                width: 1px;
                height: 100%;
                background: var(--border-comment);
                z-index: 100;
              }
              .percentBar {
                position: absolute;
                top: 0;
                width: 60px;
                // height: 102%;
                height: 47px;
                opacity: 0.2;
                z-index: 10;
                transition: 0.5s;
                @media only screen and (max-width: 1100px) {
                  height: 45px;
                }

                &-desktop {
                  // display: block;
                }

                &-mobile {
                  display: none;
                }

                @media screen and (max-width: 1100px) {
                  &-desktop {
                    display: none;
                  }
                  &-mobile {
                    display: block;
                    width: 35px;
                  }
                }
              }
              &_green,
              &_red {
                width: 50%;
                display: flex;
                // position: relative;
                .price {
                  width: 50%;
                  font-size: 14px;
                  color: var(--green);
                  text-align: right;
                  margin-right: 6px;
                  @media only screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
                .type {
                  width: 50%;
                  font-size: 14px;
                  color: var(--gray-blur);
                  text-align: left;
                  margin-left: 6px;
                  @media only screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
              }
              &_green {
                .percentBar {
                  left: 0;
                  background: var(--green);
                }
                .btnCancelOrder {
                  left: 0;
                  // background: var(--green);
                  // background: #2B3F3F;
                }
              }
              &_red {
                .percentBar {
                  right: 0;
                  background: var(--red);
                }
                .price {
                  color: var(--red);
                }
                .btnCancelOrder {
                  right: 0;
                  // background: var(--red);
                  // background:  #42373B;
                }
              }
            }
          }
        }

        &_comingSoon {
          width: 100%;
          height: 505px;

          background: var(--box-bland-bg);
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: 1px solid var(--border-box);
          border-right: 1px solid var(--border-box);
          display: flex;
          p {
            margin: auto;
            font-size: 15px;
            font-weight: 500;
            color: var(--gray);
          }
        }
        .chartStockTooltip {
          // position: relative;
          // z-index: 200;
          pointer-events: none;
          .time {
            margin-bottom: 6px;
            p {
              font-size: 11px;
              color: #898b91;
              text-align: center;
            }
          }
          .price {
            width: 50px;
            border-radius: 3px;
            background: var(--box-bg);
            padding: 5px 0;
            p {
              text-align: center;
              font-size: 11px;
            }
            &_green {
              color: var(--green);
              margin-bottom: 3px;
            }
            &_red {
              color: var(--red);
            }
          }
        }
        &_prices {
          padding: 19px 29px;
          display: flex;
          height: 52px;
          background: var(--box-bland-bg);
          border-left: 1px solid var(--border-box);
          border-right: 1px solid var(--border-box);
          p.greenPrice,
          p.redPrice {
            font-size: 14px;
            font-weight: 400;
            margin-right: 13px;
          }
          p.greenPrice {
            color: var(--green);
          }
          p.redPrice {
            // color: var(--red);
            color: #fb8081;
          }
        }
        &_chartOne {
          // position: relative;
          background: var(--box-bland-bg);
          padding-left: 29px;
          padding-right: 29px;
          &-symbolPage {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-left: 1px solid var(--border-box);
            border-right: 1px solid var(--border-box);
          }
          .chartOneWrapper {
            position: relative;
          }
          &_lineHoz {
            position: absolute;
            top: 0px;
            left: -1000px;
            z-index: 50;
            // transform: translateX(-1000px);
            pointer-events: none;
            &::before {
              content: "";
              position: absolute;
              top: 0px;
              left: 50%;
              z-index: 100;
              // left: -10000px;
              width: 1px;
              height: 446px;
              background: #898b91;
              pointer-events: none;
              // background: red;
            }
          }
          &_activeDot {
            position: absolute;
            top: 0px;
            left: -10000px;
            z-index: 50;
            transform: translate(-50%, -50%);
            pointer-events: none;
            width: 8px;
            height: 8px;
            background: var(--purple);
            border-radius: 20px;

            pointer-events: none;
          }
          &_times {
            display: flex;
            // margin-top: 3px;
            height: 20px;
            border-top: thin solid var(--border-comment); // #898b91;
            border-bottom: thin solid var(--border-comment); // #898b91;
            padding: 6px 6px;

            // set chart
            pointer-events: none;
            position: absolute;
            bottom: calc(100px + 84px);
            left: 0;
            width: 100%;
            // end set chart
            p {
              font-size: 10px;
              color: var(--gray);
              transform: translateX(-50%);
              position: absolute;
              top: 6px;
              left: 0;
              white-space: nowrap;
            }
            p.first {
              margin-right: auto;
            }
            p.last {
              margin-left: auto;
            }
          }
          //custom
          .recharts-responsive-container {
            circle {
            }
          }
          .recharts-tooltip-wrapper {
            z-index: 300;
          }
          // end custom
        }
        &_chartButton {
          display: flex;
          pointer-events: none;
          background: var(--box-bland-bg);
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          button {
            height: 35px;
            border-radius: 4px;
            font-size: 14px;
            cursor: default;
            font-weight: 500;
          }
          .btn-green {
            // background-color: rgba($color: var(--green), $alpha: 1.0);
            background: rgba(36, 207, 164, 0.7);
            border: transparent;
            // background: -moz-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #24cea4 0%,
            //   #126752 100%
            // );
            // background: -o-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #24cea4 0%,
            //   #126752 100%
            // );
            // background: -ms-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #24cea4 0%,
            //   #126752 100%
            // );
            // background: -webkit-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #24cea4 0%,
            //   #126752 100%
            // );
            margin: 0 8px 28px 26px;
            transition: 0.5s;
            min-width: 70px;
            color: var(--white);
          }
          .btn-red {
            border: transparent;
            background: rgba(255, 130, 130, 0.7);
            // background: -moz-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #7e4041 0%,
            //   #fb8081 100%
            // );
            // background: -o-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #7e4041 0%,
            //   #fb8081 100%
            // );
            // background: -ms-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #7e4041 0%,
            //   #fb8081 100%
            // );
            // background: -webkit-radial-gradient(
            //   top right,
            //   circle farthest-corner,
            //   #7e4041 0%,
            //   #fb8081 100%
            // );
            margin: 0 26px 28px 8px;
            transition: 0.5s;
            min-width: 70px;
            color: var(--white);
          }
        }

        &_chartTwo,
        &_chartThree {
          height: 100px; // chart one
          padding: 16px 29px; // check symbol,tsx Chart config
          // change to pretty ui
          padding-left: 22px;
          padding-right: 22px;
          .title {
            margin-bottom: 6px;
            padding-left: calc(29px - 22px);
            // padding: 16px 15px;
            p {
              font-size: 14px;
              color: var(--gray-blur-text);
              @media only screen and (max-width: 1100px) {
                font-size: 12px;
              }
            }
          }
          .chartWrapper {
            display: flex;
            height: 50px;
            max-height: 50px !important;
            .item {
              width: calc(100% / 48);
              height: 1%;
              max-height: 50px !important;
              // background: red;
              margin-top: auto;
              // height: 1px;
              background: var(--gray-chart);
              margin-right: 2px;
              transition: 0.3s;
              position: relative;

              // &::before {
              //   position: absolute;
              //   content: "";
              //   bottom: 0;
              //   left: 0;
              //   width: 80%;
              //   height: 100%;
              //   background: #7b7490;
              // }
              &.active {
                background: var(--purple);
                transition: 0.7s;
              }
            }
            .item:nth-child(48) {
              margin-right: 0px;
            }
          }
        }
        &_chartTwo {
          height: 100px; // chart one
          // set chart
          pointer-events: none;
          position: absolute;
          bottom: calc(84px + 4px);
          left: 0;
          width: 100%;
          z-index: 200;
          // end set chart
        }
        &_chartThree {
          padding-bottom: 0px;
          height: 84px; // chart two
          // set chart
          pointer-events: none;
          position: absolute;
          bottom: calc(10px);
          left: 0;
          width: 100%;
          z-index: 200;
          // end set chart
        }

        &-spotlight {
          border: 1px solid var(--border-box);
          &:hover {
            // background: var(--green-hover);
            // Remove green hover Spotlight , feedback 22/01/2022
            border: 1px solid var(--green-hover-border);
          }

          .chartStock_chartOne_lineHoz {
            &::before {
              height: 250px;
            }
          }
        }
      }
      .yourPositions {
        margin-top: 30px; // fix bug ui desktop
        padding: 27px 27px;
        border-radius: 8px;
        background: var(--box-bland-bg);
        border: 1px solid var(--border-box);
        display: flex;
        flex-wrap: wrap;

        p.title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 30px;
        }
        .btnShare {
          margin-left: auto;
          width: 113px;
          height: 30px;
          background: var(--bg);
          border-radius: 4px;
          transform: translateY(-5px);
          &:hover {
            border: 1px solid #03a9f4;
          }
          p {
            font-size: 14px;
            font-weight: 700;
            color: #a7a8a9;
            margin-right: 5px;
            white-space: nowrap;
          }
          svg {
            width: 18px;
          }
        }
        table {
          width: 100%;
          // margin-bottom: 16px;
          tr.head {
            height: 25px;
            border-bottom: 1px solid var(--border-comment);
            th {
              text-align: left;
              // color: var(--gray);
              color: var(--gray-blur-text);
              font-size: 14px;
            }
            th:nth-child(4),
            th:nth-child(5),
            th:nth-child(6) {
              // text-align: right;
            }
            th.shares {
              width: 20%;
            }
          }
          tr.body {
            // height: 25px;
            td {
              text-align: left;
              color: var(--normal-text-black-color);
              font-size: 15px;
              padding-top: 25px;
            }
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6) {
              // text-align: right;
            }
            td.green {
              color: var(--green);
            }
            td.red {
              color: var(--red);
            }
            td.maxReturn {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 1px;
                height: 15px;
                // background: #f7f7f81c;
                background: transparent;
              }
            }
          }
        }
      }

      .moreStocks {
        margin-top: 30px;
        padding: 27px 27px;
        padding-bottom: 13px; // 14 + 13 = 27
        border-radius: 8px;
        background: var(--box-bland-bg);
        padding-right: 0;
        position: relative;
        border: 1px solid var(--border-box);
        p.title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 30px;
        }
        &_buttons {
          position: absolute;
          top: 20px;
          right: 27px;
          display: flex;
          .btnPrev {
            margin-right: 8px;
            img {
              transform: rotateZ(180deg);
            }
          }
          .btnNext,
          .btnPrev {
            border-radius: 50%;
            background: #424448;
            width: 30px;
            height: 30px;
            img {
              width: 25px;
              opacity: 0.5;
            }
            &.disable {
              opacity: 0.6;
              pointer-events: none;
            }
          }
        }
        &_listStocks {
          // display: flex;
          // margin-bottom: calc(20px);
          position: relative;
          overflow: hidden;
          .listStocksWrapper {
            width: calc(100% + 55px);
            // width: calc(216px * 3 + 50px);
          }
          .stockItemWrapper {
            padding-right: 16px;
            .stockItem {
              // background: red;
              padding: 18px 17px 10px 16px;
              border: 1px solid var(--border);
              border-radius: 5px;
              &:hover {
                border: 1px solid var(--green-hover-border);
              }
              &-red {
                &:hover {
                  border: 1px solid var(--red-hover-border);
                }
              }
              &-green {
                &:hover {
                  border: 1px solid var(--green-hover-border);
                }
              }
              &_header {
                // margin-bottom: 17px;
                &_name {
                  p {
                    // font-size: 33px;
                  }
                  .title {
                    margin-bottom: 2px;
                    font-size: 15px;
                    @media only screen and (max-width: 1100px) {
                      // update ui responsive , 992 => mobile 600
                      // @media only screen and (max-width: 600px) {
                      font-size: 12px;
                    }
                  }
                }
                &_price {
                  p {
                    // font-size: 12px;
                  }
                }
              }
              &_percent {
                &_number {
                  p {
                    // font-size: 14px;
                  }
                }
              }
              &_footer {
                // margin-top: 16px;
              }
            }
          }
        }
      }

      .rulesSummary {
        margin-top: 30px;
        padding: 29px 30px;
        border-radius: 8px;
        background: var(--box-bland-bg);
        border: 1px solid var(--border-box);
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        p.title {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 14px;
          width: 100%;
        }
        p.text {
          font-size: 14px;
          line-height: 1.3;
        }
        &_item {
          display: flex;
          padding: 20px 0;
          padding-bottom: 18px;
          position: relative;
          width: 100%;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -30px;
            width: calc(100% + 30px + 30px);
            height: 1px;
            background: var(--border-comment);
          }
          .leftIcon {
            width: 30px;
            padding-right: 10px;
            img {
              width: 20px;
            }
          }
          .rightInfo {
            padding-top: 3px;
            p.title {
              padding-top: 2px;
              font-size: 15px;
              font-weight: 700;
              margin-bottom: 8px;
            }
            p.text {
              font-size: 14px;
              font-size: 400;
              color: var(--gray);
              margin-top: auto;
            }
            // fix .text sometime not implement. Not know yet ?
            p:nth-child(2) {
              font-size: 14px;
              font-size: 400;
              color: var(--gray);
              margin-top: auto;
            }
          }
          &:nth-child(2) {
            &::before {
              display: none;
            }
          }
          &:nth-child(3) {
            &::before {
              display: none;
            }
          }
          &:nth-child(4) {
            .leftIcon img {
              transform: scale(0.8);
            }
            .rightInfo {
              p.text {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          &:nth-child(5) {
            .leftIcon img {
              transform: scale(0.75);
            }
          }
          &:nth-child(6) {
            .leftIcon img {
              transform: scale(1);
            }
            .rightInfo {
              .text {
                margin-bottom: 0px;
                line-height: 1.5;
              }
            }
          }
          &-time {
            width: 50%;
          }
        }
      }

      .inTheNews {
        margin-top: 30px;
        padding: 27px 27px;
        padding-bottom: 11px; // 11 + 16 = 27
        border-radius: 8px;
        background: var(--box-bland-bg);
        border: 1px solid var(--border-box);
        p.title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .stockNew {
          border: 1px solid var(--border-comment);

          // border-radius: 5px;
          display: flex;
          margin-bottom: 16px;
          &:hover {
            background: #363a3c;
          }
          &_texts {
            width: 70%;
            padding: 16px;
            .title {
              font-size: 15px;
              margin-bottom: 14px;
              line-height: 1.5;
            }
            .text {
              font-size: 12px;
              font-weight: 400;
              // color: var(--normal-text-black-color);
              color: var(--gray);
              // opacity: 0.6;
              line-height: 1.5;
              margin-bottom: 14px;
            }
            .sourceName {
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 14px;
              margin-right: 10px;
            }
            .date {
              font-size: 12px;
              font-weight: 400;
              color: var(--gray-blur);
            }
          }
          &_image {
            width: 30%;
            padding: 16px;
            img {
              width: 100%;
            }
            .img {
              display: none;
              background-color: #cccccc; /* Used if the image is unavailable */
              height: 200px; /* You must set a specified height */
              background-position: center; /* Center the image */
              background-repeat: no-repeat; /* Do not repeat the image */
              background-size: cover; /* Resize the background image to cover the entire container */
            }
          }
        }
      }
    }
    &_right {
      // width: 30%;
      width: calc(282px);
      &-sticky {
        position: sticky;
        top: 0px;
      }
      // &-desktop {
      //   @media only screen and (max-width: 1100px) {
      //     // display: none;
      //   }
      // }
      // &-mobile {
      //   @media only screen and (min-width: 923px) {
      //     // display: none;
      //   }
      // }
      // custom HeadRight
      .top-header_right {
        width: 100%;
        padding: 26px 0;
      }
      .homeTopInvestors {
        // border: thin solid red;
        padding: 16px 0;
        padding-top: 10px;
        padding-bottom: 0;
        &_head {
          margin-bottom: 23px;
          display: flex;
          p.title {
            display: block;
            color: var(--normal-text-black-color);
            font-size: 18px;
            font-style: normal;
            font-weight: bold;
            margin-right: auto;
          }
          .btnWin,
          .btnLoss {
            p {
              color: var(--gray);
            }
          }
          .btnWin.active {
            p {
              color: var(--green);
            }
          }
          .btnLoss.active {
            p {
              color: var(--red);
            }
          }
        }
        &_lists {
          //custom
          .userWinLossItem {
            width: 100%;
            p {
              font-size: 16px;
            }
            p.name {
              font-weight: 400;
              opacity: 0.6;
            }
          }
        }
      }

      //new ui
      .buySellBox {
        background: var(--box-bland-bg);
        padding: 21px 27px;
        padding-bottom: 0;
        border-radius: 8px;
        margin-bottom: 25px;
        border: 1px solid var(--border-box);
        &_options {
          display: flex;
          position: relative;
          padding-top: 0px;
          padding-bottom: 12px;
          margin-bottom: 18px;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: var(--gray);
          }
          &::after {
            transition: 0.3s;
          }

          .btnOtpBuySell {
            width: 50%;
            p {
              color: var(--gray);
              font-weight: 500;
              font-size: 16px;
            }
          }

          &-BUY {
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 50%;
              height: 1px;
              background: var(--green);
            }
            .btnOtpBuySell:nth-child(1) {
              p {
                color: var(--green);
              }
            }
          }
          &-SELL {
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 50%;
              height: 1px;
              background: var(--red);
            }
            .btnOtpBuySell:nth-child(2) {
              p {
                color: var(--red);
              }
            }
          }
        }
        &_greenRedOpt {
          margin-bottom: 20px;
          p.title {
            color: var(--normal-text-black-color);
            font-size: 16px;
            margin-bottom: 14px;
          }
          .btnOtpGreen,
          .btnOtpRed {
            background: var(--btn-buy-sell-bg);
            margin-bottom: 10px;
            padding: 8px 10px;
            width: 100%;
            border-radius: 4px;
            height: 39px;
            border: 1px solid var(--border-box);
            p {
              color: var(--normal-text-black-color);
              font-size: 15px;
            }
            p.type {
              margin-right: auto;
            }
            p.price {
            }
          }
          .btnOtpGreen.active {
            background: var(--green);
            p {
              color: var(--white);
            }
          }
          .btnOtpRed.active {
            background: var(--red);
          }
        }
        &_input {
          margin-bottom: 32px;
          p.title {
            color: var(--normal-text-black-color);
            font-size: 14px;
            margin-bottom: 12px;
          }
          .inputWrapper {
            border-radius: 4px;
            border: 1px solid #6c6e71; //rgb(255, 255, 255, 0.3);
            padding: 7px 8px;
            display: flex;
            height: 39px;
            span {
              font-size: 16px;
              color: var(--normal-text-black-color);
              padding-top: 4px;
              transform: translateY(1px);
              // color: var(--gray-blur);
            }
            span.priceType {
              margin-left: 4px;
            }
            input {
              outline: none;
              color: var(--normal-text-black-color);
              // color: var(--white);
              background: var(--box-bland-bg);
              width: calc(100% - 32px);
              font-size: 16px;
              border: transparent;
              padding-left: 0;
              min-height: 23px;
              &::placeholder {
                color: var(--normal-text-black-color);
                // color: transparent;
              }
            }
          }

          &-limitOrder {
            transform: translateY(-10px);
            margin-bottom: 22px;
          }
        }

        &_info {
          margin-bottom: 24px;
          &_item {
            display: flex;
            width: 100%;
            // background: rgba(255, 0, 0, 0.437);
            margin-bottom: 6px;
            p {
              font-size: 14px;
            }
            p.title {
              margin-right: auto;
            }
            p.value {
            }
          }
        }

        .btnBuySell {
          width: 100%;
          border-radius: 4px;
          background: var(--purple);
          padding: 8px 16px;
          height: 45px;
          margin-bottom: 27px;
          p {
            color: var(--white);
            font-size: 16px;
          }
        }
        &_funds {
          width: 100%;
          display: flex;
          // align-items: center;
          height: 50px;
          position: relative;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -27px;
            width: calc(100% + 27px + 27px);
            height: 1px;
            background: var(--border-comment);
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: var(--gray);
            padding-top: 20px;
          }
          .btnFunds {
            padding: 0;
            margin-left: auto;
            // width: 50px;
            height: 100%;
            // background: red;
            // transform: translateX(17px);
          }
        }
      }
      .btnAddLiquidit,
      .btnSwitchOrder {
        width: 100%;
        border-radius: 3px;
        background: var(--box-bland-bg);
        padding: 8px 16px;
        height: 45px;
        border: 1px solid var(--border-box);
        img {
          width: 20px;
          margin-right: 16px;
        }
        p {
          color: var(--normal-text-black-color);
          font-size: 16px;
        }
        // &:hover {
        //   background: #33373c;
        // }
      }
      .btnSwitchOrder {
        img {
          width: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .home_top_left .chartStock_text-inHome,
  .userPage_top_left .chartStock_text-inHome {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
  .home_top_left .chartStock_question-inHome,
  .userPage_top_left .chartStock_question-inHome {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home_top_left .chartStock_chartButton .btn-green,
  .home_top_left .chartStock_chartButton .btn-red {
    height: 24px;
    margin-bottom: 0;
  }
  .home_top_left .chartStock_chartButton .btn-green {
    margin-left: 15px;
    margin-right: 5px;
  }
  .home_top_left .chartStock_chartButton .btn-red {
    margin-right: 15px;
    margin-left: 5px;
  }

  .home_top_left .chartStock_chartButton,
  .userPage_top_left .chartStock_chartButton {
    padding-bottom: 17px;
  }

  .topMoves_options .optionsWrapper p {
    font-size: 12px !important;
  }

  .home_top_left .chartStock_question,
  .userPage_top_left .chartStock_question {
    padding: 15px 20px;
  }

  .home_top_left .chartStock_question img,
  .userPage_top_left .chartStock_question img {
    display: none;
  }
  .home_top_left .chartStock_text,
  .userPage_top_left .chartStock_text {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .home_top_left .chartStock_question,
  .userPage_top_left .chartStock_question {
    // border-bottom: thin solid var(--box-bland-bg);
  }
  .chartStock_question-symbol {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--box-bland-bg);
  }

  .home_top_left .stockHeader_name,
  .userPage_top_left .stockHeader_name {
    padding-left: 6px;
  }
  .home_top_left .stockHeader_price,
  .userPage_top_left .stockHeader_price {
    padding-right: 6px;
  }

  .home {
    // padding-top: calc(61px + 61px + 9px);
    padding-top: 0px;
    padding-bottom: 2px; // 20 = 2 + 18 of item
    &_options {
      position: fixed;
      top: 57px;
    }
    &_comingSoon {
      // margin-top: 16px;
    }
    &_top {
      display: flex;
      flex-wrap: wrap;
      &_left {
        margin-top: 0px;
        width: 100%;
        padding-right: 0px;
        .homeChart {
          padding-top: 0;
          padding-bottom: 0;
        }
        .chartStock {
          margin-bottom: 24px;
          &-head {
            display: none;
          }
        }
        .top-header_left {
          display: none;
        }

        .moreStocks {
          &_buttons {
            display: none;
          }
        }
      }
      &_right {
        width: 100%;
        height: fit-content;
        .homeTopInvestors {
          display: none;
        }
        .top-header_right {
          display: none;
        }
        &-sticky {
          position: static;
        }
        &-investors {
          display: none;
        }
      }
    }
    &_title {
      // display: flex;
      padding-top: 0;
      padding-bottom: 8px;
      .topMoves_options {
        margin-left: auto;
      }
      &-topInvestors {
        display: flex;
        padding-top: 0;
        padding-bottom: 3px; // 8 = 3 + 5 of item
        p {
          // font-size: 16px;
        }
      }
    }
    &_TopInvestors {
      display: block;
    }

    .hideDesktop {
      display: nome;
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .home {
    &_TrendStocks,
    &_MajorIndices,
    &_BigTech,
    &_TechStocks,
    &_MemeStocks,
    &_OtherStocks {
      .trendStocksWrapper,
      .majorIndicesWrapper,
      .bigTechWrapper,
      .memeStocksWrapper,
      .techStocksWrapper,
      .otherStocksWrapper {
        .stockItem {
          width: 48%;
        }
        &-2stock {
          .stockItem {
            width: 48%;
          }
          .stockItem:nth-child(3) {
            display: none;
          }
        }
      }
    }
    &_MajorIndices {
      .majorIndicesWrapper {
        .stockItem {
          width: 48%;
        }
      }
    }
    &_TopInvestors {
      .TopInvestorsWrapper {
        // width: calc(200%);
        .investorItemWrapper {
        }
        .investorItemWrapper:nth-child(1) {
        }
      }
    }
    &_comingSoon {
      padding: 0 0;
      height: 65vh;
      display: flex;
      margin-top: 0px;
      p {
        margin: auto;
        text-align: center;
      }
    }

    &_commonStocks {
      &-bigMovers {
        overflow: visible;
        overflow: hidden;
        margin-bottom: 8px;
        .commonStocksWrapper {
          .bigMoversItemWrapper {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

//custom user page
.userPage {
  &_options {
    padding-top: 20px;
    top: calc(61px + 70px);
  }
  &_comingSoon {
    padding-top: 185px;
  }
}

@media only screen and (max-width: 1100px) {
  .userPage {
    padding-top: 196px;
    &_options {
      position: fixed;
      padding-top: 20px;
      top: calc(61px + 65px);
    }
  }
  .home {
    &_title_buttons {
      display: none;
    }
    &_top {
      // margin-top: 20px;
      &_left {
        .homeChart {
          .title {
            margin-bottom: 8px;
            margin-top: 0px;
            p {
              font-size: 16px;
            }
          }
        }
        .chartStock {
          &_header {
            padding: 15px 15px 0;
            &_logo {
              display: none;
            }
            &_info {
              .price {
                display: none;
              }
              .priceMobile {
                display: inline-block;
                margin-right: 8px;
                font-size: 12px;
                svg {
                  margin-right: 3px;
                  transform: translateY(1px);
                }
                &.green-text {
                  svg {
                    transform: translateY(1px) rotate(180deg);
                    path {
                      fill: var(--green);
                    }
                  }
                }
              }
              .vol {
                font-size: 12px;
              }
              .question {
                font-size: 16px;
              }
            }
          }
          &_text {
            &-inHome {
              p,
              span {
                font-size: 12px;
              }
            }
          }
          &_question {
            p {
              font-size: 15px;
            }
          }
          &_chartButton {
            button {
              font-size: 10px;
            }
          }
        }
      }
    }
    &_title {
      p {
        font-size: 16px;
      }
    }
  }

  .home_top_left {
    .chartStock_question_price {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .home {
    &_TrendStocks,
    &_MajorIndices,
    &_BigTech,
    &_TechStocks,
    &_MemeStocks,
    &_OtherStocks {
      .majorIndicesWrapper,
      .trendStocksWrapper,
      .bigTechWrapper,
      .memeStocksWrapper,
      .techStocksWrapper,
      .otherStocksWrapper {
        .stockItem {
          width: 32%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .home-symbolPage {
    .home_comingSoon {
      margin-bottom: 10px;
    }
  }
  .home {
    .home_comingSoon {
      margin-bottom: 18px;
    }
    &_top {
      &_earlyAccess {
        width: 100%;
        height: fit-content;
        padding-left: 0;
        padding: 26px 20px;
        margin-top: 3px; // 3 + 17 = 20
        margin-bottom: 20px;
        p.title {
          display: block;
          font-size: 48px;
          width: 100%;
          margin-bottom: 17px;
          max-width: 100%;
        }
        span.lineHoz {
          width: 54px;
          height: 4px;
          margin-bottom: 23px;
        }
        p.text {
          font-size: 14px;
          margin-bottom: 28px;
        }

        .inputWrapper {
          margin-bottom: 0;
          display: flex;
          height: fit-content;
          padding: 0;
          flex-wrap: wrap;
          width: 100%;
          background: transparent;
          input {
            height: 34px;
            width: 100%;
            background: var(--white);
            margin-bottom: 12px;
            padding: 0 13px;
            font-size: 16px;
            &::placeholder {
            }
          }
          .btnGetEarlyAccess {
            height: 34px;
            width: 100%;
            background: #2c2f33;
            p {
              color: var(--white);
            }
          }
        }
        .bgChild {
        }
        .imgLeft {
          display: none;
        }
      }
    }
  }
  .home_title_buttons {
    display: none;
  }
  .home {
    &_TrendStocks,
    &_MajorIndices,
    &_BigTech,
    &_TechStocks,
    &_MemeStocks,
    &_OtherStocks {
      .majorIndicesWrapper,
      .trendStocksWrapper,
      .bigTechWrapper,
      .memeStocksWrapper,
      .techStocksWrapper,
      .otherStocksWrapper {
        .stockItem {
          width: 49%;
        }
      }
    }

    &_commonStocks {
      // overflow: hidden; // fix bug horizontal page at mobile
      .commonStocksWrapper {
        .stockItemWrapper {
          padding-right: 8px;
          .stockItem {
            // margin-bottom: 0;
            // margin-bottom: 18px;
            .stockItem_percent_hoz {
              .hoz-red,
              .hoz-green {
                height: 11px;
              }
            }
          }
        }
      }
    }
    .home_top_right {
      .buySellBox {
        padding: 25px 27px;
        padding-bottom: 0;
        margin-bottom: 0px;
        margin-top: 0px;
        p {
          font-size: 15px;
        }
        .btnOtpBuySell {
          font-size: 16px;
        }
        &_info {
          p {
            font-size: 14px;
          }
        }
        &_funds {
          p {
            font-size: 14px;
          }
        }
      }
      .btnAddLiquidity {
        margin-top: 30px;
        // display: none;
      }
    }
    .home_top_left {
      .chartStock {
        &_question {
          padding: 17px 15px;
        }
        &_options {
          padding: 0px 15px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          .btnOptChart {
            padding: 13px 22px 13px 0;
            p {
              font-size: 12px;
              font-weight: 500;
            }
          }
          &::before {
            left: 15px;
            width: 94.67px;
          }
          //case 1
          &-stockChart {
            &::before {
              width: 64.77px;
              left: calc(15px + 94.67px + 22px);
            }
          }
          &-orderBook-1 {
            &::before {
              width: 62.55px;
              left: calc(15px + 94.67px + 22px + 64.77px + 22px);
            }
          }
          // case 2 - metrics page
          &-historicalData {
            &::before {
              width: 80.1px;
              left: calc(15px + 94.67px + 22px);
            }
          }
          &-orderBook-2 {
            &::before {
              width: 62.55px;
              left: calc(15px + 94.67px + 22px + 80.1px + 22px);
            }
          }
        }
        &_prices {
          padding: 15px;
          p.greenPrice,
          p.redPrice {
            font-size: 12px;
            font-weight: 400;
            margin-right: 15px;
          }
        }
        &_comingSoon {
          height: 300px;
        }
        &_chartOne {
          padding-left: 15px;
          padding-right: 15px;
        }

        &_chartTwo,
        &_chartThree {
          padding: 16px 13px;
          padding-right: 12px;
          .title {
            padding-left: calc(15px - 13px);
          }
        }
      }
      .yourPositions {
        padding: 17px;
        margin-top: 30px;
        p.title {
          font-size: 16px;
        }
        .btnShare {
          transform: translateY(-3px);
          width: 89px;
          height: 24px;
          // padding: 7px 8px;

          p {
            font-size: 12px;
          }
          svg {
            width: 12px;
          }
        }
        table {
          tr.head {
            height: 22px;
            th {
              font-size: 12px;
            }
          }

          tr.body td {
            font-size: 14px;
          }
        }
      }
      .moreStocks {
        padding: 17px;
        padding-bottom: 1px;
        margin-top: 30px;
        padding-right: 0;
        padding-left: 0;
        .title {
          margin-bottom: 20px;
          font-size: 16px;
          margin-left: 17px;
        }
        .stockItemWrapper {
          padding-right: 8px;
        }
        .stockItem {
          padding: 10px 13px;
        }
        .stockItem_header_name_info {
          .title {
            margin-left: 0px;
          }
        }
        // custom
        .slick-list {
          padding-left: 15px;
        }
      }
      .rulesSummary {
        padding: 20px 15px;
        margin-top: 30px;
        padding-bottom: 2px;
        .title {
          font-size: 16px;
        }
        &_item {
          &::before {
            width: calc(100% + 15px + 15px);
            left: -15px;
          }
          .rightInfo {
            p.title {
              font-size: 14px;
              font-weight: 600;
            }
            p.text {
            }
            p:nth-child(2) {
              font-size: 12px;
            }
          }
        }
        &_item:nth-child(2) {
          width: 100%;
        }
        &_item:nth-child(3) {
          width: 100%;
          &::before {
            display: block;
          }
        }
      }
      .inTheNews {
        padding: 17px;
        padding-bottom: 1px;
        margin-top: 30px;
        margin-bottom: 10px;
        // @media only screen and (max-width: 600px) {
        //   margin-bottom: 10px;
        // }
        p.title {
          font-size: 16px;
        }
        .stockNew {
          flex-direction: column-reverse;
          &_texts {
            width: 100%;
            .title {
              font-size: 14px;
              line-height: 1.3;
            }
            .text {
              font-size: 12px;
            }
            .sourceName {
              font-size: 12px;
            }
            .date {
              font-size: 12px;
            }
          }
          &_image {
            width: 100%;
            padding: 0 0 8px 0;
            img {
              display: none;
            }
            .img {
              display: block;
              height: 170px;
            }
          }
        }
      }
    }
  }
  .chartStock-spotlight {
    // min-height: 280px !important;
  }
}

@media only screen and (min-width: 1100px) {
  .home_top_left .chartStock {
    border-top: 1px solid var(--bg);
  }

  .home_top_left .chartStock_question-symbol {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    background: var(--bg);
    border-bottom: thin solid transparent;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: -1px;
      top: 0;
      width: 1px;
      height: 100%;
      background: var(--bg);
      z-index: 1000;
    }
    &::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 0;
      width: 1px;
      height: 100%;
      background: var(--bg);
      z-index: 1000;
    }
  }
}

// feedback scroll hoz mobile
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .home-custom {
    .page-content {
      padding-right: 0;
      padding-left: 0;
      .topHeaderWrapper,
      .home_top,
      .home_title {
        padding-right: 11px;
        padding-left: 11px;
      }
      .footerWrapper {
        width: calc(100vw - 22px);
        @media only screen and (min-width: 600px) {
          width: 100%;
          padding-bottom: 60px;
        }
        // margin-left: 0px;
      }
      .commonStocksWrapper-stockList {
        width: calc(100% + 8px);
      }
      // custom
      .slick-list {
        padding-left: 11px;
      }
    }
  }
}

// fix bug scroll hoz

.stockListMobile,
.TopInvestorsMobileWrapper,
.bigMoversMobile,
.voteStocksMobile,
.moreStocksMobile,
.recentTradesListMobile {
  margin-bottom: 8px;
  // custom
  .react-horizontal-scrolling-menu--wrapper {
    .react-horizontal-scrolling-menu--scroll-container {
      // padding-left: 11px;
      padding-right: 3px; // 8 + 3 = 11
      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
        display: none;
      }
    }
  }
}

.moreStocksMobile {
  .react-horizontal-scrolling-menu--wrapper {
    .react-horizontal-scrolling-menu--scroll-container {
      padding-left: 16px;
      padding-right: 0px;
    }
  }
}

.stockListMobile {
  .stockItem {
    margin-bottom: 20px;
    width: 46vw;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      width: calc(400px * 46 / 100);
    }

    margin-right: 10px;
  }

  //test
  // .main {
  //   padding: 0;
  //   // overflow-x: scroll;
  //   overflow-y: hidden;
  // }
  // .items {
  //   padding: 0;
  //   position: relative;
  //   width: 100%;
  //   overflow-x: scroll;
  //   overflow-y: hidden;
  //   white-space: nowrap;
  //   transition: all 0.2s;
  //   will-change: transform;
  //   user-select: none;
  //   // cursor: pointer;
  //   overflow: -moz-scrollbars-horizontal !important;
  //   -webkit-overflow-scrolling: touch;
  //   &::-webkit-scrollbar {
  //     width: 0 !important;
  //     height: 0 !important;
  //   }
  //   @media screen and (max-width: 600px) {
  //     padding-left: 11px;
  //   }
  // }
  // .item {
  //   width: 50%;
  //   padding-right: 8px;
  //   display: inline-block;
  //   @media screen and (max-width: 600px) {
  //     width: 52%;
  //   }
  //   .stockItem {
  //     margin-bottom: 20px;
  //   }
  // }
  // end test
}

.home_item {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.stockListBoxWrapper {
  width: calc(50% + 10px);
  .stockListBox {
    display: flex;
    flex-wrap: wrap;
    .stockItemWrapper {
      width: 50%;
      padding-right: 20px;
      &:nth-child(even) {
        // padding-right: 0px;
      }
    }
  }
}
.sectorBoxWrapper {
  width: calc(50% - 10px);
  .sectorBox {
    padding: 27px 23px;
    background: var(--box-bland-bg);
    height: calc(100% - 16px);
    border-radius: 8px;
    border: 1px solid var(--border-box);
    cursor: pointer;
    &:hover {
      border: 1px solid var(--green);
    }
    .title {
      width: 100%;
      font-size: 22px;
      font-weight: 600;
      // padding-bottom: 12px;
      margin-bottom: 12px;
      height: 22px;
      @media only screen and (max-width: 1100px) {
        font-size: 16px;
        height: 16px;
        margin-bottom: 9px;
      }
    }
    .text {
      width: 100%;
      font-size: 14px;
      height: 14px;
      color: var(--gray-blur-text);
      margin-bottom: 22px;
      @media only screen and (max-width: 1100px) {
        height: 12px;
        font-size: 12px;
        height: 12px;
        height: 10px;
        margin-bottom: 10px;
      }
    }
    &_items {
      // align-items: stretch;
      width: 100%;
      height: calc(100% - 22px - 12px - 12px - 22px);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      // background: red;

      .sectorBoxItem {
        width: 100%;

        display: flex;
        height: 42px;
        align-items: center;
        padding: 0 14px;

        position: relative;
        z-index: 1000;
        cursor: pointer;
        @media only screen and (max-width: 1100px) {
          height: 30px;
        }

        .name {
          font-size: 15px;
          font-weight: 600;
          display: block;
          position: relative;
          z-index: 1000;
          @media only screen and (max-width: 1100px) {
            font-size: 13px;
          }
        }
        &:hover {
          .sectorBoxItem_hoz {
            background: var(--green);
          }
        }

        &_hoz {
          background: #2b3f3f;
          border-radius: 4px;
          // background: #24cea4;
          // opacity: 10%;
          position: absolute;
          top: 0;
          left: 0;
          // z-index: 0;
          width: 0%;
          height: 100%;
          padding: 0 14px;
          display: flex;
          align-items: center;
          transition: 0.1s;
          // &:hover {
          //   background: var(--green);
          // }
          .price {
            color: var(--white);
            font-size: 15px;
            display: block;
            margin-left: auto;
            @media only screen and (max-width: 1100px) {
              font-size: 13px;
            }
          }
        }
      
      }
      @media only screen and (max-width: 1100px) {
        height: 150px;
      }
    }
    @media only screen and (max-width: 1100px) {
      padding: 20px;
      height: 100%;
    }
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 27px;
  }
}

.TopInvestorsMobileWrapper {
  .investorItem {
    width: 28vw;
    margin-right: 8px;
    margin-top: 5px;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      width: calc(400px * 25 / 100);
    }
  }
}

.bigMoversMobile {
  .bigMoversItem,
  .metricsChartItem {
    width: 67vw;
    margin-right: 10px;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      width: calc(400px * 67 / 100);
    }
  }
}

.voteStocksMobile {
  margin-bottom: calc(20px + 8px);
  .voteStockItem {
    width: 40vw;
    margin-right: 8px;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      width: calc(400px * 40 / 100);
    }
  }
  .voteStockItemCreate {
    margin-right: 8px;
  }
  .voteStockItemCreate-creating {
    width: calc(100% - 8px);
    min-width: 30vw;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      min-width: calc(400px * 30 / 100);
    }
  }
}

.moreStocksMobile {
  .stockItem {
    width: 48vw;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      width: calc(400px * 48 / 100);
    }
  }
}

.recentTradesListMobile {
  .rightRecentTradesItem {
    width: auto;
    width: fit-content;
    margin-right: 8px;
    min-width: fit-content;
    margin-bottom: 20px;
    border: 1px solid #424448;
    img.avatar {
      opacity: 1;
      width: 21px;
      height: 21px;
    }
    p.text {
      opacity: 1;
      font-size: 12px;
      font-weight: 500;
      display: inline-flex;
      span {
        margin-right: 3.5px;
      }
    }
  }
}
// end fix bug scroll hoz

// css endDot

.ring-container {
  position: relative;
  position: absolute;
  top: 0;
  transition: 0.3s;
  display: none;
}

.circle {
  width: 8px;
  height: 8px;
  background-color: var(--purple);
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.ringring {
  border: 3px solid var(--purple);
  -webkit-border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -6px;
  top: -6px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
  transition: 0.5s !important;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

// end css endDot

.tradingview-widget-container {
  // background-color: red !important;
  height: calc(100%) !important;
}

.changeThemeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  .btnTheme {
    width: 40px;
    height: 20px;
    background: var(--purple);
    border-radius: 10px;
    cursor: pointer;
    margin-right: 15px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;

    &::before {
      position: absolute;
      content: "";
      top: 2px;
      right: 2px;
      height: 16px;
      width: 16px;
      background: #fff;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
  p {
    width: 80px;
    display: block;
    font-size: 16px;
    color: var(--normal-text-black-color);
    opacity: 0.6;
  }
  &-light {
    .btnTheme {
      background: #7a7c80;
      &::before {
        right: 22px;
      }
    }
    p {
      color: #2c2f33;
      opacity: 1;
    }
  }
}
