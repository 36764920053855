.findBets {
  &_question {
    padding: 0 16px 0px 16px;
    // margin-top: 20px;
    width: 100%;
    top: 80px;
    // background: var(--mirage);
    z-index: 100;
    display: flex;
    align-items: center;
    p {
      color: var(--normal-text-black-color);
      
      font-size: var(--font-size-l);
      font-style: normal;
      font-weight: 500;
    }
  }

  &_requestStock {
    width: 100%;
    height: 65px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    p,
    span {
      display: block;
      color: #b1b2b6;
      font-size: var(--font-size-xs);
      font-style: normal;
      font-weight: 600;
    }
    span {
      margin-left: 10px;
      color: var(--green);
    }
  }

  // custom
  .betTable_header {
    max-height: 190px;
    background: var(--mirage);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: var(--mirage);
    z-index: 10;
    th {
      div {
        height: calc(190px - 32px) !important;
        display: flex;
        p {
          display: block;
          margin-top: auto;
        }
      }
    }

    th.green {
      div {
        p {
          margin-left: auto;
          padding-right: 45px;
        }
      }
    }
    th.red {
      div {
        p {
          padding-right: 45px;
        }
      }
    }
    th.red,
    th.total {
      div {
        p {
          margin-right: auto;
        }
      }
    }
  }
  .betTable {
    &_item {
      .green {
        text-align: right;
        padding-right: 45px;
      }
      .red {
        text-align: left;
      }
      .total {
        text-align: left;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .findBets {
    &_question {
      p {
        font-size: var(--font-size-m);
        line-height: 1.5;
      }
    }
    &_requestStock {
      height: 80px;
      flex-direction: column;
      p {
        margin-bottom: 8px;
      }
      span {
      }
    }

    .betTable_header {
      th.green,
      th.red,
      th.price {
        div {
          p {
            padding-right: 15px;
          }
        }
      }
    }
    .betTable {
      &_item {
        .green,
        .red,
        .price {
          padding-right: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  // custom
  .betTable {
    &_header {
      th.stock {
        width: 100px;
      }
      th.price {
        width: 170px;
      }

      th.green {
        width: 98px;
        div {
          p {
            margin-left: auto;
            padding-right: 44px;
          }
        }
      }
      th.red {
        width: 82px;
        div {
          p {
            padding-right: 48px;
            padding-left: 4px;
          }
        }
      }
      th.total {
        width: 80px;
        div {
          p {
            margin-right: auto;
          }
        }
      }
    }

    &_item {
      .green {
        text-align: right;
        padding-right: 44px;
      }
      .red {
        text-align: left;
        padding-right: 0px;
        padding-right: 48px;
        padding-left: 4px;
      }
      .total {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 492px) {
  .findBets {
    &_question {
      p {
        font-size: var(--font-size-m);
      }
    }

    .betTable {
      &_header {
        th.stock {
          padding-left: 16px;
          padding-right: 5px;
        }
        th.price {
          div {
            p {
              padding-right: 5px;
            }
          }
        }

        th.green,
        th.red,
        th.price {
          div {
            p {
            }
          }
        }
      }
      &_item {
        .stock {
          padding-left: 16px;
          padding-right: 5px;
        }
        .price {
          padding-right: 5px;
        }
        .green,
        .red {
        }
        .bet {
          padding-left: 5px;
          padding-right: 10px;
        }
      }
    }
  }
}
.detailBet {
  .wrap_users {
    // @media only screen and (max-width: 1300px) {
    //   right: 415px;
    // }
    // @media only screen and (max-width: 1300px) {
    //   right: 415px;
    // }
    // @media only screen and (max-width: 1200px) {
    //   right: 345px;
    // }
    // @media only screen and (max-width: 1100px) {
    //   right: 310px;
    // }
    @media only screen and (max-width: 2050px)and (min-width: 1850px) {
      right: 36%;
    }
    @media only screen and (max-width: 1850px)and (min-width: 1645px) {
      right: 36%;
    }
    @media only screen and (max-width: 1645px)and (min-width: 1440px) {
      right: 35%;
    }
    @media only screen and (max-width: 1440px)and (min-width: 1363px) {
      right: 32%;
    }
    @media only screen and (max-width: 1100px) {
      right: 15px;
    }
    position: fixed;
    z-index: 9999;
    top: 19px;
    right: 30%;
    .number_bets {
      display: flex;
      flex-direction: column;
      &_text {
        text-align: end;
        p {
          color: var(--cloud);

          font-size: var(--font-size-xs);
          font-style: normal;
          font-weight: 800;
          margin-bottom: 5px;
        }
      }
      &_avatar {
        display: flex;
        flex-direction: row;
        position: relative;
        &_item {
          display: flex;
          flex-direction: row;
          .userInfo{
            .avatar{
              position: absolute;
            }
          }
        }
      }
    }
  }
}
