.topMoves {
  &_options {
    .optionsWrapper {
      width: 100%;
      display: flex;
      background: var(#433960);
      border-radius: 25px;
      position: relative;
    }
  }
  &_options {
    .optionsWrapper {
      &-win {
        .active {
          p {
            color: var(--green);
          }
        }
      }
      &-loss {
        .active {
          p {
            color: var(--red);
          }
        }
      }
      p {
        font-weight: 400;
        font-size: 12px;
        color: var(--gray-blur-text);
      }
    }
  }
  &_title {
    padding: 10px 16px 18px 16px; // padding top like home
    display: flex;
    position: sticky;
    top: calc(61px + 58px + 7px + 8px);
    background-color: var(--bg);
    p {
      display: block;
      color: var(--normal-text-black-color);
      
      font-size: var(--font-size-l);
      font-style: normal;
      font-weight: 500;
    }
  }
  .usersWinLossWrapper {
    padding-top: 0px;
    .userWinLossItem {
      min-height: 47px;
      width: 282px;
      padding: 0 12px;
      margin-bottom: 10px;
      img {
        width: 25px;
        height: 25px;
      }
      p {
        font-size: var(--font-size-s);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .topMoves {
    // padding-top: calc(61px + 120px);
    // &_options {
    //   position: fixed;
    //   width: 100%;
    //   top: 57px;
    //   left: 0;
    //   z-index: 100;
    // }
    &_title {
      position: fixed;
      width: 100%;
      top: calc(61px + 70px - 2px);
      padding-top: 12px;
    }
    .rawHeader {
      margin-bottom: calc(62px + 45px);
    }
    //custom
    .usersWinLossWrapper {
      padding-top: 2px;
      &_title {
        width: calc(100vw - 48px);
        position: fixed;
      }
    }
  }
}
