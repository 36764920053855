.betTable {
  width: 100%;
  th,
  td {
    padding: 16px 0;
    color: var(--white);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
    vertical-align: middle;
  }
  tr {
    cursor: pointer;
    &.hide {
      display: none;
    }
  }
  th {
    text-align: left;
  }
  &_header {
    th {
      div {
        display: flex;
      }
    }
    .stock {
      padding-left: 16px;
      padding-right: 16px;
      width: 19%;
      div {
        justify-content: flex-start;
      }
    }
    .price {
      width: 30%;
      div {
        justify-content: flex-start;
      }
    }
    .green,
    .red {
      width: 15%;
      div {
        justify-content: center;
      }
    }
    .total {
      width: 15%;
      div {
        justify-content: center;
      }
      text-align: center;
    }
    .bet {
      div {
        justify-content: flex-start;
      }
      width: 6%;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #2b2f39;
    }
  }
  &_content {
    width: 100%;
  }
  &_item {
    width: 100%;
    position: relative;
    height: 65px;
    .stock {
      padding-left: 16px;
    }
    .price {
      .volatility {
        margin-top: 4px;
        font-size: var(--font-size-xs);
        font-style: normal;
        font-weight: 400;
      }
    }
    .green {
      text-align: left;
    }
    .red {
      text-align: left;
    }
    .total {
      text-align: center;
    }
    .bet {
      text-align: center;
      padding-right: 16px;
      padding-left: 8px;
      width: 32px;
      cursor: pointer;
      span {
        color: var(--cloud);
        border-radius: 5px;
        border: 1px solid var(--cloud);
        font-size: var(--font-size-xs);
        padding: 2px 5px;
        display: flex;
        div {
          padding: 1px 0px;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background: #2b2f39;
    }
  }
  //important
  &-raw {
    position: fixed;
    z-index: 50;
    width: 100%;
  }
}

@media only screen and (min-width: 1100px) {
  .betTable {
    &_item {
      &:hover {
        background-color: #252a39;
        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: 0;
          width: calc(100%);
          height: 1px;
          background: #2b2f39;
        }
      }
    }
  }
  .betTable-raw {
    background: red;
    display: none !important;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1100px) {
  .betTable {
    &_item {
      .stock {
        position: relative;
        &::after {
          content: "";
          position: absolute;

          width: 512%;
          height: 1px;
          bottom: -0.1px;
          // top: 0.1px;
          // right: -5%;
          background-color: #2b2f39;
          justify-content: center;
          display: flex;
        }
      }
    }
  }
  .betTable-raw {
    padding-top: 0;
    padding-bottom: 0;
    .betTable_item {
      opacity: 1;
      height: 0px;
      background: var(--mirage);
      td {
        padding-top: 0;
        padding-bottom: 0;
        span {
          opacity: 0;
          display: block;
          width: 33px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &::after {
        content: "";
        opacity: 0;
      }
    }
  }
  .betTable_header-notRaw {
    opacity: 0;
  }
}
@media only screen and (min-width: 375px) and (max-width: 500px) {
  .betTable {
    &_item {
      .stock {
        position: relative;
        &::after {
          content: "";
          position: absolute;

          width: 500%;
          height: 1px;
          bottom: -0.1px;
          // top: 0.1px;
          // right: -5%;
          background-color: #2b2f39;
          justify-content: center;
          display: flex;
        }
      }
    }
  }
  .betTable-raw {
    padding-top: 0;
    padding-bottom: 0;
    .betTable_item {
      opacity: 0;
      height: 0px;
      background: var(--mirage);
      td {
        padding-top: 0;
        padding-bottom: 0;
        span {
          opacity: 0;
          display: block;
          width: 33px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &::after {
        content: "";
        opacity: 0;
      }
    }
  }
  .betTable_header-notRaw {
    opacity: 0;
  }
}
@media only screen and (max-width: 375px) {
  .betTable {
    &_item {
      .stock {
        position: relative;
        &::after {
          content: "";
          position: absolute;

          width: 488%;
          height: 1px;
          bottom: -0.1px;
          // top: 0.1px;
          // right: -5%;
          background-color: #2b2f39;
          justify-content: center;
          display: flex;
        }
      }
    }
  }
  .betTable-raw {
    padding-top: 0;
    padding-bottom: 0;
    .betTable_item {
      opacity: 1;
      height: 0px;
      background: var(--mirage);
      td {
        padding-top: 0;
        padding-bottom: 0;
        span {
          opacity: 0;
          display: block;
          width: 33px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &::after {
        content: "";
        opacity: 0;
      }
    }
  }
  .betTable_header-notRaw {
    opacity: 0;
  }
}
