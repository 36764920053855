.shareAndDiscussDemo {
  padding-left: 16px;
  //   background: rgba(255, 0, 0, 0.286);
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 600px;
  img.phone {
    // width: 100%;
    width: 450px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 150;
  }
  img.man {
    width: 125px;
    position: absolute;
    top: 436px;
    left: calc(50% - 165px);
    transform: translateX(-50%);
    z-index: 160;
  }
  .bgCoverPhone {
    position: absolute;
    top: 255px;
    left: calc(50% - 153px);
    transform: translateX(-50%);
    background: var(--body-bg);
    // background: red;
    width: 60px;
    height: 200px;
    border-top-left-radius: 15px;
    z-index: 155;
  }
  .commentWrapper {
    position: absolute;
    width: 315px;
    // width: 100%;
    top: 279px;
    left: calc(50% - 13px);
    transform: translateX(-50%);
    z-index: 300;
    .comment {
      border: 2px solid var(--border);
      &:hover {
        background: var(--green-hover);
      }
      &_avatar {
        // margin-right: 10px;
        width: 33px;
        img {
        }
      }
      &_content {
        &_head {
          .name {
            font-size: 12px;
            margin-bottom: 5px;
          }
          .date {
            font-size: 10px;
          }
        }
        &_text {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .shareAndDiscussDemo {
    margin-bottom: 60px;
    .commentWrapper {
      .comment {
        &:hover {
          background: var(--box-bg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .shareAndDiscussDemo {

    min-height: 92vw;
    margin-top: 70px;
    margin-bottom: 60px;

    img.phone {
      // display: none;
      width: calc(100vw - 32px - 12px);
      top: 0;
      left: auto;
      right: 0px;
      // position: absolute;
      // top: 20%;
      // left: 50%;
      transform: translateX(0%);
      // z-index: 150;
    }
    img.man {
      width: 25vw;
      top: 63vw;
      left: 0px;
      transform: translateX(0%);
    }
    .bgCoverPhone {
      width: 13vw;
      height: 40vw;
      top: 25vw;
      left: 16vw;
      // left: calc(50% - 153px);
      // transform: translateX(-50%);
      // background: var(--body-bg);
      // // background: red;
      // width: 60px;
      // height: 200px;
      // z-index: 155;
    }
    .commentWrapper {
      width: 60vw;
      top: 33vw;
      left: auto;
      right: 18vw;

      transform: translateX(0%);

      .comment {
        &:hover {
        }
        &_avatar {
          // width: 33px;
          img {
          }
        }
        &_content {
          &_head {
            .name {
            }
            .date {
            }
          }
          &_text {
            p {
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
