.userFollowItem {
  display: flex;
  padding: 16px;
  // padding-top: calc(30px - 3px);
  padding-bottom: 0;
  background: var(--bg);
  -webkit-tap-highlight-color: transparent;
  &_left {
    width: 80%;
    .infoUser {
      display: flex;
      &_avatar {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      &_detail {
        padding: 0px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        p {
          display: block;
          margin-right: auto;
        }
        .name {
          color: var(--normal-text-black-color);
          
          font-size: var(--font-size-l);
          font-style: normal;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .followers {
          color: #b3b2b3;
          
          font-size: 12px;
        }
      }
    }
  }
  &_right {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    .btnFollowing,
    .btnFollow {
      min-width: 91px;
      padding: 6px 12px;
      border: 1px solid var(--green);
      border-radius: 5px;
      p {
        font-size: var(--font-size-xs);
        font-style: normal;
        
        color: var(--green);
      }
      // &:hover {
      //   background: var(--green);
      //   p {
      //     color: #000;
      //   }
      // }
    }
    .btnFollow {
      background: var(--green);
      p {
        color: var(--white);
      }
    }
    .btnFollowing {
      cursor: pointer;
    }
  }
}

// custom scss common at user.scss
.userFollowItemActiveBets {
  margin-top: 0px;
  margin-bottom: 30px;
  &-notBets {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
