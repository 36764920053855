.trades {
  &-bar {
    padding-top: 26px;
    padding-right: 10px;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: auto;
    &::-webkit-scrollbar {
      width: 0em;
    }
  }
  &-mobile {
    margin-bottom: 20px; 
    .rightRecentTradesItem {
      img.avatar {
        opacity: 0.8;
      }
      p.text {
        opacity: 0.8;
      }
    }
  }

  &_title {
    margin-bottom: 20px;
    p {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.rightRecentTradesItem {
  min-width: 263px;
  padding: 10px 12px;
  display: flex;
  border-radius: 4px;
  background: var(--box-bland-bg);
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  img.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    opacity: 0.6;
  }
  p.text {
    padding-top: 1px;
    font-size: 14px;
    opacity: 0.4;

    span.dola {
      color: var(--green);
    }
  }

  &:hover {
    border: 1px solid var(--purple);
    img.avatar {
      opacity: 1;
    }
    p.text {
      opacity: 1;
    }
  }
}
