.header {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #2b2f39;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //update
  // position: sticky;
  position: fixed;
  top: 0;
  background: var(--mirage);
  z-index: 100;
  &_flex {
    display: flex;
    flex-direction: row;
  }
  &_btnMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    display: none;
    img {
      filter: invert(80%);
      cursor: pointer;
      &:hover {
        filter: invert(90%);
      }
    }
  }
  &_title {
    padding: 30px 16px;

    .number_bets {
      display: flex;
      flex-direction: column;
      z-index: 9999;
      top: 16px;
      right: 11px;
      &_text {
        p{
          color: var(--cloud);
          font-size: var(--font-size-xs);
          font-style: normal;
          font-weight: 800;
          margin-bottom: 5px;
        }
        
      }
      &_avatar {
        display: flex;
        flex-direction: row;
      }
    }

    p {
      color: var(--white);
      font-size: var(--font-size-l);
      font-style: normal;
      font-weight: 700;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

@media only screen and (max-width: 768px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

@media only screen and (max-width: 1100px) {
  .header {
    &_btnMenu {
      display: flex;
    }
    &_signUp {
      cursor: pointer;
      padding: 30px 16px;

      p {
        color: var(--green);
        font-size: var(--font-size-l);
        font-style: normal;
        font-weight: 700;
      }
    }
  }
}
/* Large devices (laptops/desktops, 1100px and up) */
@media only screen and (min-width: 1100px) {
  .header {
    &_signUp {
      display: none;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
