.rightBar {
  width: 280px;
  position: relative;
  z-index: 1000;
  box-shadow: 20px 0px 20px rgb(57 63 72 / 30%);
  background: var(--right-bar-bg);
  // padding-top: 26px;

  &::before {
    position: absolute;
    content: "";
    left: -1px;
    top: 0;
    width: 1px;
    height: 100%;
    background: var(--border-box);
  }
  .accountInfo {
    padding-top: 18px;
    .accountValue {
      .text {
        color: var(--normal-text-black-color);
      }
    }
  }
  .titleChat {
    padding-left: 25px;
    margin-top: 26px;
    background: var(--right-bar-title-bg);
    border-radius: 4px;
    height: 40px;
    align-items: center;
    display: flex;
    color: var(--normal-text-black-color);
    border: 1px solid var(--border-box);
    border-left: none;
    //
  }
  &_social {
    // margin-top: 20px;
    // padding: 16px;
    // max-height: 585px;
    height: 100%;
    // box-shadow: -20px 0px 20px 0px #1d222f6b;
    // border-radius: 10px;
    // border-left: 1px solid var(--border-box);
    overflow: hidden;
    .social {
      // padding: 16px;
      display: flex;
      height: auto;
      flex-direction: column-reverse;
      height: 100%;
      // justify-content: end;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 16px;
      .typeChatWrapper {
        padding: 0;
        margin: 0;
        // &::before {
        //   position: absolute;
        //   content: "";
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 1px;
        //   background: var(--border);
        // }
      }
      .typeChat {
        margin: 0;
        margin-top: auto;
        box-shadow: none;
        border-radius: 0;
        flex-direction: column-reverse;
        border-radius: 8px;
        background: var(--box-bg);
        border: none;
        margin: 0 17px;
        border: 1px solid var(--border-box);
        textarea {
          font-size: 14px;
          width: calc(90%);
          &::placeholder {
            color: #65686a;
            font-size: 14px;
          }
        }
        .imageUploadWrapper {
          margin-top: 0px;
          margin-bottom: 5px;
          height: fit-content;
          max-height: 400px;
          overflow: hidden;
          img {
            // height: 80px;
            width: 100%;
            // width: auto;
            border: 1px solid var(--border);
            border-radius: 4px;
          }
          .btnCloseImage {
            left: calc(206px);
            top: 11px;
            right: 1px;
            left: auto;
            border-radius: 0;
            border-top-right-radius: 3px;
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .listsChat {
        margin: 0;
        padding: 0;
        margin-bottom: 105px;
        width: 100%;
        // height: calc(100vh - 300px);
        // max-height: calc(100vh  - 290px);
        // background: red;
        scroll-behavior: auto;
        overflow: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0em;
        }
        #bar-checkScroll-chat {
          width: 100%;
          height: 1px; // fix scrollId elm not see
          background: transparent;
        }
        .comment {
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          margin-left: 20px;
          margin-right: 16px;
          box-shadow: none;
          border-radius: 0;
          // border-bottom: 2px solid #f7f7f81c;
          border-bottom: 1px solid var(--border-comment);
          padding: 10px 0;
          padding-bottom: 18px;
          &_content {
            &_head {
              .name {
                font-size: 14px;
                margin-bottom: 7px;
                opacity: 0.8;
              }
              .date {
                font-size: 12px;
              }
            }
            &_text {
              p {
                font-size: 14px;
                margin-bottom: 1px;
                span {
                  opacity: 0.6;
                }
                span.dola {
                  opacity: 1;
                }
              }
            }
            &_images {
              margin-top: 5px;
              // margin-bottom: 5px;
              height: fit-content;
              max-height: 400px;
              overflow: hidden;
              img {
                // height: 80px;
                width: 100%;
                // width: auto;
                border: 1px solid var(--border);
                border-radius: 4px;
              }
            }
          }
        }
      }
      &_content {
        // height: 100%;
        // padding: 50% 0;
      }
    }
  }
  &_input {
    position: relative;
    input {
      border-radius: 8px;
      padding: 8px;
      padding-left: 32px;
      width: 100%;
      background-color: transparent;
      outline: none;
      color: var(--white);
      font-size: var(--font-size-s);
      font-style: normal;
      font-weight: 400;
    }
    input::placeholder {
      color: var(--cloud);
      font-weight: 300;
    }
    &_searchIcon {
      position: absolute;
      top: 7px;
      left: 8px;
    }
  }

  // &_recentTrades {
  //   padding-top: 26px;
  //   padding-right: 10px;
  // }
}

// @media only screen and (max-width: 1260px) {
//   .rightBar {
//     display: none;
//   }
// }
@media only screen and (max-width: 1370px) {
  .rightBar {
    display: none;
  }
}

