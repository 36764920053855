.metricsOrderItem {
  display: flex;
  background: var(--box-bland-bg);
  border-radius: 4px;
  padding: 17px 24px;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 11px;
  }
  p.content {
    font-size: 20px;
    font-weight: 500;
  }
  .wrapperBtns {
    margin-left: auto;
    display: flex;
  }
  .btnYes,
  .btnNo {
    border-radius: 4px;
    width: 53px;
    padding: 10px 0;
    opacity: 0.6;
    p {
      font-size: 16px;
      font-weight: 400;
    }
    &.active {
      opacity: 1;
    }
  }
  .btnYes {
    border: 1px solid var(--green);

    margin-right: 10px;
    p {
      color: var(--green);
    }
  }
  .btnNo {
    border: 1px solid var(--red);
    p {
      color: var(--red);
    }
  }
}

@media screen and (max-width: 1100px) {
  .metricsOrderItem {
      &:last-child {
          margin-bottom: 20px;
      }
    p.content {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.3;
    }
    .btnYes,
    .btnNo {
      width: 40px;
      p {
        font-size: 14px;
      }
    }
  }

}
