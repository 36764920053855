.rewards {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &_head {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    display: none;
    @media only screen and (max-width: 1100px) {
      display: block;
    }
  }
  &_times {
    width: 490px;
    height: 290px;
    background: var(--box-bland-bg);
    border-radius: 8px;
    display: flex;
    margin-right: 26px;
    margin-bottom: 20px;
    .contents {
      margin: auto;
      p.title {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 15px;
        text-align: center;
      }
      p.text {
        font-size: 18px;
        font-weight: 500;
        color: var(--gray);
        text-align: center;
      }
      p.textMobile {
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        display: none;
      }
    }
    @media only screen and (max-width: 1100px) {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;
      height: 45px;
      .contents {
        p.title {
          display: none;
        }
        p.text {
          display: none;
        }
        p.textMobile {
          display: block;
        }
      }
    }
  }
  &_topMarkets,
  &_ranks {
    width: calc((100% - 26px - 26px - 490px) / 2);
    height: 290px;
    background: var(--box-bland-bg);
    border-radius: 8px;
    padding: 25px;
    .title {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 18px;
      display: flex;
      &_right {
        margin-left: auto;
        display: inline-block;
      }
    }
    .item {
      padding: 11px 14px;
      background: var(--bg);
      border-radius: 8px;
      margin-bottom: 8px;
      display: flex;
      &_title,
      &_text {
        font-size: 15px;
        font-weight: 500;
      }

      &_text {
        margin-left: auto;
      }
      &-active {
        background: var(--purple);
      }
    }

    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }
  &_topMarkets {
    //   margin-left: auto;
    margin-right: 26px;
  }

  &_title {
    margin-bottom: 20px;
    background: var(--box-bland-bg);
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    p {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      transform: translateY(1px);
    }
    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }

  &_table {
    width: calc((100% - 26px) / 2);
    background: var(--box-bland-bg);
    // margin-right: 26px;
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden;
    &_header {
      height: 54px;
      //   border-bottom: 1px solid var(--border-comment);
      display: flex;
      align-items: center;
      padding: 0 25px;
      p.title {
        font-size: 15px;
        font-weight: 500;
        // padding: 20px 0 20px 20px;
        transform: translateY(1px);
      }
      .btnBoxReward {
        background: var(--purple);
        margin-left: auto;
        display: inline-block;
        height: 25px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 0 10px;
        cursor: default;
        img {
          width: 12px;
          margin-right: 7px;
        }
        p {
          color: var(--normal-text-black-color);
          font-size: 12px;
          font-weight: 500;
          display: inline-block;
          transform: translateY(1px);
        }
      }
    }
    table {
      width: 100%;

      tr {
        border-top: 1px solid var(--border-comment);
        th,
        td {
          padding: 15px 0;
          text-align: center;

          color: var(--gray);
          font-size: 14px;
        }
        th {
          &.rating {
            text-align: left;
            padding-left: 25px;
            // padding-right: 25px;
          }
          &.username {
            text-align: left;
          }
          &.rank {
            text-align: left;
            @media only screen and (max-width: 1100px) {
              display: none;
            }
          }
          &.value {
            text-align: right;
            padding-right: 25px;
            width: 25%;
            @media only screen and (max-width: 1100px) {
              width: 29%;
            }
          }
        }
      }
      tr.rewardTableItem {
        cursor: pointer;
        td {
          //   display: flex;
          //   align-items: center;
          &.rating {
            text-align: left;
            padding-left: calc(25px + 12px);
            // padding-right: 25px;
          }
          &.username {
            display: flex;
            .usernameWrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: auto;
              transform: translateY(3px);
              .avatar {
                width: 17px;
                margin-right: 10px;
                border-radius: 10px;
              }
              .name {
                //   margin-top: auto;
                //   margin-bottom: auto;
                display: inline-block;
                font-size: 14px;
                color: var(--normal-text-black-color);
                transform: translateY(2px);
              }
              .top {
                margin-left: 10px;
              }
            }
          }
          &.rank {
            line-height: 0px;
            text-align: left;
            @media only screen and (max-width: 1100px) {
              display: none;
            }
          }
          &.value {
            padding-right: 25px;
            text-align: right;
          }
        }
        &-myItem {
          background: var(--purple);
          td {
            color: var(--white)
          }
        }
      }
    }
    &-netLoss {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }
}
