.stockAdminItem {
  width: 24%;
  margin-bottom: 16px;
  background: var(--box-bland-bg); //#fbfafb;
  border-radius: 8px;
  padding: 20px 21px 26px 22px;
  cursor: pointer;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background: var(--green-hover);
  }
  &-green {
    &:hover {
      background: var(--green-hover);
    }
  }
  &-red {
    &:hover {
      background: var(--red-hover);
    }
  }

  &_header {
    display: flex;
    margin-bottom: 16px;
    &_name {
      width: 50%;
      p {
        color: var(--normal-text-black-color);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
      }
    }
    &_price {
      width: 50%;
      p {
        font-size: 14px;
        font-style: normal;
        text-align: right;
        font-weight: 400;
        margin-bottom: 4px;
        height: 14px;
      }
      p.volatility {
        font-size: 12px;
        height: 12px;
      }
    }
  }
  &_percent {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &.red {
      margin-bottom: 25px;
    }
    &_hoz {
      width: calc(100% - 40px);
      .hoz-green {
        height: 16px;
        background: var(--green);
        border-radius: 2px;
        transition: 0.3s;
        width: 0%;
      }
      .hoz-red {
        height: 16px;
        background: red;
        background: var(--red);
        border-radius: 2px;
        transition: 0.3s;
        width: 0%;
      }
    }
    &_number {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        display: block;
        margin-left: auto;
        
        font-size: 16px;
        font-style: normal;
        text-align: right;
        font-weight: 400;
      }
    }
  }
  &_footer {
    display: flex;
    width: 100%;
    gap: 4%;
    .inputWrapper {
      display: flex;
      .dollarsText {
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 2px 6px 2px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        p {
          font-size: 14px;
          font-weight: 500;
          font-family: var(--font-family-overpass);
        }
        p.red {
          color: #fb8081;
        }
        p.green {
          color: #24cea4;
        }
      }
      .dollarsText.red {
        border: 1px #fb8081 solid;
        border-right: none;
      }
      .dollarsText.green {
        border: 1px #24cea4 solid;
        border-right: none;
      }
      input {
        width: calc(100%);
        padding: 8px 6px 6px 2px;
        outline: none;
        background: none;
        color: var(--normal-text-black-color);
        font-family: var(--font-family-overpass);
        font-size: var(--font-size-s);
        font-style: normal;
        font-weight: 400;
        border-radius: 0px 5px 5px 0px;
        text-align: right;
      }
      input.red {
        border: 1px #fb8081 solid;
        color: #fb8081;
        width: 100%;
        border-left: none;
      }
      input.green {
        border: 1px #24cea4 solid;
        color: #24cea4;
        width: 100%;
        border-left: none;
      }
      input:focus {
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 1330px) {
  .stockAdminItem {
    width: 32%;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1150px) {
  .stockAdminItem {
    width: 48%;
  }
}

@media only screen and (max-width: 1100px) {
  .stockAdminItem {
    width: 24%;
  }
}
@media only screen and (max-width: 750px) {
  .stockAdminItem {
    width: 32%;
  }
}

@media only screen and (max-width: 650px) {
  .stockAdminItem {
    width: 49%;
    &_header {
      &_name {
        p {
          font-size: 26px;
        }
      }
    }
    &:hover {
      background: var(--box-bland-bg);
    }
  }
}
