.deposit-custom {
  position: fixed;
}
.auth,
.deposit,
.withdraw,
.settings,
.userPage,
.wallet,
.social {
  &_options {
    width: 100%;
    padding: 16px;
    padding-top: 27px;
    padding-bottom: 20px;
    position: sticky;
    top: 61px;
    background: var(--bg);
    z-index: 500;
    .optionsWrapper {
      width: 100%;
      display: flex;
      background: var(--gray-bg);
      border-radius: 25px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0%;
        top: 0;
        z-index: 100;
        width: calc(100% / 2);
        height: 100%;
        background: var(--green);
        border-radius: 25px;
        transition: 0.1s;
      }
    }
    .btnSignIn,
    .btnSignUp,
    .btnCoinbase,
    .btnBank,
    .btnWin,
    .btnLoss,
    // .btnUserPage,
    .btnWallet,
    .btnSocial,
    .btnFollowing {
      // width: 50%;
      // justify-content: center;
      // padding: 20px 16px;
      // border-bottom: 1px solid #2b2f39;
      // border-right: 1px solid #2b2f39;
      // position: relative;
      //
      padding: 5px 10px;
      width: calc(100% / 2);
      position: relative;
      z-index: 1000;
      height: 26px;
      p {
        color: var(--normal-text-black-color);
        
        font-size: var(--font-size-s);
        font-style: normal;
        transition: 0.1s;
      }
      &.active {
        p {
          color: var(--normal-text-color);
        }
      }
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   transform: translateX(0);
    //   width: 50%;
    //   height: 4px;
    //   background: var(--green);
    //   transition: 0.2s;
    //   z-index: 100;
    // }
    &-sign-up {
      &::before {
        bottom: 0;
        left: calc(0%);
      }
    }
    &-sign-in {
      &::before {
        bottom: 0;
        left: calc(50%);
      }
    }
    &-coin,
    &-portfolio {
      &::before {
        bottom: 0;
        left: calc(0%);
      }
    }
    &-bank,
    &-posts {
      &::before {
        bottom: 0;
        left: calc(50%);
      }
    }
  }
  &_title {
    padding: 16px 16px 0 16px;
    padding-top: 20px;
    p {
      text-align: left;
      font-size: 18px;
      font-style: normal;
      
      color: var(--normal-text-black-color);
      font-style: normal;
      font-weight: 500;
    }
  }
  &_text {
    padding: 5px 16px;
    p {
      text-align: left;
      font-size: 12px;
      font-style: normal;
      
      color: #8e8e8f;
      font-style: normal;
      line-height: 1.5;
      font-weight: 400;
    }
  }
  &_optionsMoney {
    padding: 20px 16px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .btnMoney {
      width: 30%;
      padding: 20px 10px;
      margin-bottom: 18px;
      border-radius: 5px;
      box-shadow: 0px 2px 1px rgba(57, 63, 72, 0.3),
        1px 0px 5px rgba(57, 63, 72, 0.3);
      background: var(--box-bg);
      &:hover {
        background: var(--green-hover);
      }
      p {
        font-size: 18px;
        font-style: normal;
        
        color: var(--normal-text-black-color);
        font-weight: 500;
      }
      &.active {
        background: var(--green);
        p {
          color: var(--normal-text-color);
        }
      }
    }
  }
  &_continue {
    padding: 20px 16px;
    .btnContinue {
      width: 120px;
      border: 1px solid var(--green);
      padding: 8px 10px;
      border-radius: 5px;
      p {
        font-size: 12px;
        font-style: normal;
        
        color: var(--green);
        font-weight: 700;
      }
      &:hover {
        background: var(--green);
        p {
          color: var(--normal-text-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .deposit,
  .withdraw,
  .settings,
  .topMoves {
    &_options {
      .btnCoinbase,
      .btnBank,
      .btnWin,
      .btnLoss {
        p {
          font-size: var(--font-size-s);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .deposit {
  }
}
