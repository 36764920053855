.stockItem {
  width: 100%;
  margin-bottom: 16px;
  background: var(--box-bland-bg); //#fbfafb;
  border-radius: 8px;
  padding: 14px 17px 12px 17px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  // box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // height: 152px;
  border: 1px solid var(--border-box);

  &:hover {
    // background: var(--green-hover);
    border: 1px solid var(--green-hover-border);
  }
  &-green {
    &:hover {
      // background: var(--green-hover);
      border: 1px solid var(--green-hover-border);
    }
  }
  &-red {
    &:hover {
      // background: var(--red-hover);
      border: 1px solid var(--red-hover-border);
    }
  }

  &_header {
    display: flex;
    margin-bottom: 23px;
    align-items: center;
    height: 33px;

    &_name {
      width: 100%;
      display: flex;
      &_logo {
        margin-right: 10px; // => w = 33 + 10
        img {
          width: 33px;
          border-radius: 50%;
        }
      }
      &_info {
        flex: 1;
        padding-top: 4px;
        p {
          color: var(--normal-text-black-color);
          font-style: normal;
          display: flex;
          height: auto;
        }
        .title {
          font-size: 15px;
          margin-bottom: 2px;
          white-space: nowrap;
          &_name {
            font-weight: 700;
          }
          &_price {
            margin-left: auto;
            display: flex;
            font-weight: 500;
            .ticker-view {
              color: var(--normal-text-black-color);
            }
          }
        }
        .text {
          font-size: 12px;
          color: var(--gray-blur-text);
          font-weight: 400;
          white-space: nowrap;
          &_name {
          }
          &_price {
            margin-left: auto;
          }
        }
      }
    }
    &_price {
      display: none;
      width: 40%;
      padding-top: 4px;
      p {
        font-size: 15px;
        font-style: normal;
        text-align: right;
        font-weight: 400;
        // margin-bottom: 2px;
        height: 14px;
        margin-bottom: 4px;
      }
      p.volatility {
        font-size: 12px;
        height: 12px;
      }
    }
  }
  &_percent {
    display: flex;
    margin-bottom: 7px;
    position: relative;
    &_hoz {
      // width: calc(100% - 40px);
      width: calc(100%);

      .hoz-green {
        height: 13px;
        background: var(--green);
        border-radius: 2px;
        transition: 0.5s;
        width: 50%;
        min-width: 1%;
        max-width: 100%;
      }
      .hoz-red {
        height: 13px;
        background: red;
        background: var(--red);
        border-radius: 2px;
        transition: 0.3s;
        width: 50%;
        min-width: 1%;
        max-width: 100%;
      }
    }
    &_number {
      position: absolute;
      top: 1.5px;
      right: 0;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      // display: none;
      p {
        display: block;
        margin-left: auto;
        color: var(--normal-text-black-color);

        font-size: 14px;
        font-style: normal;
        text-align: right;
        font-weight: 400;
        height: 14px;
      }
    }
  }
  &_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    height: 18px;
    &_investor,
    &_money {
      display: flex;
      align-items: center;
      width: 50%;
      img {
        width: 18px;
        display: block;
        margin-right: 6px;
        filter: invert(1);
      }
      p {
        display: block;
        color: var(--normal-text-black-color);

        font-size: 14px;
        font-style: normal;
        text-align: right;
        font-weight: 500;
      }
    }
    &_money {
      width: auto;
      // margin-left: auto;
      img {
        margin-left: 6px;
      }
    }
    &_volume {
      display: flex;
      &_text {
        padding-right: 5px;
      }
      p {
        color: var(--gray-text-3);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .stockItem {
    &:hover {
      background: var(--box-bland-bg);
    }
    &_header {
      &_name {
        p {
          // font-size: 26px;
        }
        &_info {
          padding-top: 3px;
          .title_price {
            //custom
            .ticker-view {
              span {
                font-size: 12px;
              }
              .number-placeholder {
                font-size: 12px;
              }
            }
          }
        }
      }
      &_price {
        p,
        .volatility {
          // font-size: 9px;
        }
      }
    }
    &_percent {
      &_number {
        p {
          // font-size: 11px;
        }
      }
    }
    &_footer {
      &_volume {
        p {
          // font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .stockItem {
    padding: 13px;
    &_header {
      height: 28px;
      margin-bottom: 18px;
      &_name {
        &_logo {
          img {
            width: 28px;
          }
        }
        &_info {
          .title {
            font-size: 12px;
          }
          .text {
            font-size: 10px;
          }
        }
      }
      &_price {
        padding-top: 4px;
        p {
          font-size: 12px;
          margin-bottom: 0px;
          height: auto;
          //
        }
        p.volatility {
          margin-top: 1px;
          font-size: 10px;
        }
      }
    }
    &_percent {
      margin-bottom: 5px;
      &_hoz {
        .hoz-green {
          height: 11px;
        }
        .hoz-red {
          height: 11px;
        }
      }
      &_number {
        p {
          // font-size: 11px;
        }
      }
    }
    &_footer {
      height: auto;
      margin-top: 17px;
      &_volume {
        p {
          font-size: 11px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .stockItem_header_name {
    margin-bottom: auto;
    p {
      font-size: 23px;
    }
  }
  .stockItem_percent {
    height: 11px;
  }
  .stockItem_percent_number p {
    font-size: 11px;
  }
}
