.topHeaderLandingWrapper {
  background-color: var(--body-landing-bg);
  position: fixed;
  z-index: 1000;
  width: 100%;
  border-bottom: 2px solid var(--box-bg);
  display: flex;
  justify-content: center;
  * {
    font-family: var(--font-family-overpass);
  }
}

.top-header-landing {
  width: 100%;
  display: flex;
  flex-direction: row;
  // padding: 12px 18%;
  justify-content: space-between;
  min-height: 61px;
  max-width: calc(220px + 600px + 300px);
  // background: rgba(0, 128, 0, 0.211);
  &_logo {
    // background: rgba(255, 0, 0, 0.176);
    cursor: pointer;
    width: 220px;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    &_img {
      img {
        width: 27px;
        height: 27px;
      }
    }
    &_text {
      color: var(--normal-text-black-colorr);
      font-size: 19px;
      font-weight: 500;
      padding-left: 2px;
      transform: translateY(1px);
    }
  }
  &_navbar {
    font-family: var(--font-family-overpass);
    max-width: 600px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 64px;

    &_item {
      color: var(--normal-text-black-colorr);
      font-size: 14px;
      margin: 0px 18px;
    }
  }
  &_auth {
    display: flex;
    flex-direction: row;
    width: 300px;
    // background: rgba(210, 105, 30, 0.279);
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    .btnSignUp,
    .btnLogin {
      width: 95px;
      height: fit-content;
      padding: 6px 16px;
      border: 1px solid var(--green);
      border-radius: 4px;
      p {
        font-size: 13px;
        font-weight: 400;
        color: #2b2b2d;
        transform: translateY(1px);
      }
    }
    .btnLogin {
      margin-right: 16px;
      border: 1px solid var(--green);
      p {
        color: var(--green);
      }
    }

    .btnSignUp {
      border: 1px solid var(--green);
      background-color: var(--green);
      p {
        color: var(--normal-text-color); //#fff;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .top-header-landing {
    padding-left: 16px;
    padding-right: 16px;
    &_logo {
      padding-left: 0;
    }
    &_navbar {
      display: none;
    }
    &_auth {
      padding-right: 0;
      .btnSignUp,
      .btnLogin {
        p {
        }
      }
      .btnLogin {
        margin-right: 8px;
      }
    }
  }
}
