.notifications {
  p.titleBig {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .notificationItem {
    padding: 16px 20px;
    padding-bottom: 13px;
    // background: green;
    cursor: pointer;
    display: flex;
    border-top: 1px solid #414548;
    // border-bottom: 1px solid #414548;
    &:hover {
      background: rgb(54, 58, 60);
    }
    &_left {
      width: 38px;
      img {
        width: 19px;
        // margin-right: 19px;
      }
    }
    &_right {
      // background: red;
      width: calc(100% - 38px);
      .title {
        font-size: 14px;
        color: var(--normal-text-black-color);
        display: flex;
        margin-bottom: 10px;
        span.left {
          display: inline-block;
          margin-right: auto;
          font-weight: 600;
          font-size: 14px;
        }
        span.right {
          display: inline-block;
          margin-left: auto;
          font-weight: 600;
          font-size: 14px;
        }
      }
      .text {
        font-size: 13px;
        color: var(--gray);
        display: flex;
        span.left {
          display: inline-block;
          margin-right: auto;
        }
        span.right {
          display: inline-block;
          margin-left: auto;
        }
      }
    }
  }
}
