.settings-custom {
  position: fixed;
}

// look like deposit. title, text, button
// look like withdraw. input

.settings {
  &_title {
    padding: 16px 0;
  }
  &_buttons {
    padding: 20px 0;
    display: flex;
    .btnUpdate,
    .btnLogout {
      width: 100px;
      border: 1px solid var(--green);
      padding: 5px 10px;
      border-radius: 5px;
      p {
        font-size: var(--font-size-s);
        font-style: normal;
        
        color: var(--green);
      }
      &:hover {
        background: var(--green);
        p {
          color: var(--normal-text-color);
        }
      }
    }
    .btnLogout {
      margin-left: 20px;
      border: 1px solid var(--cloud);
      p {
        color: var(--normal-text-black-color);
      }
      &:hover {
        // border: 1px solid var(--green);
        background: var(--body-bg);
        // box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
        p {
          color: var(--normal-text-black-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .settings {
    &_input {
      .inputWrapper {
        input {
          font-size: var(--font-size-m);
        }
      }
    }
  }
}
