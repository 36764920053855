.commonButton {
  background: transparent;
  border: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: var(--normal-text-color);
  }
  img {
    width: 2em;
  }
  .active {
  }
}
.btnWin {
  padding-right: 7px;
}
.btnWin,
.btnLoss {
  p {
    font-size: var(--font-size-m) !important;
  }
}

@media screen and (max-width: 600px) {
  .btnWin,
  .btnLoss {
  }
  .btnWin{
    padding-right: 3px;
  }
  .btnLoss {
    padding-right: 0;
    padding-left: 3px;
  }
}
