.footer-mobile {
  position: fixed;
  list-style: none;
  width: 100%;
  bottom: 0;
  background-color: var(--body-bg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  z-index: 1000;
  border-top: 1px solid var(--border-type-chat);
  &_item {
    cursor: pointer;
    padding: 20px;
    svg {
      width: 18px;
      height: 18px;
      // fill: #A7A9AA;
    }
  }
  .active {
    // svg {
    //   fill: #7F72DA;
    //   filter: invert(0);
    //   g {
    //     fill: #7F72DA;
    //   }
    //   ellipse,
    //   path,
    //   rect {
    //     stroke: #ca9dff;
    //   }
    // }
  }
  // padding: ;
  &-admin {
    .footer-mobile_item {
      svg {
        fill: #a7a9aa;
      }
    }
    .active {
      svg {
        fill: #7f72da;
        filter: invert(0);
        g {
          fill: #7f72da;
        }
        ellipse,
        path,
        rect {
          stroke: #ca9dff;
        }
      }
    }
  }
}
@media only screen and (min-width: 1100px) {
  .footer-mobile {
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .footer-mobile {
    width: 400px;
    left: 50%;
    transform: translateX(-50%);
  }
}
