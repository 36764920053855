.following-custom {
  .footerWrapper {
    // margin-top: 30px;
  }
}

.following {
  display: flex;
  .desktop {
    display: flex;
    width: 100%;
  }
  .mobile {
    display: none;
  }
  &_padding {
    padding: 24px 38px;
  }
  &_title {
    padding-bottom: 23px;
    p {
      font-size: var(--font-size-l);
      font-weight: bold;
    }
  }
  &_subscriptions {
    // width: 70%;
    // padding-right: 50px;
    // margin-bottom: 100px;
    .nothing {
      background: var(--box-bland-bg);
      height: calc(100vh - 250px + 25px);
      width: 100%;
      transform: translateX(-4px);
      padding: 16px;
      display: flex;
      border: 1px solid var(--border-box);
      margin-bottom: 30px;
      p {
        color: var(--gray-blur-text-2);
      }
    }
  }
  &_follow {
    min-height: calc(100vh - 250px);
    // position: sticky;
    top: 100px;
    overflow: hidden;
    // border-bottom: 1px solid var(--border-box);
    margin-bottom: 30px;
    &_list {
      .followUserItem:nth-last-child(1) {
        margin-bottom: 0;
      }
      // .followUserItem:last-child() {
      //   background: red;
      //   margin-bottom: 0;
      // }
    }
  }
  &_table {
    background: var(--box-bland-bg);
    border-color: #707070;
    padding: 24px 33px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 1px solid var(--border-box);
    &_person {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_name {
        display: flex;
        align-items: center;
        p {
          font-size: 18px;
          font-weight: bold;
        }
        .avatar {
          margin-right: 16px;
          img {
            width: 36px;
            border-radius: 50%;
          }
        }
      }
      .btnFollowing {
        width: 107px;
        height: 37px;
        background: var(--purple);
        border-radius: 4px;

        p {
          color: white;
          // padding: 13px 18px;
          font-size: 14px;
        }
      }
    }
    &_detail {
      table {
        width: 100%;
      }
      padding-top: 39px;
      &_header {
        justify-content: space-between;
        flex-wrap: nowrap;

        border-bottom: 1px solid var(--border-comment);
        &_tr {
          font-size: 13px;
          color: var(--gray-blur-text);

          th {
            text-align: start;
            width: 16%;
            font-size: 13px;
            font-weight: 400;
            padding-bottom: 16px;
          }
        }
      }
      &_tbody {
        tr {
          height: 44px;
        }
        td {
          font-size: 15px;
          padding-top: 24px;
          color: var(--normal-text-black-color);
          font-family: var(--font-family-overpass);
          p {
            border-right: 1px solid #544b6e;
            margin-right: 20px;
          }
        }
        .shares_td_win {
          color: var(--green);
        }
        .shares_td_loss {
          color: var(--red);
        }
      }
    }
  }
  &_description {
    background: var(--box-bland-bg);
    height: calc(100vh - 150px);
    overflow: auto;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    z-index: 2;
    // margin-bottom: 30px;
    border: 1px solid var(--border-box);

    &_padding {
      padding: 0 20px 25px;
      width: 100%;
      padding-top: 16px;
      //custom
      .comment {
        padding: 0;
        padding-bottom: 18px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--border-comment);
        &_content_head {
          .name {
            color: var(--normal-text-black-color);
            opacity: 0.8;
          }
        }
        &_content_text {
          p {
            font-size: 14px;
            margin-bottom: 1px;
            span {
              opacity: 0.6;
            }
            span.dola {
              opacity: 1;
            }
          }
        }
      }
    }
    &_persons {
      padding: 20px 0;
      display: flex;
      border-bottom: 1px solid var(--border-comment);
      .comment_avatar {
        width: 24px;
        margin-right: 10px;
        height: 24px;
      }
    }
    .name {
      align-items: center;
      p {
        font-size: 14px;
        padding-bottom: 7px;
      }
      .description {
        font-size: 14px;
        color: var(--normal-text-black-color);
        opacity: 0.6;
      }
    }
    &-nothing {
      padding: 16px;
      background: var(--box-bland-bg);
      height: calc(100vh - 250px + 25px);
      // padding: 16px;
      display: flex;
      border: 1px solid var(--border-box);
      p {
        color: var(--gray-blur-text-2);
      }
    }
  }
  &_description::-webkit-scrollbar {
    display: none;
  }
  &_options {
    position: sticky;
    top: 61px;
    .optionsWrapper {
      &-SUBSCRIPTIONS {
        &::before {
          left: calc(0);
        }
      }
      &-COMMENTS {
        &::before {
          left: calc(100% / 2);
        }
      }
    }
  }

  &_user {
    display: flex;
    padding: 16px;
    // padding-top: calc(30px - 3px);
    padding-bottom: 0;
    background: var(--bg);
    &_left {
      width: 80%;
      .infoUser {
        display: flex;
        &_avatar {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        &_detail {
          padding: 0px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;
          p {
            display: block;
            margin-right: auto;
          }
          .name {
            color: var(--normal-text-black-color);

            font-size: var(--font-size-l);
            font-style: normal;
            font-weight: 500;
            margin-bottom: 5px;
          }
          .followers {
            color: var(--normal-text-black-color);

            font-size: 12px;
          }
        }
      }
    }
    &_right {
      .btnFollowing,
      .btnFollow {
        min-width: 91px;
        padding: 6px 12px;
        border: 1px solid var(--green);
        border-radius: 5px;
        p {
          font-size: var(--font-size-xs);
          font-style: normal;

          color: var(--green);
        }
        // &:hover {
        //   background: var(--green);
        //   p {
        //     color: #000;
        //   }
        // }
      }
      .btnFollow {
        background: var(--green);
        p {
          color: var(--white);
        }
      }
      .btnFollowing {
        cursor: auto;
      }
    }
  }
  &_notSubs {
    // height: calc(100vh - 61px - 61px);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-wrap: wrap;
    padding-top: 170px;
    p {
      text-align: center;
      width: 270px;
      display: block;
      color: #adacae;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;
    }
    p.text {
      font-size: 14px;
      line-height: 1.2;
    }
  }
  //custom
  .listsChat {
    padding: 16px;
    padding-top: 9px;
  }
  .userFollowItem {
    // min-height: 59px;
    padding-top: 30px;
    padding-bottom: 30px;
    // border-top: 1px solid var(--border);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background: var(--border);
    }
    &:nth-child(1),
    &-disableBorder {
      padding-top: 14px;
      // background: red;
      &::before {
        background: transparent;
      }
    }
    .my-bet-list {
      .my-bet-item {
        margin-bottom: 0;
      }
    }
  }
}
@media only screen and (max-width: 1170px) {
  .following {
    display: block;
    &_subscriptions {
      width: 100%;
      padding-right: 0;
    }
    &_follow {
      width: 100%;
      height: unset;
    }
    &_description {
      // height: unset;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .following {
    // padding-top: 16px;
    flex-direction: column;
    display: flex;
    &_follow {
      // padding-top: 16px;
      margin-bottom: 20px;
      min-height: fit-content;
      .following_title {
        padding-bottom: 10px;
      }
      .following_description-nothing {
        height: 38px;
        padding-top: 13px;
        margin-bottom: 0px;
        p {
          font-size: 14px;
        }
      }
    }
    &_description {
      height: unset !important;
    }
    &_subscriptions {
      .following_title {
        padding-bottom: 10px;
      }
      .nothing {
        height: 38px;
        padding-top: 13px;
        margin-bottom: 20px;
        p {
          font-size: 14px;
        }
      }
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 425px) {
  .following {
    .maxReturn,
    .avgPrice,
    .entry {
      display: none;
    }

    .following_table_detail_header_tr th {
      text-align: start;
      width: 27%;
      font-size: 12px;
    }
    &_title {
      p {
        font-size: 16px;
      }
      font-size: 16px;
    }
    &_table {
      padding: 16px;
      &_detail {
        padding-top: 27px;
        &_tbody td {
          font-size: 14px;
        }
      }
      &_person_name {
        p {
          font-size: 16px;
        }
        .avatar {
          img {
            width: 24px;
          }
        }
      }
    }
  }
}

// followUserItem

.followUserItem {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  border-radius: 8px;
  background: var(--box-bland-bg);
  margin-bottom: 12px;
  .avatar {
    width: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .name {
    width: 110px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    color: var(--normal-text-black-color);
    transform: translateY(2px);
  }
  .text {
    margin-left: auto;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-blur-text);
    transform: translateY(2px);
  }
}

@media only screen and (max-width: 1100px) {
  .followUserItem {
    .name {
      font-size: 14px;
    }
    .text {
      font-size: 14px;
    }
  }
}
