.startedPage {
  width: 100%;
  padding: 16px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //   background: rgba(255, 0, 0, 0.176);
  margin-top: 70px;
  &_box {
    width: 100%;
    background: var(--green);
    padding: 30px 65px;
    border-radius: 5px;
    position: relative;
    &.reveal {
      top: 100px;
      transition: all 0.5s ease;
    }
    &.reveal-active {
      top: 0;
    }
    &_title {
      margin-bottom: 8px;
      p {
        font-size: 30px;
        color: var(--normal-text-color);
        font-weight: 700;
      }
    }
    .boxSignUp {
      display: flex;
      width: 50%;
      &_input {
        margin-right: 10px;
        padding: 8px;
        padding-left: 12px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--border); //#e5e5e5;
        background-color: transparent;
        outline: none;
        color: #898b91;
        
        font-size: var(--font-size-s);
        font-style: normal;
        font-weight: 400;
        background: var(--body-bg);
      }
      .btnSignUp {
        width: 100px;
        background-color: var(--green);
        outline: none;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid var(--body-bg);
        P {
          color: var(--normal-text-color);
          font-weight: 500;
          transform: translateY(1px);
        }
      }
    }
    &_image {
      width: 240px;
      position: absolute;
      top: -120%;
      right: 15%;
      &.reveal {
        transform: translateY(150px);
        transition: all 0.6s ease;
      }
      &.reveal-active {
        transform: translateY(0px);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .startedPage {
    min-height: auto;
    margin-top: 35px;
    margin-bottom: 30px;
    &_box {
      padding: 25px 16px;
      &_title {
      }
      .boxSignUp {
        width: 100%;
        .btnSignUp {
          border: 2px solid var(--body-bg);
        }
      }
      &_image {
        display: none;
      }
    }
  }
}
