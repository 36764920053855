.input-bet {
  width: 150px;
  height: 35px;
  position: relative;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--body-bg);
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);

  .money_icon {
    // position: absolute;
    height: 100%;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 500;
    color: var(--normal-text-black-color);
  }
  input {
    background: var(--box-bg);
    width: calc(100% - 25px);
    padding: 5px 10px;
    padding-right: 44px;
    outline: none;
    border: none;
    height: 100%;
    color: var(--normal-text-black-color);
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &__submit {
    position: absolute;
    right: 0;
    height: 100%;
    width: 34px;
    outline: none;
    border: none;
    // border-top-left-radius: 2px;
    // border-bottom-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 6px;
    font-weight: 500;
    cursor: pointer;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
  }
  &.green {
    .money_icon {
     
    }
    .input-bet__submit {
      width: 35%;
      background-color: var(--green);
      color: var(--normal-text-color);
      border-radius: 4px;
      font-weight: 500;
    }
    input {
      
    }
  }
  &.red {
    margin-top: 8px;
    .money_icon {
      
    }
    .input-bet__submit {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: var(--normal-text-color);
      background-color: var(--red);
      font-weight: 500;
    }
    input {
     
    }
  }
}
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (max-width: 1100px) {
  .input-bet {
    input {
      height: 30px;
      font-size: var(--font-size-m);
    }
  }
 }