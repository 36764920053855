// .chartStock
.chartSymbolItem {
  //
  background: var(--box-bland-bg);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid var(--border-box);
  margin-bottom: 15px;
  &_head {
    padding: 26px 29px 15px 29px;
    display: flex;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // border-bottom: 1px solid var(--border-comment);
    border-top: 1px solid var(--border-box);
    &_left {
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .text {
        font-size: 14px;
        color: var(--gray-blur-text);
      }
    }
    &_right {
      //
      margin-left: auto;
      display: flex;
      .green,
      .red {
        border-radius: 4px;
        display: flex;
        width: 55px;
        height: 32px;
        p {
          display: inline-block;
          margin: auto;
          font-size: 13px;
          font-weight: 500;
        }
      }
      .green {
        background: #2b3f3f;
        margin-right: 9px;
        p {
          color: var(--green);
        }
      }
      .red {
        background: #4d2d2e;
        p {
          color: var(--red);
        }
      }
    }

    @media only screen and (max-width: 1100px) {
      padding: 15px;
      .chartSymbolItem_head_left {
        .title {
          font-size: 14px;
          margin-bottom: 6px;
        }

        .text {
          font-size: 12px;
        }
      }
      .chartSymbolItem_head_right {
        .green,.red {
          height: 30px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }

  &.active {
    .chartSymbolItem_head {
      cursor: auto;
    }
  }
  &.notActive {
    .chartStock_options,
    .chartStock_prices,
    .chartStock_chartOne,
    .chartStock_chartTwo,
    .chartStock_chartThree {
      display: none;
    }
  }
  &.last {
    margin-bottom: 0;
  }
}
