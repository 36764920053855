.investorsPage {
  width: 100%;
  //   background: rgba(128, 255, 0, 0.129);
  padding: 16px;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-top: 14vh;
  &_title {
    width: 100%;
    margin-bottom: 25px;
    p {
      text-align: center;
      font-size: 35px;
      font-weight: 500;
    }
  }
  &_text {
    width: 100%;
    margin-bottom: 45px;
    p {
      text-align: center;
    }
  }
  &_investors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
    &.reveal {
      transition: all 0.8s ease;
    }
    &.reveal-active {
    }
    .investorBox {
      width: 24%;
      margin: 14px;
      max-height: 152px;
      //   &-fat {
      //     width: 27%;
      //     padding-left: 20px;
      //     padding-right: 20px;
      //   }
      //   &-short {
      //     max-height: 138px;
      //   }
      &:nth-child(2),
      &:nth-child(5) {
        width: 27%;
        padding-left: 20px;
        padding-right: 20px;
      }
      &:nth-child(4),
      &:nth-child(6) {
        transform: translateY(138px - 152px);
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        max-height: 138px;
        .investorBox_content {
          p {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      &_content {
        p {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

.investorBox {
  width: 100%;
  background: var(--box-bg);
  padding: 18px;
  border-radius: 4px;
  height: fit-content;
  &:hover {
    background: var(--green-hover);
  }
  &-fat {
    //   padding: 20px;
  }
  &_head {
    display: flex;
    margin-bottom: 10px;
    &_left {
      img.avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    &_right {
      padding-top: 5px;
      p {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      p.name {
        font-size: 15px;
        font-weight: 400;
        color: #e6e7ea;
        margin-bottom: 3px;
      }
      p.position {
        font-size: 12px;
        font-weight: 400;
        color: #e6e7ea;
      }
    }
  }
  &_content {
    margin-bottom: 10px;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
      color: #a6a9b2;
    }
  }
  &_bottom {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 10px;
      img {
        width: 14px;
        filter: invert(100%);
      }
    }
    .text {
      p {
        font-size: 12px;
        color: --var(--normal-text-black-color);
        color: #b3b3b3;
        transform: translateY(0px);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .investorsPage {
    &_investors {
      .investorBox {
        &:hover {
          background: var(--box-bg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .investorsPage {
    &_title p {
      span {
        display: block;
      }
    }
    &_text {
      margin-bottom: 22px;
    }
    &_investors {
      .investorBox {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        &:nth-child(2),
        &:nth-child(5) {
          width: 100%;
          max-height: auto;
        }
        &:nth-child(4),
        &:nth-child(6) {
          transform: translateY(0px);
        }
        &_head {
        }
        &_content {
          p {
            line-height: 1.3;
          }
        }
      }
    }
  }
}
