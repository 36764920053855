.ticker-view {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    font-size: 15px;
    position: relative;
    color: white;
  }
  
  .number-placeholder {
    visibility: hidden;
  }
  
  .ticker-column-container {
    position: relative;
  }
  
  .ticker-column {
    position: absolute;
    height: 1000%;
    bottom: 0;
  }
  
  .ticker-digit {
    height: 10%;
    //custom
    display: flex;
    span {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    // end custom
  }
  
  .ticker-column.increase {
    animation: pulseGreen 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
  }
  
  .ticker-column.decrease {
    animation: pulseRed 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
  }
  
  @keyframes pulseGreen {
    0%,
    100% {
      color: var(--normal-text-black-color);
    }
  
    50% {
      // @apply text-green-500;
      color: var(--green);
    }
  }
  
  @keyframes pulseRed {
    0%,
    100% {
      color: --normal-text-black-color;
    }
  
    50% {
      // @apply text-red-500;
      color: var(--red);
    }
  }