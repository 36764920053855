.portfolio {
  margin-bottom: 30px;
  // padding-top: 18px;
  .rawHeader {
    display: flex;
    justify-content: space-between;
    height: auto;
    &_chart {
      // width: 70%;
      width: calc(100% - 282px);
      padding-right: 50px;
      padding-bottom: 30px;
      &_container {
        // height: 327px;
        background: var(--box-bland-bg);
        width: 100%;
        margin-top: 17px;
        border-radius: 8px;
        border: 1px solid var(--border-box);
        // .chartUserWrapper {
        //   border: 1px solid var(--border-box);
        // }
        .chartUserWrapper {
          max-height: 332px;
        }
      }
      &_follower {
        height: 334px;
        background: var(--box-bland-bg);
        width: 100%;
        margin-top: 17px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 18px;
          color: var(--gray);
        }
      }
    }
  }
  &_positions {
    margin-top: 10px;
    margin-bottom: calc(40px - 15px);
    // overflow-x: hidden;
    &_title {
      margin-bottom: 18px; // look llike 20px
      position: relative;
      p {
        font-size: 23px;
        font-weight: 600;
      }
      &_buttons {
        position: absolute;
        top: -4px;
        right: 0px;
        display: flex;
        .btnPrev {
          margin-right: 8px;
          img {
            transform: rotateZ(180deg);
          }
        }
        .btnNext,
        .btnPrev {
          border-radius: 50%;
          background: #424448;
          width: 28px;
          height: 28px;
          img {
            width: 25px;
            opacity: 0.5;
          }
          &.disable {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }
    }
    &_options {
      margin-bottom: 20px;
      display: flex;
      overflow: hidden;
      .btnPositionOpt {
        background: var(--box-bland-bg);
        // height: 26px;
        margin-right: 9px;
        padding: 7px 15px;
        border-radius: 16px;
        p {
          color: var(--gray-blur-text);
          font-size: 13px;
          transform: translateY(1px);
        }
        &.active {
          background: var(--purple);
          p {
            color: var(--normal-text-black-color);
          }
        }
        &.disable {
          // background: var(--box-bland-bg);
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    .positionItemList {
      &-desktop {
        width: calc(100% + 15px);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .positionItemWrapper {
          // width: calc((100% - 15px * 3) / 4);
          width: 25%;
          padding-right: 15px;
          margin-bottom: 18px;
          // margin-right: auto;
          .positionItem {
          }
        }
      }
    }
    //custom
    // https://github.com/akiran/react-slick/issues/1004
    .slick-track {
      margin-left: 0px;
    }
  }
  .button-follow {
    background: var(--purple);
    height: 40px;
    width: 85px;
    border-radius: 4px;
    border: none;
    color: white;
  }
  .rawHeader_info {
    display: flex;
    align-items: center;
    img {
      width: 37px;
      height: 37px;
      margin-right: 12px;
      border-radius: 50%;
    }
    &_follow {
      display: flex;
      justify-content: space-between;
    }

    &_input {
      display: flex;
      align-items: center;
    }
    .changeName {
      border-radius: 4px;
      padding: 8px;
      width: 180px;
      background-color: transparent;
      outline: none;

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      background: var(--box-bland-bg);
      height: 24px;
      margin-right: 5px;
      border: var(--box-bland-bg);
      color: white;
    }
    .saveName {
      background: var(--purple);
      padding: 6px 5px 2px;
      border-radius: 2px;
      margin-left: 7px;
      font-size: 12px;
      color: var(--white);
      font-family: var(--font-family-overpass);
      border: var(--box-bland-bg);
      cursor: pointer;
      border: none;

      // padding: 8px;
      // border-radius: 4px;
      // height: 30px;
      // cursor: pointer;

      //
      // font-size: var(--font-size-s);
    }
  }
  .name {
    display: flex;
    align-items: center;
    height: 24px;
    button {
      background: var(--box-bland-bg);
      padding: 6px 5px 2px;
      border-radius: 2px;
      margin-left: 7px;
      font-size: 12px;
      color: var(--gray-blur-text);
      font-family: var(--font-family-overpass);
      border: var(--box-bland-bg);
      cursor: pointer;
      border: 1px solid var(--border-box);
    }
  }

  .follow {
    display: flex;
    padding-top: 4px;
    align-items: center;
    img {
      width: 5px;
      height: 5px;
      margin: 0 8px;
    }
  }
  .fs-18 {
    font-size: 18px;
    font-weight: 600;
  }
  .fs-14 {
    font-size: 14px;
    // color: var(--gray-blur-text);
    color: var(--gray-blur-text);
  }
  .statistic {
    // width: 30%;
    width: calc(282px);
    div.title {
      padding-top: 25px;
      margin-bottom: 15px;
      p {
      }
    }
    &_list {
      height: 332px;
      padding-top: 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      &_item {
        width: 100%;
        height: 55px;
        background: var(--box-bland-bg);
        justify-content: center;
        display: flex;
        // padding: 18px 0 26px;
        border-radius: 4px;
        // margin-bottom: 20px;
        border: 1px solid var(--border-box);
        align-items: center;
        p {
          padding-top: 2px;
          font-size: 16px;
          font-weight: 400;
          color: var(--normal-text-black-color);
        }
        &-winStreak {
          background: var(--purple);
        }
      }
    }
  }
  .userChart {
    padding: 30px;
  }
  .table-position {
    background: var(--box-bland-bg);
    padding: 25px 30px;
    margin-bottom: 30px;
    border: 1px solid var(--border-box);
    border-radius: 8px;
    .following_table_detail_header_tr th {
      text-align: start;
      width: 15%;
      font-family: var(--font-family-overpass);
    }
    .following_table_detail_header_tr_history {
      font-size: 13px;
      color: var(--gray-blur-text);
      min-width: 80px;
      th {
        text-align: start;
        // width: 31%;
        font-family: var(--font-family-overpass);
      }
    }
  }
  .fs-23 {
    font-size: 23px;
    font-weight: 600;
    font-family: var(--font-family-overpass);
  }
  .fs-20 {
    font-size: 20px;
  }
  .view-position-history {
    background: var(--box-bland-bg);
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    padding: 0 30px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .button-sub {
    background: var(--purple);
    height: 100%;
    padding: 0 10px;
    border-radius: 4px;
    border: none;
    color: white;
    width: 173px;
    height: 37px;
  }
}
.userPage {
  &_head {
    position: sticky;
    top: 61px;
    z-index: 100;
    display: flex;
    padding: 16px;
    padding-top: calc(30px - 3px);
    padding-bottom: 0;
    background: var(--bg);
    height: 70px;
    &_left {
      width: 80%;
      .infoUser {
        display: flex;
        &_avatar {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        &_detail {
          padding: 0px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;
          p {
            display: block;
            margin-right: auto;
          }
          .name {
            color: var(--normal-text-black-color);

            font-size: var(--font-size-l);
            font-style: normal;
            font-weight: 500;
            // margin-bottom: 5px;
            transform: translateY(-1px);
          }
          .followers {
            // color: var(--normal-text-black-color);

            font-size: 12px;
            color: #b3b2b3; // Please update "Follower/Following" color => this applies to BOTH Light mode + Dark Mode
          }
        }
      }
    }
    &_right {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
      .btnFollowing,
      .btnFollow {
        min-width: 91px;
        padding: 6px 12px;
        border: 1px solid var(--green);
        border-radius: 5px;
        p {
          font-size: var(--font-size-xs);
          font-style: normal;

          color: var(--green);
        }
        // &:hover {
        //   background: var(--green);
        //   p {
        //     color: #000;
        //   }
        // }
      }
      .btnFollow {
        background: var(--green);
        p {
          color: var(--normal-text-color);
        }
      }
      .btnFollowing {
        // cursor: auto;
      }
    }
  }

  // new ui
  .chartUserWrapper {
    box-shadow: 0 2px 1px rgba(57, 63, 72, 0.3), 1px 0 5px rgba(57, 63, 72, 0.3);
    margin: 4px 16px; // 4 + 16 = 20
    border-radius: 10px;
    margin-bottom: 20px;
    height: fit-content;
  }

  //end new ui
  &_accountValues {
    padding: 30px 32px 0;
    max-height: 74px;
    .accountValuesWrapper {
      display: flex;
      .accountValuesDetail {
        width: 50%;
        .value {
          color: var(--normal-text-black-color);
          font-family: var(--font-family-overpass);
          font-size: 24px;
          font-style: normal;
          padding-bottom: 6px; // 8px;
          font-weight: bold;
        }
        .detail {
          span {
            color: var(--gray-blur-text); // var(--normal-text-black-color);
            font-family: var(--font-family-overpass);
            font-size: 14px;
            font-style: normal;
          }
          span.text {
            color: var(--gray-blur-text);
            padding-left: 12px;
          }
        }
      }
      .accountValuesOptions {
        width: 50%;
        display: flex;
        .optionsWrapper {
          position: relative;
          z-index: 1;
          display: flex;
          margin-left: auto;
          height: 30px;
          top: -10px;
          .btnTimeOpt {
            width: 48px;
            p {
              color: var(--gray-blur-text);
              font-family: var(--font-family-overpass);
              font-size: 14px;
              font-style: normal;
            }
            &.active {
              p {
                color: var(--purple);
              }
            }
          }
          &::before {
            position: absolute;
            content: "";
            bottom: 0;
            z-index: 1;
            width: 30px;
            height: 2px;
            // background: var(--purple);
            background: transparent;
            transition: 0.1s;
          }

          &-WEEK {
            &::before {
              left: 8px;
            }
          }
          &-MONTH {
            &::before {
              left: calc(56px);
            }
          }
          &-YEAR {
            &::before {
              left: calc(105px);
            }
          }
        }
      }
    }
  }

  &_chart {
    .userChart {
      padding: 40px 15px 20px;
      position: relative;

      //custom
      .recharts-responsive-container {
        circle {
          display: none;
          opacity: 0;
        }
        .recharts-tooltip-wrapper {
          background: var(--bg);
        }
      }
    }
    .chartStock {
      padding: 40px 15px 18px;
      &_chartOne {
        position: relative;
        &_activeDot {
          position: absolute;
          top: 0px;
          left: -10000px;
          z-index: 50;
          transform: translate(-50%, -50%);
          pointer-events: none;
          width: 8px;
          height: 8px;
          background: var(--purple);
          border-radius: 20px;
          pointer-events: none;
        }
        &_lineHoz {
          position: absolute;
          // top: 16px;
          // left: 16px;
          top: 0px;
          left: -10000px;
          z-index: 50;
          // transform: translateX(-1000px);
          pointer-events: none;
          // transition: 0.3s;
          p {
            min-width: 60px;
            position: absolute;
            top: -20px;
            left: 0px;
            transform: translateX(-50%);
            color: #898b91;
            font-size: 10px;
            white-space: nowrap;
            pointer-events: none;
          }
          &::before {
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            width: 1px;
            height: 205px;
            background: #898b91;
            pointer-events: none;
            z-index: 50;
          }
        }
      }
      circle.recharts-dot {
        fill: var(--purple);
        stroke: var(--purple);
      }
    }
    .chartStockTooltip {
      // position: relative;
      // z-index: 200;
      .time {
        margin-bottom: 6px;
        p {
          font-size: 11px;
          color: #898b91;
        }
      }
      .price {
        border-radius: 3px;
        background: #342c4b;
        padding: 5px 0;
        p {
          text-align: center;
          font-size: 11px;
        }
        &_green {
          color: var(--purple);
          margin-bottom: 3px;
        }
        &_red {
          color: var(--red);
        }
      }
    }
    .chartStock_chartOne_date {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      margin-top: 40px;
      padding: 10px 15px;
      border-top: 1px solid #f7f7f81c;
      p {
        color: #898b91;
      }
    }
  }
}
// @media only screen and (max-width: 1250px) {
//   .portfolio {
//     .rawHeader {
//       display: block;
//       &_chart {
//         width: 100%;
//         padding-right: 0;
//       }
//     }
//     .statistic {
//       width: 100%;
//       padding-top: 30px;
//     }
//   }
// }

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .portfolio {
    margin-bottom: 20px;
    &_positions {
      margin-top: 30px;
      margin-bottom: 30px;
      &_title {
        p {
          font-size: 16px;
        }
      }
    }
  }
  .portfolio .rawHeader_info img.comment_avatar {
    width: 39px;
    height: 39px;
  }
  .portfolio .table-position {
    padding: 17px 15px;
    margin-bottom: 20px;
    .fs-23 {
      font-size: 15px;
    }
  }
  .history .table-position {
    margin-bottom: 30px;
  }
  .portfolio {
    .rawHeader {
      display: block;
      &_chart {
        width: 100%;
        padding-right: 0;
      }
    }
    .statistic {
      width: 100%;
      padding-top: 0px;
      div.title {
        padding-top: 0px;
      }
      &_list {
        padding-top: 0px;
        height: auto;
        border-radius: 8px;
        overflow: hidden;
        background: var(--box-bland-bg);

        &_item {
          height: 50px;
          border-radius: 0;
          background: transparent;
          padding: 12px 0;
          border-bottom: 1px solid var(--border-comment);
          p {
            color: var(--gray);
          }
          p.isMobile {
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            transform: translateY(2px);
            span.title {
              display: block;
              margin-right: auto;
            }
            span.value {
              display: block;
              margin-left: auto;
            }
          }
          &-winStreak {
            // background: var(--purple);
            border-bottom: 1px solid transparent;
            p {
              color: var(--purple);
            }
          }
        }
      }
    }

    .name {
      button {
        font-size: 10px;
      }
    }
    .fs-18 {
      font-size: 16px;
    }
    .fs-14 {
      font-size: 12px;
    }

    .userPage_accountValues {
      padding: 19px 20px;
      padding-bottom: 50px;
      .accountValuesWrapper {
        .accountValuesDetail {
          .value {
            font-size: 20px;
            padding-bottom: 4px;
          }
          .detail {
            span {
              font-size: 12px;
            }
          }
        }
        .accountValuesOptions {
          .optionsWrapper {
            .btnTimeOpt {
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .userPage {
      &_chart {
        .chartStock {
          padding: 0;
        }
        .chartStock_chartOne_lineHoz {
          p {
            font-size: 10px;
            left: 12px;
            left: 4px;
            font-weight: 400;
          }
          &::before {
            height: 226px;
          }
          .time-WEEK-0 {
            transform: translateX(10px);
          }
          .time-WEEK-27 {
            transform: translateX(-20px);
          }
          .time-WEEK-26 {
            transform: translateX(-14px);
          }

          .time-MONTH-335 {
            transform: translateX(6px);
          }
          .time-MONTH-364 {
            transform: translateX(-5px);
          }
          .time-YEAR-335 {
            transform: translateX(6px);
          }
          .time-YEAR-364 {
            transform: translateX(-5px);
          }
        }
        .chartStock_chartOne_date p {
          font-size: 8px;
        }
      }
    }
    .statistic_list_item p {
      font-size: 14px;
    }
    .fs-23 {
      font-size: 15px;
    }
    .avgPrice {
      display: none;
    }
    .total_th,
    .total {
      display: none;
    }
    .maxReturn {
      display: none;
    }
    .table-position {
      .following_table_detail_header {
        height: 24px;
      }
      .following_table_detail_header_tr th {
        width: 28%;
        font-size: 12px;
      }
      .following_table_detail_header_tr_history {
        th {
          text-align: start;
          width: 35%;
          font-size: 12px;
        }
      }
      .following_table_detail_tbody td {
        font-size: 14px;
      }
    }
    .history {
      .following_table_detail_tbody td {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .portfolio {
    .table-position {
      .following_table_detail_header_tr th {
        width: 25%;
      }
      .following_table_detail_header_tr_history {
        th {
          width: 21%;
        }
      }
    }
  }
}

.positionItemListWrapper {
  // width: calc(100% + 120px);
  overflow: hidden;
  // custom
  .react-horizontal-scrolling-menu--wrapper {
    .react-horizontal-scrolling-menu--scroll-container {
      padding-left: 0px;
      padding-right: 3px; // 8 + 3 = 11
      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
        display: none;
      }
    }
  }
}
.positionItemList {
  width: calc(100% + 120px);
}
.positionItemWrapper {
  padding-right: 20px;
}
.positionItem {
  position: relative;
  width: 100%;
  // width: 260px;
  // margin-bottom: 16px;
  background: var(--box-bland-bg); //#fbfafb;
  border-radius: 8px;
  padding: 18px 22px 22px 22px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  // box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // height: 152px;
  border: 1px solid var(--border-box);

  // &:hover {
  //   // background: var(--green-hover);
  //   border: 1px solid var(--green-hover-border);
  // }
  &-green {
    // &:hover {
    // background: var(--green-hover);
    border: 1px solid var(--green-hover-border);
    // }
  }
  &-red {
    // &:hover {
    // background: var(--red-hover);
    border: 1px solid var(--red-hover-border);
    // }
  }
  &-past {
    opacity: 0.3;
  }

  &_header {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--border-comment);

    &_name {
      width: 60%;
      display: flex;
      &_logo {
        margin-right: 10px;
        img {
          width: 37px;
          border-radius: 50%;
        }
      }
      &_info {
        padding-top: 4px;
        p {
          color: var(--normal-text-black-color);
          font-style: normal;
          font-weight: 700;
        }
        .title {
          font-size: 16px;
          margin-bottom: 4px;
          white-space: nowrap;
        }
        .text {
          font-size: 14px;
          color: var(--gray-blur-text);
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
    &_price {
      width: 40%;
      padding-top: 4px;
      p {
        font-size: 15px;
        font-style: normal;
        text-align: right;
        font-weight: 400;
        // margin-bottom: 2px;
        height: 14px;
        margin-bottom: 6px;
      }
      p.volatility {
        font-size: 14px;
        height: 12px;
        transform: translateY(2px);
      }
    }
  }
  &_entry {
    display: flex;
    padding: 20px 0 18px;
    border-bottom: 1px solid var(--border-comment);
    p {
      font-size: 14px;
      color: var(--normal-text-black-color);
    }
    .entry {
      margin-right: auto;
    }
    .price {
      margin-left: auto;
    }
  }
  &_shares,
  &_costBasis,
  &_potentialReturn {
    display: flex;
    margin-bottom: 17px;
    p {
      font-size: 14px;
      color: var(--normal-text-black-color);
    }
    .title {
      margin-right: auto;
    }
    .value {
      margin-left: auto;
    }
  }
  &_shares {
    padding-top: 20px;
  }
  &_potentialReturn {
    margin-bottom: 0px;
  }
  .btnCancelOrder {
    position: absolute;
    background: #6a6c70;
    width: calc(100% - 22px - 22px);
    bottom: 15px;
    padding: 4px 0;
    border-radius: 4px;
    p {
      color: var(--normal-text-black-color);
      font-size: 13px;
      transform: translateY(1px);
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .positionItemList {
    width: calc(100%);
  }
  .positionItemWrapper {
    padding-right: 13px;
  }
  .positionItem {
    padding: 15px 20px 18px 18px;
    width: 60vw;
    @media only screen and (min-width: 600px) and (max-width: 1100px) {
      width: calc(400px * 60 / 100);
    }
    &_header {
      padding-bottom: 15px;
      &_name {
        &_logo {
          img {
            width: 33px;
          }
        }
        &_info {
          .title {
            font-size: 14px;
          }
          .text {
            font-size: 12px;
          }
        }
      }
      &_price {
        p {
          font-size: 14px;
          transform: translateY(2px);
        }
        p.volatility {
          font-size: 12px;
          transform: translateY(0px);
        }
      }
    }
    &_entry {
      padding: 17px 0 15px;
      p {
        font-size: 12px;
      }
      // .entry {
      //   font-size: 12px;
      // }
    }
    &_shares,
    &_costBasis,
    &_potentialReturn {
      margin-bottom: 14px;
      p {
        font-size: 14px;
      }
    }
    &_shares {
      padding-top: 17px;
    }
    &_potentialReturn {
      margin-bottom: 0;
    }
  }
}
