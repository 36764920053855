.landingSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: calc(220px + 600px + 300px);
  padding: 32px 16px;
  &_left {
    width: 50%;
    padding-left: 16px;
    &_title {
      font-size: 55px;
      font-weight: 500;
      margin-bottom: 27px;
      &.reveal {
        transition: all 0.5s ease;
      }
      &.reveal-active {
      }
    }
    &_details {
      font-size: 22px;
      margin-bottom: 40px;
      line-height: 1.35;
      font-weight: 400;
      &.reveal {
        transition: all 0.8s ease;
      }
      &.reveal-active {
      }
    }
    &_link {
      color: var(--green);
      // border-top: 1px solid var(--green);
      width: max-content;
      padding-top: 16px;
      padding-bottom: 16px;
      position: relative;
      cursor: pointer;
      &.reveal {
        transition: all 1s ease;
      }
      &.reveal-active {
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 100;
        background: var(--green);   
        transition: 0.5s;
      }
      &:hover {
        color: var(--normal-text-color);
        padding-left: 20px;
        padding-right: 20px;
        transition: 0.5s;
        &::before {
          height: 100%
        }
      }
      a {
        position: relative;
        z-index: 100;
        transition: 0.1s;
      }
    }
  }
  &_right {
    width: 50%;
  }

  .stockItem {
    * {
      font-family: var(--font-family-overpass) !important; // big custom
    }
    &_footer {
      &_investor,
      &_money {
        align-items: flex-end;
      }
    }
  }
}
.flexDirec {
  flex-direction: row-reverse;
  .landingSection_left {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1100px) {
  .landingSection {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .flexDirec {
      flex-direction: column;
    }
    &_left {
      width: 100%;
      padding-left: 0px;
      &_title {
        font-size: 45px;
        margin-bottom: 22px;
      }
      &_details {
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
    &_right {
      width: 100%;
    }
  }
}

// custom
@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .landingSection {
    .stockItem {
      width: 100%;
      margin-bottom: 8px;
      padding: 10px;

      &_header {
        margin-bottom: 8px;
        &_name {
          p {
            font-size: 16px;
            height: 24px;
          }
        }
        &_price {
          p {
            font-size: 10px;
            margin-bottom: 1px;
            height: 12px;
          }
          p.volatility {
            font-size: 8px;
            height: 10px;
          }
        }
      }
      &_percent {
        margin-bottom: 4px;
        &_hoz {
          width: calc(100% - 40px);
          .hoz-green,
          .hoz-red {
            height: 10px;
          }
        }
        &_number {
          width: 40px;

          p {
            font-size: 10px;
            height: 14px;
          }
        }
      }
      &_footer {
        margin-top: 8px;
        height: 18px;
        &_investor,
        &_money {
          img {
            width: 12px;
            margin-right: 4px;
          }
          p {
            font-size: 10px;
            text-align: right;
          }
        }
        &_money {
          img {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
