.TransactionTableWrapper {
  * {
    font-family: var(--font-family-overpass);
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  width: 100%;
  .transactionTable {
    width: 100%;
    margin-bottom: 20px;
    th,
    td {
      padding: 16px 0;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      line-height: 1.3;
      &.green-text {
        color: var(--green);
      }
      &.red-text {
        color: var(--red);
      }
    }
    tr {
      cursor: pointer;
    }
    th {
      text-align: left;
      color: #b3afbf;
    }
    &_header {
      position: sticky;
      position: -webkit-sticky; /* Safari */
      top: 222px;
      z-index: 10;
      max-height: calc(80px + 54px + 76px);
      border-radius: 10px;
      th {
        p {
          font-size: 12px;
          display: block;
          margin-top: auto;
          color: #b3afbf;
        }
      }
      .date {
        background-color: var(--box-bland-bg);
        border-radius: 8px 0px 0px 8px;
        padding-left: 16px;
        padding-right: 8px;
        width: 20%;
        div {
          justify-content: flex-start;
        }
      }
      .email {
        background-color: var(--box-bland-bg);
        padding-left: 8px;
        padding-right: 8px;
        width: 20%;
        div {
          justify-content: flex-start;
        }
      }
      .transaction {
        background-color: var(--box-bland-bg);
        padding-left: 8px;
        padding-right: 8px;
        width: 15%;
        //border-right: 1px solid #252a39;
        div {
          justify-content: flex-start;
        }
      }
      .amount {
        background-color: var(--box-bland-bg);
        padding-left: 8px;
        padding-right: 8px;
        width: 15%;
        div {
          justify-content: flex-start;
        }
      }
      .fees {
        background-color: var(--box-bland-bg);
        padding-left: 8px;
        padding-right: 8px;
        width: 15%;
        div {
          justify-content: flex-start;
        }
      }
      .accountValue {
        background-color: var(--box-bland-bg);
        border-radius: 0px 8px 10px 0px;
        padding-left: 8px;
        padding-right: 16px;
        div {
          justify-content: flex-start;
        }
        width: 15%;
      }
    }
    &_content {
      width: 100%;
    }
    &_item {
      width: 100%;
      position: relative;
      height: 51px;
      border: 5px solid var(--bg);
      border-left: none;
      border-right: none;
      border-radius: 20px 10px;
      td {
        span {
          font-size: 14px;
          color: var(--normal-text-black-color);
        }
      }
      .date {
        padding-left: 16px;
        padding-right: 8px;
        background-color: var(--box-bland-bg);
        border-radius: 10px 0px 0px 10px;
      }
      .email {
        padding-left: 8px;
        padding-right: 8px;
        background-color: var(--box-bland-bg);
        div {
          div {
            span {
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
      .transaction {
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
        background-color: var(--box-bland-bg);
      }
      .amount {
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
        background-color: var(--box-bland-bg);
      }
      .fees {
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
        background-color: var(--box-bland-bg);
      }
      .accountValue {
        text-align: left;
        padding-right: 16px;
        padding-left: 8px;
        width: 32px;
        background-color: var(--box-bland-bg);
        border-radius: 0px 10px 10px 0px;
        cursor: pointer;
      }

      &:nth-child(odd) {
        // background-color: #252a39;
      }
      &:nth-child(even) {
        background-color: var(--body-bg);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .TransactionTableWrapper {
    .transactionTable {
      &_header {
        top: 232px;
        th {
          font-size: 9px !important;
        }
      }
      &_item {
        td {
          span {
            font-size: 9px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .TransactionTableWrapper {
    .transactionTable {
      &_header {
        top: 224px;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .TransactionTableWrapper {
    .transactionTable {
      &_header {
        top: 255px;
      }
    }
  }
}
