.sidebar {
  // padding-top: 65px;
  width: 220px;
  background: var(--sidebar-bg);
  // box-shadow: 20px 0px 20px 0px #1d222f6b;
  z-index: 1200;
  &_menu {
    width: 220px;
    padding: 26px 15px;
    height: calc(100% - 50px);
    .top-header_logo_img {
      // width: 22px;
      display: flex;
      margin: 0 0 17px 24px;
      cursor: pointer;
      img {
        width: 28px;
        display: block;
        margin-right: 9px;
      }
      &_texts {
        padding-top: 8px;
        display: flex;
        width: 82px;
        flex-direction: column;
        .title {
          font-size: 18px;
          font-weight: 700;
          color: var(--normal-text-black-color);
          margin-left: auto;
        }
        .version {
          font-size: 10px;
          font-weight: 500;
          color: var(--normal-text-black-color);
          opacity: 0.45;
          margin-top: 1px;
          margin-left: auto;
          order: 2;
        }
      }
    }
    .sidebar_menu_item_icon {
      svg {
        filter: invert(0);
        g {
          fill: var(--white);
        }
      }
      &-collapsed {
        display: none;
        @media only screen and (max-width: 1530px) {
          display: none;
        }
      }
      &-normal {
        display: block;
        @media only screen and (max-width: 1530px) {
          // display: none;
        }
      }
    }

    &_item {
      cursor: pointer;
      margin: 15px 10px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      border: 1px solid transparent;

      .itemLinkWrapper {
        width: 100%;
        height: 100%;
        padding: 10px 10px;
      }
      .group {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
      }
      &_icon {
        padding-left: 3px;
        margin-right: 12px;
        // opacity: 0.6;
        // not active
        svg {
          width: 25px;
          height: 30px;
          // filter: invert(1); // dark
          path {
            stroke: #aaacad;
          }
        }
      }
      p {
        font-weight: 500;
        font-size: 18px;
        // opacity: 0.6; // not active
        color: #aaacad;
      }
    }

    .active {
      background-color: var(--box-bg);
      border-radius: 4px;
      // box-shadow: 0px 5px 10px rgba(57, 63, 72, 0.3);
      border: 1px solid var(--border-box);
      background: var(--sidebar-item-bg);
      @media only screen and (max-width: 1530px) {
        background: transparent;
      }
      .itemLinkWrapper {
      }
      .sidebar_menu_item_icon {
        opacity: 1;
        svg {
          filter: invert(0);
          path {
            stroke: #fff;
            @media only screen and (max-width: 1530px) {
              stroke: transparent;
            }
          }
          g {
            fill: var(--white);
          }
        }

        &-collapsed {
          display: none;
          @media only screen and (max-width: 1530px) {
            display: block;
          }
        }
        &-normal {
          @media only screen and (max-width: 1530px) {
            display: none;
          }
        }
      }
      p {
        opacity: 1;
        color: var(--normal-text-black-color);
      }
    }
  }

  .changeThemeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    .btnTheme {
      width: 40px;
      height: 20px;
      background: var(--purple);
      border-radius: 10px;
      cursor: pointer;
      margin-right: 15px;
      position: relative;
      margin-top: auto;
      margin-bottom: auto;

      &::before {
        position: absolute;
        content: "";
        top: 2px;
        right: 2px;
        height: 16px;
        width: 16px;
        background: #fff;
        border-radius: 50%;
        transition: 0.3s;
      }
    }
    p {
      width: 80px;
      display: block;
      font-size: 16px;
      color: var(--normal-text-black-color);
      opacity: 0.6;
    }
    &-light {
      .btnTheme {
        background: #7a7c80 !important;
        &::before {
          right: 22px;
        }
      }
      p {
        color: #2c2f33;
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .changeThemeWrapper {
  }
}

@media only screen and (max-width: 1530px) {
  .sidebar {
    // display: none;
    width: 71px;
    .sidebar_menu {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      .top-header_logo_img_texts {
        display: none;
      }
      &_item {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        padding: auto;
        transform: translateX(2px);
        &_icon {
          padding-left: 0;
          padding-right: 0;
          svg {
            width: 27px;
            height: 25px;
          }
        }
        .itemLinkWrapper {
        }
      }
      &_item .group p {
        display: none !important;
      }
    }

    .changeThemeWrapper {
      .btnTheme {
        margin-right: 0;
      }
      p {
        display: none;
      }
    }
  }
}
