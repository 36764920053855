.accountInfo {
  width: 100%;
  margin-top: 16px;
  border-radius: 8px;
  background-color: var(--box-bg);
  box-shadow: 0px 5px 10px rgba(57, 63, 72, 0.3);
  padding: 16px;
  .accountValue,
  .currentBet,
  .availFund {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    height: 26px;
    .text {
      color: var(--normal-text-black-color); // #232325;
      font-size: var(--font-size-s);
      font-style: normal;
      font-weight: 400;
    }

    .value {
      color: var(--normal-text-black-color);
      font-size: var(--font-size-s);
      font-style: normal;
      font-weight: 500;
    }
  }
}
