.metrics-custom {
  .page-content {
    width: 1320px;
    max-width: 1320px;
  }
  .sidebar {
    display: none;
  }
  .rightBar {
    display: none;
  }
  .topHeaderWrapper {
    display: none;
  }
  .footerWrapper {
    display: none;
  }
}

.metrics {
  &_loginWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .metricsLogin {
      width: 345px;
      height: 43px;
      background: var(--box-bland-bg);
      border-radius: 4px;
      display: flex;
      input {
        display: block;
        border: transparent;
        border-radius: 4px;
        padding: 8px;
        padding-left: 20px;
        width: 180px;
        background-color: transparent;
        outline: none;
        color: #898b91;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: var(--box-bland-bg);
        width: 300px;
        height: 40px;
        border: 1px solid var(--border-box);
      }
      // https://stackoverflow.com/questions/61178501/input-internal-autofill-selected-being-overridden-on-chrome-via-webkit
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        border-radius: 0;
        border: 1px solid var(--box-bland-bg);
        -webkit-text-fill-color: #898b91;
        -webkit-box-shadow: 0 0 0px 1000px var(--box-bland-bg) inset;
        // transition: background-color 5000s ease-in-out 0s;
      }
      .btnLoginMetrics {
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          display: block;
        }
      }
    }
  }
  &_dashBoard {
    width: 100%;
    // background: red;
    padding: 20px 0;
    // padding-bottom: 20px;

    &_title {
      margin-bottom: 14px;
      display: flex;
      position: relative;
      p {
        display: block;
        font-size: 18px;
        font-weight: 700;
      }
      p.times {
        display: block;
        margin-left: auto;
        font-size: 14px;
        font-weight: 500;
        margin-right: 25px;
        cursor: pointer;
        &:hover {
          color: var(--purple);
        }
      }
      img.download {
        width: 15px;
        transform: translateY(-1px);
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
    &_times {
      display: flex;
      p {
        display: block;
        margin-left: auto;
        font-size: 14px;
        font-weight: 500;
        margin-right: 17px;
        cursor: pointer;
        &:hover {
          color: var(--purple);
        }
      }
    }
    &_chart {
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 25px;
      background: var(--box-bland-bg);
      border-radius: 8px;
      .listMetrics {
        display: flex;
        justify-content: space-between;
        padding: 28px 28px;
        .metricItem {
          //   width: 16%;
          width: fit-content;
        }
      }
    }

    &_cols {
      display: flex;
      justify-content: space-between;

      .allTimeStats,
      .newUsers,
      .recentTrades {
        position: relative;
        .title {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 14px;
        }
        img.download {
          width: 15px;
          transform: translateY(-1px);
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
        &_scrollMore {
          position: relative;
          top: -1px;
        }
      }
      .allTimeStats {
        width: 23%;
      }
      .newUsers {
        width: 31%;
      }
      .recentTrades {
        width: 42%;
      }
    }
  }
}

// css date
.datePickerMetrics {
  display: block;
  margin-left: auto;
  //custom
  .react-datepicker-popper {
    padding-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 2000;
    top: 10px !important;
    border-radius: 8px;
    border: 2px solid var(--purple);
    background: var(--bg);
  }
  .react-datepicker {
    font-family: var(--font-family-overpass);
    background: var(--bg);
    border: 1px solid transparent;
    .react-datepicker__header {
      background: transparent;
      padding-top: 20px;
      border-bottom: 1px solid transparent;
      .react-datepicker__current-month {
        color: var(--white);
        font-weight: normal;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .react-datepicker__day-name {
        color: var(--white);
        font-size: 12px;
      }
    }
    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0rem;
    }
    .react-datepicker__navigation {
      transform: scale(0.6);
      top: 10px;
      .datepicker__navigation-icon::before {
        color: var(--white);
      }
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__month {
    margin-top: 0;
  }
  .react-datepicker__month-container {
    background: var(--bg);
  }
  .react-datepicker__day {
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    font-size: 12px;
    // background: transparent;
    &:hover {
      background: transparent;
      color: var(--purple);
    }
  }
  .react-datepicker__day--today {
    // color: var(--white);
    font-weight: 500;
  }
  .react-datepicker__day--selected {
    background: transparent;
    color: var(--purple);
  }
  .react-datepicker__day--selected {
    background: transparent;
    color: var(--purple);
  }
  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected) {
    background: transparent;
    // color: var(--purple);
  }
  .react-datepicker__day--in-selecting-range {
    color: var(--purple);
    opacity: 1;
    background: transparent;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background: transparent;
  }
  .react-datepicker__day--in-range {
    color: var(--purple);
    opacity: 1;
    background: transparent;
    font-weight: 700;
  }
}
// end css date

// css chart

.chartMetrics {
  width: 100%;
  position: relative;
  &_lineHoz {
    position: absolute;
    top: 0px;
    left: 16px;
    left: -10000px;
    z-index: 50;

    // transform: translateX(-1000px);
    pointer-events: none;
    &::before {
      content: "";
      position: absolute;
      top: 0px;

      left: 50%;
      z-index: 100;

      width: 1px;
      height: 227px;
      background: #898b91;
      pointer-events: none;
      // background: red;
    }
  }
  &_activeDot {
    position: absolute;
    top: 0px;
    left: -10000px;
    z-index: 50;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 8px;
    height: 8px;
    background: var(--purple);
    border-radius: 20px;

    pointer-events: none;
  }
  &_times {
    display: flex;
    // margin-top: 3px;
    height: 20px;
    border-top: thin solid var(--border-comment); // #898b91;
    border-bottom: thin solid var(--border-comment); // #898b91;
    padding: 6px 6px;

    // set chart
    pointer-events: none;
    position: absolute;
    bottom: calc(100px + 84px);
    left: 0;
    width: 100%;
    // end set chart
    p {
      font-size: 7px;
      color: var(--normal-text-black-color);
      transform: translateX(-50%);
      position: absolute;
      top: 5px;
      left: 0;
      white-space: nowrap;
    }
    p.first {
      margin-right: auto;
    }
    p.last {
      margin-left: auto;
    }
  }
  &_Tooltip {
    // position: relative;
    // z-index: 200;
    pointer-events: none;
    background: var(--bg);
    padding: 8px 10px;
    border-radius: 4px;
    p.time {
      font-size: 12px;
      color: #a7a8a9;
      margin-bottom: 7px;
    }

    p.value {
      text-align: left;
      font-size: 12px;
      color: var(--purple);
      //   &-TradingVolume {
      //     color: var(--purple);
      //   }
    }
  }

  //custom
  //   .recharts-cartesian-grid-horizontal line:nth-child(6) {
  //     transform: translate(0, -20px);
  //   }
  .recharts-cartesian-grid-horizontal {
    background: red;
    line:nth-child(6) {
      stroke-dasharray: 1 0;
      stroke: #424448;
    }
  }
  .recharts-cartesian-axis-tick {
    transform: translateY(10px);
    line {
      display: none;
    }
    text {
      tspan {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  //   .recharts-cartesian-axis-tick:nth-child(1) {
  //     transform: translateX(15px);
  //   }
  //   .recharts-cartesian-axis-tick:nth-child(7) {
  //     transform: translateX(-15px);
  //   }
}

// end css chart

// css Item

.metricItem {
  // width: 16%;
  //   background: red;
  //   padding: 25px 0px 25px 28px;
  cursor: pointer;
  padding: 0;
  &_title {
    display: block;
    font-size: 14px;
    color: var(--normal-text-black-color);
    font-weight: 500;
    margin-bottom: 10px;
    .volatility {
      margin-left: 5px;
    }
  }
  &_value {
    display: block;
    font-size: 30px;
    color: var(--normal-text-black-color);
    font-weight: 500;
  }
  &.active {
    .metricItem_value {
      color: var(--purple);
    }
  }
}

.statItem {
  width: 100%;
  padding: 18px 16px;
  background: var(--box-bland-bg);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  border: 1px solid transparent;
  position: relative;
  &:hover {
    border: 1px solid var(--purple);
  }
  &_title,
  &_value {
    display: block;
    font-size: 14px;
    color: var(--normal-text-black-color);
  }
}

.newUserItem {
  width: 100%;
  padding: 12px 16px;
  background: var(--box-bland-bg);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid transparent;
  position: relative;
  &:hover {
    border: 1px solid var(--purple);
  }
  img.avatar {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    border-radius: 50%;
  }
  &_title,
  &_time {
    display: block;
    font-size: 14px;
    color: var(--normal-text-black-color);
    text-align: justify;
    vertical-align: middle;
    padding-top: 2px;
  }
  &_title {
    width: 55%;
    white-space: nowrap;
    overflow: hidden;
  }
  &_time {
    margin-left: auto;
    color: var(--gray);
  }
}

.recentTradeItem {
  width: 100%;
  padding: 12px 16px;
  background: var(--box-bland-bg);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid transparent;
  position: relative;
  &:hover {
    border: 1px solid var(--purple);
  }
  img.avatar {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    border-radius: 50%;
  }
  &_title,
  &_value,
  &_time {
    display: block;
    font-size: 14px;
    color: var(--normal-text-black-color);
    text-align: justify;
    padding-top: 2px;
  }
  &_title {
    width: 20%;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
  }
  &_value,
  &_time {
    color: var(--gray);
  }
  &_time {
    margin-left: auto;
  }
}
