.voteStockItem {
  background: var(--box-bland-bg);
  // max-width: 200px;
  border-radius: 30px;

  padding: 9px 12px;
  border: 1px solid #424448;
  min-height: 47px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  &:hover {
    border: 1px solid var(--purple);
  }

  img.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
  p.title {
    font-size: 15px;
    font-weight: 700;
    color: var(--normal-text-black-color);
    transform: translateY(1px);
  }
  p.numVotes {
    font-size: 15px;
    font-weight: 500;
    color: #808284;
    margin-left: auto;
    transform: translateY(1px);
  }

  // &_numVote {
  //   margin-right: 12px;
  //   svg {
  //     width: 11px;
  //     transform: translateY(-3px);
  //   }
  //   p {
  //     font-size: 15px;
  //     color: var(--gray-blur);
  //     font-weight: 700;
  //     text-align: center;
  //   }
  // }
  // &_info {
  //   .name {
  //     font-size: 15px;
  //     font-weight: 700;
  //     color: var(--normal-text-black-color);
  //   }
  //   .company {
  //     margin-top: 4px;
  //     font-size: 12px;
  //     color: var(--gray-text-3);
  //   }
  // }

  &-vote {
    // background: var(--purple);
    border: 1px solid var(--purple);

    p.title {
      color: var(--purple);
    }
    p.numVotes {
      color: var(--purple);
    }
    // .voteStockItem_numVote {
    //   svg {
    //     path {
    //       opacity: 1;
    //       fill: var(--white);
    //     }
    //   }
    //   p {
    //     color: var(--white);
    //   }
    // }
    // .voteStockItem_info {
    //   .name {
    //     color: var(--white);
    //   }
    //   .company {
    //     color: var(--white);
    //   }
    // }
  }
}

.voteStockItemCreate {
  background: var(--box-bland-bg);
  width: 47px;
  // height: 47px;
  border-radius: 30px;
  height: 100%;
  border: 1px solid var(--border-box);
  &_icon {
    width: 47px;
    height: 47px;
    display: flex;
    cursor: pointer;
    svg {
      margin: auto;
    }
  }

  &-creating {
    width: 100%;
  }

  &_input {
    width: 100%;
    display: flex;
    height: 47px;
    padding: 8px 25px;
    align-items: center;
    span {
      display: block;
      color: var(--purple);
    }
    input {
      border: transparent;
      // border-radius: 4px;
      padding-left: 0;
      // width: 180px;
      background-color: transparent;
      outline: none;
      color: var(--purple);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      background: var(--box-bland-bg);
      width: 80%;
      // height: 40px;
      border: 1px solid var(--border-box);
    }
    span.submitVoteStock {
      cursor: pointer;
      svg {
        width: 14px;
        margin-left: 3px;
        path {
          fill: var(--gray-text-3);
        }
      }
      &:hover {
        svg {
          path {
            fill: var(--purple);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // update ui responsive , 992 => mobile 600
  // @media only screen and (max-width: 600px) {
  .voteStockItem {
    padding: 8px 12px;
    img.avatar {
      width: 19px;
      height: 19px;
    }
    p.title {
      font-size: 12px;
    }
    p.numVotes {
      font-size: 12px;
    }
  }
}
