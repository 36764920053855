.adminWithdrawItemWrapper {
  * {
    font-family: var(--font-family-overpass);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: var(--box-bland-bg);
  margin: 20px 0px;
  border-radius: 4px;
  .adminWithdrawItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
    background-color: var(--box-bland-bg);
    border-radius: 4px;
    box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
    margin: 5px 0;
    &_avatar {
      width: 37px;
      height: 37px;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    &_left {
      display: flex;
      flex-direction: column;
      padding-top: 6px;
      .name {
        p {
          color: var(--normal-text-black-color);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;

          padding-bottom: 4px;
        }
      }
      .email {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 19px;
      }

      .date {
        display: flex;
        p {
          color: #b3afbf;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          padding-bottom: 4px;
        }
        span {
          margin-left: 10px;
          .editIconWithdraw {
            margin: 0 !important;
            filter: invert(80%);
            min-width: 12px !important;
            min-height: 12px !important;
          }
        }
      }
      .status {
        p {
          font-size: 12px;
          font-style: normal;
        }
        &-green {
          p {
            color: var(--green);
          }
        }
      }
    }
    &_right {
      display: flex;
      flex-direction: column;
      .price {
        margin-left: auto;
        margin-bottom: 4px;
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        &-red {
          p {
            color: var(--red);
          }
        }
        &-green {
          p {
            color: var(--green);
          }
        }
        &-yellow {
          p {
            color: var(--yellow);
          }
        }
      }
      .money {
        font-size: 14px;
      }
    }

    &-detail {
      border-radius: 4px;
      box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
    }
  }
}

@media only screen and (min-width: 1100px) {
  .adminWithdrawItemWrapper {
    .adminWithdrawItemW {
      &:hover {
        background: var(--ebony-clay);
        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: 0;
          width: calc(100%);
          height: 1px;
          background: #2b2f39;
        }
      }
    }
  }
}
